import { Component, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import * as _ from "lodash";
import { Popup } from "ng2-opd-popup";
import { CodeItem } from "../../model/code";
import { CodeCommon } from "../../model/code-common";
import { Code } from "../../model/dto";
import { CodeService } from "../../services/code.service";

@Component({
  selector: "app-code",
  templateUrl: "./code.component.html",
  styles: [],
})
export class CodeComponent {
  @ViewChild("popup1") popup1: Popup;
  @ViewChild("popup2") popup2: Popup;
  popupMessage: string;

  codeItems: CodeItem[] = [];
  currentMasterCode: string;
  currentCode: string;

  deleteCode: CodeItem;

  countryList: any[] = [
    { code: "NZ", value: "NZ" },
    { code: "AUS", value: "AU" },
    { code: "UK", value: "UK" },
    { code: "US", value: "US" },
    { code: "PHP", value: "PHP" },
    { code: "Ireland", value: "Ireland" },
  ];
  schoolTypeList: any[] = [];
  activeList: any[] = [
    { code: true, value: "True" },
    { code: false, value: "False" },
  ];
  companyList: any[] = [
    { code: "k", value: "Kokos" },
    { code: "h", value: "Hana" },
  ];

  masterCodes: Code[];

  submitBtnName: string = "Update";

  codeForm: FormGroup;

  addButton: boolean = false;

  columnName2: string = "Other Value";
  columnName3: string = "Extra Value";
  columnName4: string = "Value4";
  columnName5: string = "value5";
  columnName6: string = "value6";
  columnName8: string = "Company";

  isShow4: boolean = false;
  isShow5: boolean = false;
  isShow6: boolean = false;
  isShow8: boolean = false;

  constructor(private codeService: CodeService, private router: Router) {
    codeService.getCommon(this);
  }

  setCodeList(codeCommon: CodeCommon) {
    if (codeCommon == undefined) {
      this.router.navigate(["/logout"]);
    }

    this.schoolTypeList = codeCommon.schoolTypeList;
  }

  ngOnInit() {
    this.masterCodes = [];

    this.codeService.getRoot().subscribe(
      (data: any[]) => {
        //console.log("data : " + JSON.stringify(data));
        data.forEach((code) => {
          let val: Code = {
            code: code.code,
            value: code.value,
          };
          this.masterCodes.push(val);
        });

        if (this.masterCodes) {
          this.selectMasterCode(this.masterCodes[0].value);
        }
      },
      (error) => {
        console.log("error : ", error);
        if (error.status == "401") {
          this.router.navigate(["/logout"]);
        }
      }
    );

    this.initForm();
  }

  selectMasterCode(item) {
    this.onSetItemEmpty();
    this.currentMasterCode = item;
    let first = true;
    this.codeService.get(item).subscribe(
      (data: any[]) => {
        this.codeItems = [];
        //console.log("data : " + JSON.stringify(data));

        data.forEach((item) => {
          if (!(item.masterCode == "Sub_Agent" && item.code == "self")) {
            let codeItem = new CodeItem(
              item.code,
              item.value,
              item.id,
              item.masterCode,
              item.value2,
              item.value3,
              item.value4,
              item.value5,
              item.value6,
              item.orderIdx,
              item.parentId,
              item.parentCode,
              item.isActive,
              item.createdBy,
              item.createdOnDate,
              item.createdOnTime,
              item.updatedBy,
              item.updatedOnDate,
              item.updatedOnTime
            );

            if (item.children != null && item.children.length) {
              this.setCodeChildren(codeItem, item);
            }
            this.codeItems.push(codeItem);
            if (first) {
              this.onClickedItem(codeItem);
              first = false;
            }
            this.currentMasterCode = item.masterCode;
          }
        });

        this.codeItems = _.sortBy(this.codeItems, "orderIdx");

        let treeItem1 = new CodeItem(
          "[Add New]",
          "",
          "",
          this.currentMasterCode,
          "",
          "",
          "",
          "",
          "",
          null,
          "",
          "",
          true,
          "",
          "",
          "",
          "",
          "",
          ""
        );
        this.codeItems.push(treeItem1);
      },
      (error) => {
        console.log("error : ", error);
        if (error.status == "401") {
          this.router.navigate(["/logout"]);
        }
      }
    );
  }

  setCodeChildren(treeItem, item) {
    if (item.children != null && item.children.length) {
      treeItem.children = [];
      item.children.forEach((item) => {
        let treeItem1 = new CodeItem(
          item.code,
          item.value,
          item.id,
          item.masterCode,
          item.value2,
          item.value3,
          item.value4,
          item.value5,
          item.value6,
          item.orderIdx,
          item.parentId,
          item.parentCode,
          item.isActive,
          item.createdBy,
          item.createdOnDate,
          item.createdOnTime,
          item.updatedBy,
          item.updatedOnDate,
          item.updatedOnTime
        );
        this.setCodeChildren(treeItem1, item);
        treeItem.children.push(treeItem1);
      });

      let treeItem2 = new CodeItem(
        "[Add New]",
        "",
        "",
        this.currentMasterCode,
        "",
        "",
        "",
        "",
        "",
        null,
        treeItem.id,
        treeItem.code,
        true,
        "",
        "",
        "",
        "",
        "",
        ""
      );
      treeItem.children.push(treeItem2);
    }
  }

  onClickedItem(item) {
    this.currentCode = item.code;

    this.columnName2 = "";
    this.columnName3 = "";
    this.columnName4 = "";
    this.columnName5 = "";
    this.columnName6 = "";

    this.isShow4 = false;
    this.isShow5 = false;
    this.isShow6 = false;
    this.isShow8 = false;

    if (item.masterCode == "Admission_School_Type") {
      this.columnName2 = "정규/연수";
    } else if (item.masterCode == "School_Name") {
      this.columnName2 = "Country";
      this.columnName3 = "Type";
      this.columnName4 = "Email (Commission Claim)";

      this.isShow4 = true;
    } else if (
      item.masterCode == "Sub_Agent" ||
      item.masterCode == "Admin_Agent"
    ) {
      this.columnName2 = "CONT Name";
      this.columnName3 = "CONT Number";
      this.columnName4 = "CONT Email";
      this.columnName5 = "Bank";
      this.columnName6 = "Agent Code";

      this.isShow4 = true;
      this.isShow6 = true;
    } else if (item.masterCode == "Kokos_Branch") {
      this.columnName2 = "Email";
      this.columnName3 = "Extra Value";
    } else if (item.masterCode == "EP") {
      this.columnName4 = "Account Code";

      this.isShow4 = true;
      this.isShow8 = true;
    } else {
      this.columnName2 = "Other Value";
      this.columnName3 = "Extra Value";
    }

    this.codeForm.controls["code"].setValue(
      item.code == "[Add New]" ? "" : item.code
    );
    this.codeForm.controls["value"].setValue(item.value);
    this.codeForm.controls["id"].setValue(item.id);
    this.codeForm.controls["masterCode"].setValue(item.masterCode);
    this.codeForm.controls["value2"].setValue(item.value2);
    this.codeForm.controls["value3"].setValue(item.value3);
    this.codeForm.controls["value4"].setValue(item.value4);
    this.codeForm.controls["value5"].setValue(item.value5);
    this.codeForm.controls["value6"].setValue(item.value6);
    this.codeForm.controls["orderIdx"].setValue(item.orderIdx);
    this.codeForm.controls["parentId"].setValue(item.parentId);
    this.codeForm.controls["parentCode"].setValue(item.parentCode);
    this.codeForm.controls["isActive"].setValue(item.isActive);
    //this.codeForm.controls['createdBy'].setValue(item.createdBy);
    //this.codeForm.controls['createdOnDate'].setValue(item.createdOnDate);
    //this.codeForm.controls['createdOnTime'].setValue(item.createdOnTime);
    //this.codeForm.controls['updatedBy'].setValue(item.updatedBy);
    //this.codeForm.controls['updatedOnDate'].setValue(item.updatedOnDate);
    //this.codeForm.controls['updatedOnTime'].setValue(item.updatedOnTime);
    this.submitBtnName = "Update";

    this.addButton = false;
  }

  onSetItemEmpty() {
    this.codeForm.controls["code"].setValue("");
    this.codeForm.controls["value"].setValue("");
    this.codeForm.controls["id"].setValue("");
    this.codeForm.controls["masterCode"].setValue("");
    this.codeForm.controls["value2"].setValue("");
    this.codeForm.controls["value3"].setValue("");
    this.codeForm.controls["value4"].setValue("");
    this.codeForm.controls["value5"].setValue("");
    this.codeForm.controls["value6"].setValue("");
    this.codeForm.controls["orderIdx"].setValue("");
    this.codeForm.controls["parentId"].setValue("");
    this.codeForm.controls["parentCode"].setValue("");
    this.codeForm.controls["isActive"].setValue("");
  }

  private initForm() {
    this.codeForm = new FormGroup({
      id: new FormControl(""),
      masterCode: new FormControl(""),
      code: new FormControl(""),
      value: new FormControl(
        "",
        Validators.compose([Validators.required, Validators.minLength(2)])
      ),
      value2: new FormControl(""),
      value3: new FormControl(""),
      value4: new FormControl(""),
      value5: new FormControl(""),
      value6: new FormControl(""),
      orderIdx: new FormControl(""),
      parentId: new FormControl(""),
      parentCode: new FormControl(""),
      isActive: new FormControl(""),
    });
  }

  onClear() {
    this.addButton = true;
    this.submitBtnName = "Create";

    this.codeForm.controls["code"].setValue("");
    this.codeForm.controls["value"].setValue("");
    this.codeForm.controls["id"].setValue("");
    this.codeForm.controls["value2"].setValue("");
    this.codeForm.controls["value3"].setValue("");
    this.codeForm.controls["value4"].setValue("");
    this.codeForm.controls["value5"].setValue("");
    this.codeForm.controls["value6"].setValue("");
    this.codeForm.controls["orderIdx"].setValue(0);
    this.codeForm.controls["isActive"].setValue(true);
    this.codeForm.controls["parentId"].setValue("");
    this.codeForm.controls["parentCode"].setValue("");
  }

  onClearForChild() {
    this.addButton = true;

    this.submitBtnName = "Create Child";

    this.codeForm.controls["parentId"].setValue(
      this.codeForm.controls["id"].value
    );
    this.codeForm.controls["parentCode"].setValue(
      this.codeForm.controls["code"].value
    );

    this.codeForm.controls["code"].setValue("");
    this.codeForm.controls["value"].setValue("");
    this.codeForm.controls["id"].setValue("");
    this.codeForm.controls["value2"].setValue("");
    this.codeForm.controls["value3"].setValue("");
    this.codeForm.controls["value4"].setValue("");
    this.codeForm.controls["value5"].setValue("");
    this.codeForm.controls["value6"].setValue("");
    this.codeForm.controls["orderIdx"].setValue(0);
    this.codeForm.controls["isActive"].setValue(true);
  }

  onSubmit(codeForm) {
    if (!this.codeForm.valid) return;

    //this.loading  = true;
    if (this.codeForm.controls["id"].value === "") {
      if (codeForm.code == "") {
        codeForm.code = codeForm.value;
      }

      this.codeService.create(codeForm).subscribe(
        (data) => {
          //this.alertService.success('Registration successful', true);
          //this.router.navigate(['/home']);
          console.log("create successful");
          console.log(data);

          this.popupMessage = "Succeed in creating";

          this.popup1.options = {
            header: "Information",
            color: "#5cb85c",
            widthProsentage: 40,
            animationDuration: 0,
            showButtons: true,
            confirmBtnContent: "OK",
            cancleBtnContent: "Cancel",
            confirmBtnClass: "btn btn-default",
            cancleBtnClass: "one-button-hidden",
          };

          this.popup1.show(this.popup1.options);
        },
        (error) => {
          //this.alertService.error(error);
          //this.loading = false;
          console.log(error);
        }
      );
    } else {
      this.codeService.update(codeForm).subscribe(
        (data) => {
          //this.alertService.success('Registration successful', true);
          //this.router.navigate(['/home']);
          console.log("update successful");
          console.log(data);

          this.popupMessage = "Succeed in updating";

          this.popup1.options = {
            header: "Information",
            color: "#5cb85c",
            widthProsentage: 40,
            animationDuration: 0,
            showButtons: true,
            confirmBtnContent: "OK",
            cancleBtnContent: "Cancel",
            confirmBtnClass: "btn btn-default",
            cancleBtnClass: "one-button-hidden",
          };

          this.popup1.show(this.popup1.options);
        },
        (error) => {
          //this.alertService.error(error);
          //this.loading = false;
          console.log(error);
        }
      );
    }
  }

  onDelete(codeItem) {
    this.deleteCode = codeItem;

    this.popup2.show({
      header: "Information",
      color: "orange",
      widthProsentage: 40,
      animationDuration: 0,
      showButtons: true,
      confirmBtnContent: "Delete",
      cancleBtnContent: "Cancel",
      confirmBtnClass: "btn btn-default",
      cancleBtnClass: "btn btn-default",
    });
  }

  deleteEvent(doIt: boolean) {
    if (doIt) {
      this.codeService.delete(this.deleteCode).subscribe(
        (data) => {
          this.codeItems = _.filter(this.codeItems, (c) => c.id != data.id);

          this.popupMessage = "Succeed in deleting";

          this.popup1.options = {
            header: "Information",
            color: "#5cb85c",
            widthProsentage: 40,
            animationDuration: 0,
            showButtons: true,
            confirmBtnContent: "OK",
            cancleBtnContent: "Cancel",
            confirmBtnClass: "btn btn-default",
            cancleBtnClass: "one-button-hidden",
          };

          this.popup1.show(this.popup1.options);
        },
        (error) => {
          //this.alertService.error(error);
          //this.loading = false;
          console.log(error);
        }
      );
    }

    this.deleteCode = null;

    this.popup2.hide();
  }

  clickOKButton() {
    this.popup1.hide();
    if (
      this.popupMessage === "Succeed in creating" ||
      this.popupMessage === "Succeed in deleting" ||
      this.popupMessage === "Succeed in updating" ||
      this.popupMessage === "Succeed in deleting" ||
      this.popupMessage === "Succeed in updating status"
    ) {
      this.onClear();
    }
  }
}
