import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { Profile } from "app/model/profile";
import * as FileSaver from "file-saver";
import { Popup } from "ng2-opd-popup";
import { ModalDirective } from "ngx-bootstrap/modal";
import { filter } from "rxjs/operators";
import { Application, ApplicationSearchParams } from "../../model/application";
import { Basic, BasicMappingSearchParams } from "../../model/basic";
import { CodeCommon } from "../../model/code-common";
import { CounsellingCard } from "../../model/counselling-card";
import { Page } from "../../model/page";
import { TertiaryApplicant } from "../../model/tertiary-applicant";
import { ApplicationService } from "../../services/application.service";
import { AuthenticationService } from "../../services/authentication.service";
import { BasicService } from "../../services/basic.service";
import { CodeService } from "../../services/code.service";
import { CounsellingCardService } from "../../services/counselling-card.service";
import { TertiaryApplicantService } from "../../services/tertiary-applicant.service";

@Component({
  selector: "app-application",
  providers: [
    ApplicationService,
    CounsellingCardService,
    TertiaryApplicantService,
    BasicService,
  ],
  templateUrl: "./application.component.html",
})
export class ApplicationComponent implements OnInit, OnChanges {
  @Input() parentData;
  @Input("data") linkedToEventDetail;

  @ViewChild("popup1") popup1: Popup;
  popupMessage: string;

  public page = new Page();
  public rowsWh = new Array<Application>();
  public rowsCc = new Array<CounsellingCard>();
  public rowsTa = new Array<TertiaryApplicant>();

  public basicMappingPage = new Page();
  public basicMappingRows = new Array<Basic>();
  public userId: number = 0;

  public seletedImage;

  public searchParams = new ApplicationSearchParams();
  public basicSearchParams = new BasicMappingSearchParams();

  public selectedRowWh = new Application();
  public selectedRowCc = new CounsellingCard();
  public selectedRowTa = new TertiaryApplicant();

  public selectedWh: any[] = [];
  public selectedCc: any[] = [];
  public selectedTa: any[] = [];
  public selectedMappedBasic: any[] = [];

  public selectedRowMapping: any;

  realTypeList: any[] = [];
  eventList: any[] = [];

  @ViewChild("imageModal") public imageModal: ModalDirective;

  @ViewChild("ccModal") public ccModal: ModalDirective;
  @ViewChild("taModal") public taModal: ModalDirective;

  loadingIndicator2: boolean = false;
  timeout2: any;

  disabledMappingBtn: boolean = true;
  disabledCreateBtn: boolean = true;

  selectedOffSet: number = 0;

  mobileCc: string;
  mobileTa: string;

  authority: string;
  name: string;
  company: string;

  addEventClickCount: number;

  constructor(
    private router: Router,
    private sanitizer: DomSanitizer,
    private codeService: CodeService,
    private authenticationService: AuthenticationService,
    private counsellingCardService: CounsellingCardService,
    private tertiaryApplicantService: TertiaryApplicantService,
    private basicService: BasicService,
    private applicationService: ApplicationService
  ) {
    authenticationService.profile$
      .pipe(filter<Profile>(Boolean))
      .subscribe((profile) => {
        this.name = profile.name;
        this.authority = profile.authority;
        this.company = profile.company;
      });

    this.page.pageNumber = 0;
    this.page.size = 10;

    this.basicMappingPage.pageNumber = 0;
    this.basicMappingPage.size = 15;

    this.searchParams.realType = "TA";
    this.searchParams.kname = "";
    this.searchParams.email = "";

    codeService.getCommon(this);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["linkedToEventDetail"]) {
      if (this.linkedToEventDetail == null || this.linkedToEventDetail == -1) {
      } else {
        this.searchParams.email = "";
        this.searchParams.kname = "";
        this.searchParams.realType = "TA";
        this.searchParams.tertiaryId = this.linkedToEventDetail;
        this.searchTa(0);

        if (this.rowsTa[0] != null) {
          /*
          this.selectedRowTa = this.rowsTa[0];
          this.taModal.show();
          */
        }
      }
    }
  }

  clickOKButton() {
    this.popup1.hide();
    console.log("clickOKButton:popup1.hide");
    this.onMapMember(); //20240731
    /*
    if (
      this.popupMessage ===
      "Either Name/Phone No or Email is inconsistent. Pls update the existing details."
    ) {
      this.addEventClickCount = 1;
    } else if (
      this.popupMessage ===
      "Either Name/Phone No or Email is Still inconsistent, but I will keep the existing Name/Phone No/Email."
    ) {
      this.onMapMember();
      this.addEventClickCount = 0;
    }
      */
  }

  setCodeList(codeCommon: CodeCommon) {
    if (codeCommon == undefined) {
      this.router.navigate(["/logout"]);
    }

    //this.eventList = codeCommon.eventList;
    this.eventList = Object.assign([], codeCommon.eventList);

    //TA, CC always be shown,
    //others are limited by the number.
    
    //0822 ALL option add
    this.eventList.unshift({masterCode: 'Event' ,code: 'ALL', value: 'ALL'});
    
    while (this.eventList.length > 5) {
      var x = this.eventList.pop();
    
      //0822 ALL option add
      if (x.code == "ALL"||x.code == "Tertiary Application" || x.code == "Counselling Card") {
        this.eventList.unshift(x);
      }
    }
    
    //0822 ALL option add / Ensure "All" is at the beginning
    const allIndex = this.eventList.findIndex(item => item.code === 'ALL');
    if (allIndex > 0) {
        const allOption = this.eventList.splice(allIndex, 1)[0];
        this.eventList.unshift(allOption);
    }

    this.realTypeList = codeCommon.realTypeList;

    if (this.searchParams.realType == "TA")
      this.searchParams.event = "Tertiary Application";
    
    //0822 ALL option add
    this.searchParams.event = "ALL";
    
    this.search();
  }

  ngOnInit() {
    //this.setPage({ offset: 0 });
    this.addEventClickCount = 0;
  }

  optionChange(varX: string) {
    this.searchParams.realType = varX;
    this.selectedOffSet = 0;
    this.search();
  }

  searchResetPage() {
    this.searchParams.realType = "TA";
    this.selectedOffSet = 0;
    this.search();
  }

  search() {


    this.basicMappingRows = new Array<Basic>();
    console.log("searchParams.realType :",this.searchParams.realType);
    console.log("searchParams :",this.searchParams);
    if (this.searchParams.realType == "WH") {
      this.searchWh(this.selectedOffSet);
    } else if (this.searchParams.realType == "CC") {
      this.searchCc(this.selectedOffSet);
    } else if (this.searchParams.realType == "TA" || this.searchParams.realType == "ALL" ) {
      this.searchTa(this.selectedOffSet);
    } 
  }

  onDelete() {
    if (this.searchParams.realType == "WH") {
      this.deleteWh();
    } else if (this.searchParams.realType == "CC") {
      this.deleteCc();
    } else if (this.searchParams.realType == "TA") {
      this.deleteTa();
    }
  }

  /**
   * Populate the table with new data based on the page number
   * @param page The page to select
   */
  setPageWh(pageInfo) {
    this.selectedOffSet = pageInfo.offset;
    this.searchWh(pageInfo.offset);
  }

  searchWh(pageNumber) {
    this.rowsWh = new Array<Application>();
    this.page = new Page();

    this.page.pageNumber = pageNumber;
    this.page.size = 10;

    this.applicationService.search(this.searchParams, this.page).subscribe(
      (data) => {
        //console.log("data : " + JSON.stringify(data));

        this.rowsWh = new Array<Application>();
        data.content.forEach((application) => this.rowsWh.push(application));

        this.page.totalElements = data.totalElements;
        this.page.totalPages = data.totalPages;
        this.page.pageNumber = data.number;
      },
      (error) => {
        console.log("error : ", error);
        if (error.status == "401") {
          this.router.navigate(["/logout"]);
        }
      }
    );
  }

  setPageCc(pageInfo) {
    this.selectedOffSet = pageInfo.offset;
    this.searchCc(pageInfo.offset);
  }

  searchCc(pageNumber) {
    this.rowsCc = new Array<CounsellingCard>();
    this.page = new Page();

    this.page.pageNumber = pageNumber;
    this.page.size = 10;

    this.counsellingCardService.search(this.searchParams, this.page).subscribe(
      (data) => {
        //console.log("data : " + JSON.stringify(data));

        this.rowsCc = new Array<CounsellingCard>();
        data.content.forEach((application) => this.rowsCc.push(application));

        this.page.totalElements = data.totalElements;
        this.page.totalPages = data.totalPages;
        this.page.pageNumber = data.number;

        if (this.selectedCc != undefined && this.selectedCc.length > 0) {
          let app = this.rowsCc.find((x) => x.id == this.selectedCc[0].id);
          this.selectedCc = [app];

          if (app != undefined && app.basicId != undefined) {
            this.basicSearchParams = new BasicMappingSearchParams();
            this.searchBasic(app);
          }
        }
      },
      (error) => {
        console.log("error : ", error);
        if (error.status == "401") {
          this.router.navigate(["/logout"]);
        }
      }
    );
  }

  setPageTa(pageInfo) {
    this.selectedOffSet = pageInfo.offset;
    this.searchTa(pageInfo.offset);
  }

  searchTa(pageNumber) {
    this.rowsTa = new Array<TertiaryApplicant>();
    this.page = new Page();

    this.page.pageNumber = pageNumber;
    this.page.size = 10;

    this.tertiaryApplicantService
      .search(this.searchParams, this.page)
      .subscribe(
        (data) => {
          //console.log("data : " + JSON.stringify(data));

          this.rowsTa = new Array<TertiaryApplicant>();
          data.content.forEach((application:any) => this.rowsTa.push(application));

        // forEach로 VisitDate 필드를 각 application 객체에 추가 0912
        this.rowsTa.forEach((application:any) => {
          if (application.note) {
            const match = application.note.match(/visit_date:\s*([^\s]+)/);
            application.VisitDate = match ? match[1] : 'N/A';  // VisitDate에 값 설정
          } else {
            application.VisitDate = 'N/A';  // note 필드가 없을 경우 기본값 설정
          }
        });

          console.log("rowsTa :", this.rowsTa);
          this.page.totalElements = data.totalElements;
          this.page.totalPages = data.totalPages;
          this.page.pageNumber = data.number;

          if (
            this.selectedTa != undefined &&
            this.selectedTa.length > 0 &&
            this.selectedTa[0].id != undefined &&
            this.rowsTa.length > 0
          ) {
            let app = this.rowsTa.find((x) => x.id == this.selectedTa[0].id);
            this.selectedTa = [app];

            if (app != undefined && app.basicId != undefined) {
              this.basicSearchParams = new BasicMappingSearchParams();
              this.searchBasic(app);
            }
          }

          if (this.parentData != null && this.linkedToEventDetail != -1) {
            this.selectedRowTa = this.rowsTa[0];
            this.taModal.show();
          }
        },
        (error) => {
          console.log("error : ", error);
          if (error.status == "401") {
            this.router.navigate(["/logout"]);
          }
        }
      );
  }

  deleteWh() {}

  deleteCc() {
    if (this.selectedRowCc == undefined || this.selectedRowCc.id == undefined)
      return;

    this.counsellingCardService.delete(this.selectedRowCc).subscribe(
      (data) => {
        console.log("delete successful");
        this.searchCc(this.selectedOffSet);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  deleteTa() {
    if (this.selectedRowTa == undefined || this.selectedRowTa.id == undefined)
      return;

    this.tertiaryApplicantService.delete(this.selectedRowTa).subscribe(
      (data) => {
        console.log("delete successful");
        this.searchTa(this.selectedOffSet);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  onSelect({ selected }) {
    //console.log('Select Application', selected, this.selected);
    this.basicMappingRows = new Array<Basic>();
    this.basicMappingPage = new Page();

    this.addEventClickCount = 0;
  }

  searchBasic(selectedApplication) {
    if (selectedApplication.basicId != undefined) {
      this.basicSearchParams.basicId = selectedApplication.basicId;
    }
    this.searchBasicMapping();
  }

  onActivateWh(obj) {
    if (obj.type === "click") {
      this.selectedRowWh = obj.row;

      this.basicSearchParams = new BasicMappingSearchParams();

      this.basicSearchParams.mdNameOrg = obj.row.kname;
      this.basicSearchParams.mdEMail = obj.row.email;
      this.basicSearchParams.mdKorMobile = obj.row.phone;
      this.basicSearchParams.camKakaoId = obj.row.kakao;
      this.basicSearchParams.mdDob = obj.row.dob;

      this.searchBasic(obj.row);
    }
  }

  onActivateCc(obj) {
    if (obj.type === "click") {
      this.selectedRowCc = obj.row;

      this.mobileCc = "";
      if (
        this.selectedRowCc.mobile != undefined &&
        this.selectedRowCc.mobile != ""
      ) {
        if (
          this.selectedRowCc.countryCode != undefined &&
          this.selectedRowCc.countryCode != ""
        ) {
          this.mobileCc = "(" + this.selectedRowCc.countryCode + ") ";
        }
        this.mobileCc = this.mobileCc + this.selectedRowCc.mobile;
      }

      //if(this.selectedRowCc.basicId == undefined || this.selectedRowCc.basicId == null) {
      //  this.disabledCreateBtn  = false;
      //} else {
      //  this.disabledCreateBtn  = true;
      //}

      this.basicSearchParams = new BasicMappingSearchParams();

      this.basicSearchParams.mdNameOrg = obj.row.firstName;
      this.basicSearchParams.mdEMail = obj.row.email;
      this.basicSearchParams.mdKorMobile = obj.row.mobile;
      this.basicSearchParams.mdDob = obj.row.dob;

      this.searchBasic(obj.row);
    } else if (obj.type == "dblclick") {
      this.selectedRowCc = obj.row;
      this.ccModal.show();
    }
  }

  onActivateTa(obj) {
    if (obj.type === "click") {
      this.selectedRowTa = obj.row;

      this.mobileTa = "";
      console.log('selectedRowTa:', this.selectedRowTa);
      if (
        this.selectedRowTa.mobile != undefined &&
        this.selectedRowTa.mobile != ""
      ) {
        if (
          this.selectedRowTa.countryCode != undefined &&
          this.selectedRowTa.countryCode != ""
        ) {
          this.mobileTa = "(" + this.selectedRowTa.countryCode + ") ";
        }
        this.mobileTa = this.mobileTa + this.selectedRowTa.mobile;
      }

      //if(this.selectedRowTa.basicId == undefined || this.selectedRowTa.basicId == null) {
      //  this.disabledCreateBtn  = false;
      //} else {
      //  this.disabledCreateBtn  = true;
      //}

      this.basicSearchParams = new BasicMappingSearchParams();

      this.basicSearchParams.mdNameOrg = obj.row.firstName;
      this.basicSearchParams.mdEMail = obj.row.email;
      this.basicSearchParams.mdKorMobile = "0" + obj.row.mobile;
      this.basicSearchParams.mdDob = obj.row.dob;

      this.searchBasic(obj.row);
    } else if (obj.type == "dblclick") {
      this.selectedRowTa = obj.row;
      this.taModal.show();
    }
  }

  hideCcModal() {
    this.ccModal.hide();
  }

  hideTaModal() {
    if (this.parentData != null) {
      this.linkedToEventDetail = -1;
    }

    this.taModal.hide();
  }

  get profileImage() {
    if (this.seletedImage == undefined) return this.seletedImage;

    return this.sanitizer.bypassSecurityTrustUrl(
      "data:image/jpg;base64," + this.seletedImage
    );
  }

  onDisplayImage(image) {
    this.seletedImage = image;
    this.imageModal.show();
  }

  onCloseImage() {
    this.imageModal.hide();
  }

  searchBasicMapping() {
    this.loadingIndicator2 = true;

    this.basicMappingRows = new Array<Basic>();
    this.basicMappingPage = new Page();

    this.basicMappingPage.pageNumber = 0;
    this.basicMappingPage.size = 10000;

    this.disabledCreateBtn = true;

    this.basicService
      .searchBasicMapping(this.basicSearchParams, this.basicMappingPage)
      .subscribe(
        (data) => {
          //console.log("data : " + JSON.stringify(data));

          this.basicMappingRows = data.content;

          //this.page.size = data.size;
          this.basicMappingPage.totalElements = data.totalElements;
          this.basicMappingPage.totalPages = data.totalPages;
          this.basicMappingPage.pageNumber = data.number;

          setTimeout(() => {
            this.loadingIndicator2 = false;
          }, 1500);

          this.disabledMappingBtn = true;
          console.log("noga data length: " + data.content.length);
          if (data.content.length == 0) {
            this.disabledCreateBtn = false;
          } else {
            this.disabledCreateBtn = true; //240328 = false;
          }
          console.log("noga disabledCreateBtn: " + this.disabledCreateBtn);
          console.log("noga disabledMappingBtn: " + this.disabledMappingBtn);
        },
        (error) => {
          this.loadingIndicator2 = false;
          console.log("error : ", error);
          if (error.status == "401") {
            this.router.navigate(["/logout"]);
          }

          this.disabledMappingBtn = true;
        }
      );
  }

  setPageBasicMapping(pageInfo) {
    this.basicMappingRows = new Array<Basic>();
    this.basicMappingPage = new Page();
    this.basicMappingPage.pageNumber = pageInfo.offset;
    this.basicMappingPage.size = 10000;

    this.searchBasicMapping();
  }

  onActivateBasicMapping(obj) {
    if (obj.type === "click") {
      this.selectedRowMapping = obj.row;

      if (
        this.basicSearchParams.basicId != undefined &&
        this.basicSearchParams.basicId != null &&
        this.basicSearchParams.basicId != ""
      ) {
        this.disabledMappingBtn = true;
      } else {
        this.disabledMappingBtn = false;
      }
    } else if (obj.type === "dblclick") {
      this.router.navigate(["/basicDetail", obj.row.id]);
    }
  }

  onSelectBasicMapping(obj) {}

  onMapMemberMSG() {
    console.log("onMapMemberMSG:");
    var tamobile1 = "" + this.selectedRowTa.mobile;
    var tamobile2 = "0" + this.selectedRowTa.mobile;

    var basicNZMmbile1 = "" + this.selectedMappedBasic[0].mdNzMobile;
    var basicNZMmbile2 = "0" + this.selectedMappedBasic[0].mdNzMobile;

    var basicKRMmbile1 = "" + this.selectedMappedBasic[0].mdKorMobile;
    var basicKRMmbile2 = "0" + this.selectedMappedBasic[0].mdKorMobile;

    //is TA?,
    //not have the same name?,
    //not have the same email?,
    //not have any similar phone numbers?
    //20240731
    let differentFields = [];

    if (this.searchParams.realType == "TA") {
      const mappedName = this.selectedMappedBasic[0].name.split("(")[1].split(")")[0].toLocaleUpperCase();
      const rowName = (this.selectedRowTa.lastName + " " + this.selectedRowTa.firstName).toLocaleUpperCase();
      if (mappedName.length > 4 && mappedName !== rowName) {
        differentFields.push(`Name (${mappedName} : ${rowName})`);
      }
    }

    if (this.selectedMappedBasic[0].mdEMail.length > 4 && this.selectedMappedBasic[0].mdEMail !== this.selectedRowTa.email) {
      differentFields.push(`Email (${this.selectedMappedBasic[0].mdEMail} : ${this.selectedRowTa.email})`);
    }

    if (basicNZMmbile1.length < 4 &&
      tamobile1 != basicNZMmbile1 &&
      tamobile1 != basicNZMmbile2 &&
      tamobile2 != basicNZMmbile1 &&
      this.selectedRowTa.countryCode == "64") {
      differentFields.push(`PhoneNumber (${basicNZMmbile1} : ${tamobile1})`);
    }

    if (differentFields.length > 0) {
      const fieldsString = differentFields.join(" ");
      this.popupMessage = `${fieldsString} are different. Update excluding these field?`;
      this.popup1.options = {
        header: "Information",
        color: "#5cb85c",
        widthProsentage: 50,
        animationDuration: 0,
        showButtons: true,
        confirmBtnContent: "Update",
        cancleBtnContent: "Cancel",
        confirmBtnClass: "btn btn-default",
        cancleBtnClass: "btn btn-default",        
      };
      this.popup1.show(this.popup1.options); // Uncomment this line to show the popup
      console.log("popup1.show:");
    } else {
      console.log("onMapMember:");
      this.onMapMember();
    }
    //20240731
  }
    


    /* //20240731
    if (
      (this.searchParams.realType == "TA" &&
        this.selectedMappedBasic[0].name
          .split("(")[1]
          .split(")")[0]
          .toLocaleUpperCase().length > 4 &&
        this.selectedMappedBasic[0].name
          .split("(")[1]
          .split(")")[0]
          .toLocaleUpperCase() !==
          (
            this.selectedRowTa.lastName +
            " " +
            this.selectedRowTa.firstName
          ).toLocaleUpperCase()) ||
      (this.selectedMappedBasic[0].mdEMail.length > 4 &&
        this.selectedMappedBasic[0].mdEMail !== this.selectedRowTa.email) ||
      (basicNZMmbile1.length < 4 &&
        tamobile1 != basicNZMmbile1 &&
        tamobile1 != basicNZMmbile2 &&
        tamobile2 != basicNZMmbile1 &&
        this.selectedRowTa.countryCode == "64") ||
      (basicKRMmbile1.length < 4 &&
        tamobile1 != basicKRMmbile1 &&
        tamobile1 != basicKRMmbile2 &&
        tamobile2 != basicKRMmbile1 &&
        this.selectedRowTa.countryCode != "64")
    ) {
      
      if (this.addEventClickCount == 0) {
        console.log("addEventClickCount:",this.addEventClickCount);
        this.popupMessage =
          "Either Name/Phone No or Email is inconsistent. Pls update the existing details.";

        this.popup1.options = {
          header: "Information",
          color: "#5cb85c",
          widthProsentage: 50,
          animationDuration: 0,
          showButtons: true,
          confirmBtnContent: "Yes (Pls update the existing details.)",
          cancleBtnContent: "No",
          confirmBtnClass: "btn btn-default",
          cancleBtnClass: "one-button-hidden",
        };
      } else if (this.addEventClickCount == 1) {
        console.log("addEventClickCount:",this.addEventClickCount);
        this.popupMessage =
          "Either Name/Phone No or Email is Still inconsistent, but I will keep the existing Name/Phone No/Email.";

        this.popup1.options = {
          header: "Information",
          color: "#5cb85c",
          widthProsentage: 50,
          animationDuration: 0,
          showButtons: true,
          confirmBtnContent:
            "Save - I will keep the existing Name/Phone No/Email",
          cancleBtnContent: "Close",
          confirmBtnClass: "btn btn-default",
          cancleBtnClass: "btn btn-default",
        };
      }
      console.log("NO onMapMember:");
      //this.popup1.show(this.popup1.options); //240328
    } else if (this.searchParams.realType == "TA") {
      this.onMapMember();
    } else {
      this.onMapMember();
    }
    
  }
  */
  onMapMember() {
    this.popup1.hide();

    if (this.searchParams.realType == "CC") {
      this.selectedRowCc.processing = "mapped";
      this.selectedRowCc.basicId = this.selectedRowMapping.id;

      this.counsellingCardService.update(this.selectedRowCc).subscribe(
        (data) => {
          console.log("update successful");

          this.search();
        },
        (error) => {
          console.log(error);
        }
      );
    } else if (this.searchParams.realType == "TA") {
      this.selectedRowTa.processing = "mapped";
      this.selectedRowTa.basicId = this.selectedRowMapping.id;

      this.tertiaryApplicantService.update(this.selectedRowTa).subscribe(
        (data) => {
          console.log("update successful");

          this.search();
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }

  onCreateMember() {
    if (this.searchParams.realType == "CC") {
      this.selectedRowCc.processing = "created";

      this.counsellingCardService.createBasic(this.selectedRowCc).subscribe(
        (data) => {
          console.log("create successful");

          this.router.navigate(["/basicDetail", data.basicId]);
          //this.search();
        },
        (error) => {
          console.log(error);
        }
      );
    } else if (this.searchParams.realType == "TA") {
      this.selectedRowTa.processing = "created";

      this.tertiaryApplicantService.createBasic(this.selectedRowTa).subscribe(
        (data) => {
          console.log("create successful");

          this.router.navigate(["/basicDetail", data.basicId]);
          //this.search();
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }

  onSaveFileAfterGetting(id) {
    this.tertiaryApplicantService.getFile(id).subscribe(
      (data) => {
        var byteContents = window.atob(data.file);
        var byteNumbers = new Array(byteContents.length);

        for (var i = 0; i < byteContents.length; i++) {
          byteNumbers[i] = byteContents.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);

        let blob = new Blob([byteArray]);
        FileSaver.saveAs(blob, data.fileName);
      },
      (error) => {
        console.log("error : ", error);
      }
    );
  }


  
}
