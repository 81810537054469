import { DatePipe, Location } from "@angular/common";
import { Component, OnInit, ViewChild, ViewContainerRef } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { Profile } from "app/model/profile";
import * as _ from "lodash";
import { Popup } from "ng2-opd-popup";
import { ModalDirective } from "ngx-bootstrap/modal";
import { filter, switchMap } from "rxjs/operators";
import { AdminHistoryComment } from "../../model/admin-history-comment";
import { AdminMemo } from "../../model/admin-memo";
import { Admission } from "../../model/admission";
import { Basic } from "../../model/basic";
import { CodeCommon } from "../../model/code-common";
import { AdminHistoryCommentService } from "../../services/admin-history-comment.service";
import { AdminMemoService } from "../../services/admin-memo.service";
import { AdmissionService } from "../../services/admission.service";
import { AuthenticationService } from "../../services/authentication.service";
import { BasicService } from "../../services/basic.service";
import { CodeService } from "../../services/code.service";
import Utils from "../../shared/utils";

@Component({
  selector: "app-admission-detail",
  providers: [
    AdmissionService,
    AdminMemoService,
    AdminHistoryCommentService,
    BasicService,
    DatePipe,
  ],
  templateUrl: "./admission-detail.component.html",
  styles: [
    `
      .line-breaker {
        white-space: pre-line;
      }
    `,
  ],
})
export class AdmissionDetailComponent implements OnInit {
  basicDSCdisabledUpdate: boolean = true;

  public admissionRows = new Array<Admission>();
  public adminMemoRows = new Array<AdminMemo>();

  public adminHistoryCommentList = new Array<AdminHistoryComment>();
  keyNote: string;

  public selectedAdmissionRow = new Admission();
  public selectedAdminMemoRow = new AdminMemo();

  @ViewChild("admissionModal") public admissionModal: ModalDirective;
  @ViewChild("adminMemoModal") public adminMemoModal: ModalDirective;
  @ViewChild("childModal") public childModal: ModalDirective;

  @ViewChild("popup1") popup1: Popup;
  popupMessage: string;

  searchTerm: string;

  selectedBasicId: number;
  basic = new Basic();

  selectedAdminId: number;

  schoolTypeList: any[] = [];
  schoolNameList: any[] = [];
  courseList: any[] = [];
  subAgentList: any[] = [];
  adminAgentList: any[] = [];
  epList: any[] = [];

  memoTypeList: any[] = [];
  adminStatusList: any[] = [];
  emailEpList: any[] = [];
  msgTo: any[] = [];
  emailTo: any[] = [];

  isBonusPayment: boolean = false;

  schoolTypeListForVisa: any[] = [
    { code: "Accreditation", value: "Accreditation" },
    { code: "Job Check", value: "Job Check" },
    { code: "Section 61", value: "Section 61" },
    { code: "Student Visa", value: "Student Visa" },
    { code: "Visitor Visa - ", value: "Visitor Visa - " },
    { code: "Visitor Visa - Guardian", value: "Visitor Visa - Guardian" },
    { code: "Visitor Visa - Partnership", value: "Visitor Visa - Partnership" },
    { code: "Work Visa - AEWV", value: "Work Visa - AEWV" },
    { code: "Work Visa - Essential Skill", value: "Work Visa - Essential Skill",},
    { code: "Work Visa - Graduate Visa", value: "Work Visa - Graduate Visa" },
    { code: "Work Visa - Partnership", value: "Work Visa - Partnership" },
    { code: "Working Holiday Visa", value: "Working Holiday Visa" },
  ];

  schoolTypeListForInsurance: any[] = [
    {
      code: "Done By School(Paid to KOKOS)",
      value: "Done By School(Paid to KOKOS)",
    },
    { code: "Student Lite(Orbit)", value: "Student Lite(Orbit)" },
    { code: "Student Prime(Orbit)", value: "Student Prime(Orbit)" },
    { code: "Done By Subagent", value: "Done By Subagent" },
    { code: "Family Lite(Orbit)", value: "Family Lite(Orbit)" },
    { code: "Individual Prime(Orbit)", value: "Individual Prime(Orbit)" },
    { code: "Individual Lite(Orbit)", value: "Individual Lite(Orbit)" },
    { code: "Working Holiday(Orbit)", value: "Working Holiday(Orbit)" },
  ];

  schoolTypeListForOtherServices: any[] = [
    { code: "Admin Service (Refundable)", value: "Admin Service (Refundable)" },
    { code: "Admin Service (No-Retund)", value: "Admin Service (No-Retund)" },
    { code: "Visa Service Fee", value: "Visa Service Fee" },
    { code: "Consulting Service (Edu)", value: "Consulting Service (Edu)" },
    { code: "Consulting Service (Visa)", value: "Consulting Service (Visa)" },
    {
      code: "School Viewing/Tour Service",
      value: "School Viewing/Tour Service",
    },
    { code: "Airport Pick Up Service", value: "Airport Pick Up Service" },
    { code: "Airport Drop Service", value: "Airport Drop Service" },
    { code: "Settlement Service", value: "Settlement Service" },
    { code: "Guardianship Service", value: "Guardianship Service" },
    { code: "Other Service", value: "Other Service" },
  ];
  //{ code: 'Bonus Commission from School', value: 'Bonus Commission from School' },

  //for course setting
  secondary: any[] = [
    "Year 1",
    "YR 10",
    "YR 11",
    "YR 12",
    "YR 13",
    "YR 2",
    "YR 3",
    "YR 4",
    "YR 5",
    "YR 6",
    "YR 7",
    "YR 8",
    "YR 9",
  ];
  auSecondary: any[] = [
    "Year 1",
    "YR 10",
    "YR 11",
    "YR 12",
    "YR 13",
    "YR 2",
    "YR 3",
    "YR 4",
    "YR 5",
    "YR 6",
    "YR 7",
    "YR 8",
    "YR 9",
  ];
  elicos: any[] = ["GE", "1", "2", "TECSOL", "CFZ", "BST", "NZCEL", "EAP"];
  pteUni: any[] = [
    "7",
    "5",
    "3",
    "Graduate Diploma",
    "Master",
    "DT",
    "GCZ",
    "PDP",
    "PCT",
    "6",
  ];

  displayMessage: string;

  validStatusList: any[] = [
    { code: "Enrolled", value: ["Enrolled", "wd", "an", "Admin Holding"] },
    {
      code: "1_RO",
      value: ["2_AS", "wd", "dr", "ADM EP Change Req", "an", "Admin Holding"],
    },
    {
      code: "2_AS",
      value: [
        "2_AS",
        "3_PS",
        "4_CO",
        "6_UO",
        "wd",
        "Rejected",
        "ADM EP Change Req",
        "dr",
        "an",
        "Admin Holding",
      ],
    },
    {
      code: "3_PS",
      value: [
        "2_AS",
        "3_PS",
        "4_CO",
        "6_UO",
        "wd",
        "Rejected",
        "ADM EP Change Req",
        "dr",
        "Defer",
        "an",
        "Admin Holding",
      ],
    },
    {
      code: "4_CO",
      value: [
        "4_CO",
        "6_UO",
        "wd",
        "Rejected",
        "ADM EP Change Req",
        "dr",
        "Defer",
        "an",
        "Admin Holding",
      ],
    },
    {
      code: "5_FS",
      value: [
        "5_FS",
        "6_UO",
        "wd",
        "Rejected",
        "ADM EP Change Req",
        "dr",
        "Defer",
        "an",
        "Admin Holding",
      ],
    },
    {
      code: "6_UO",
      value: [
        "6_UO",
        "wd",
        "Rejected",
        "ADM EP Change Req",
        "dr",
        "Defer",
        "an",
        "Admin Holding",
      ],
    },
    {
      code: "wd",
      value: ["wd", "an", "2_AS", "4_CO", "6_UO", "Admin Holding"],
    },
    {
      code: "Defer",
      value: [
        "Defer",
        "3_PS",
        "4_CO",
        "6_UO",
        "wd",
        "Rejected",
        "ADM EP Change Req",
        "dr",
        "an",
        "Admin Holding",
      ],
    },
    { code: "dr", value: ["dr", "an"] },
    { code: "OnGoing", value: ["2_AS", "OnGoing", "4_CO", "6_UO", "wd", "an"] },
    {
      code: "Admin Holding",
      value: ["2_AS", "wd", "dr", "ADM EP Change Req", "an", "Admin Holding"],
    },
    { code: "Cancelled", value: ["an", "6_UO"] },
  ];

  intake: NgbDateStruct;
  intakeDate: NgbDateStruct;
  startingDate: NgbDateStruct;
  endDate: NgbDateStruct;

  mmAlarmDate: NgbDateStruct;
  adminHoldingDate: NgbDateStruct;
  visaAppliedDate: NgbDateStruct;
  visaCreditPurchaseDate: NgbDateStruct;

  insStartDate: NgbDateStruct;
  insEndDate: NgbDateStruct;
  insPurchaseDate: NgbDateStruct;

  osEndDate: NgbDateStruct;
  osStartDate: NgbDateStruct;

  epChangedList: any[] = [];
  selected: any[] = [];

  disabledRequest: boolean = true;
  disabledStuId: boolean = false;
  disabledStuIdPwd: boolean = false;

  isOnGoing: boolean = false;
  isSendToAc: boolean = false;
  adminMessage: string;

  authority: string;
  name: string;
  company: string;

  onUpdateStatusStr: string;
  onDeleteHistoryCommentMsgStr: string;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private admissionService: AdmissionService,
    private adminMemoService: AdminMemoService,
    private adminHistoryCommentService: AdminHistoryCommentService,
    private authenticationService: AuthenticationService,
    private basicService: BasicService,
    private codeService: CodeService,
    private datePipe: DatePipe,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location
  ) {
    authenticationService.profile$
      .pipe(filter<Profile>(Boolean))
      .subscribe((profile) => {
        this.name = profile.name;
        this.authority = profile.authority;
        this.company = profile.company;
      });

    codeService.getCommon(this);
  }

  setCodeList(codeCommon: CodeCommon) {
    if (codeCommon == undefined) {
      this.router.navigate(["/logout"]);
    }
    this.schoolTypeList = codeCommon.schoolTypeList;
    this.schoolNameList = codeCommon.schoolNameList;
    this.courseList = codeCommon.courseList;
    this.subAgentList = codeCommon.subAgentList.filter((x) => x.code != "self");
    this.adminAgentList = codeCommon.adminAgentList;
    this.epList = JSON.parse(JSON.stringify(codeCommon.epList));

    this.memoTypeList = JSON.parse(JSON.stringify(codeCommon.memoTypeList));
    //this.adminStatusList= codeCommon.adminStatusList;
    this.emailEpList = JSON.parse(JSON.stringify(codeCommon.epList));

    this.setInit();
  }

  ngOnInit() {}

  selectSchool(val: any) {
    this.selectedAdmissionRow.school = val;
    this.onChangeSchool();
  }

  setInit() {
    var admId = null;

    this.route.params
      .pipe(
        switchMap((params: Params) => {
          this.selectedBasicId = +params["id"];
          admId = +params["admid"];
          return this.admissionService.getList(+params["id"]);
        })
      )
      .subscribe(
        (data) => {
          //console.log("data : " + JSON.stringify(data));
          //console.log("data.length : " + data.length);

          this.admissionRows = new Array<Admission>();

          let admin = new Admission();
          admin.baseCourse1Name = "click here to start";
          this.admissionRows.push(admin);

          data.forEach((admission) => this.admissionRows.push(admission));

          if (admId != null) {
            let account = this.admissionRows.find((x) => x.id === admId);
            this.selected = [account];
            this.onSelected(account);
          }

          //console.log("this.admissionRows : " + JSON.stringify(this.admissionRows));
        },
        (error) => {
          console.log("error : ", error);
          if (error.status == "401") {
            this.router.navigate(["/logout"]);
          }
        }
      );

    /*
    
    ).add(() => {

      console.log("a");
      if(admId != null) {
        console.log("b");
        console.log(this.admissionRows.length);
 
        //this.onSelected(this.admissionRows[0]);
        //this.selectedAdminId = admId;
        //console.log(admId);
      }
      else
      {
        console.log("c");
        //console.log(admId);
      }
 });
 */

    this.basicService.get(this.selectedBasicId).subscribe((basic) => {
      this.basic = basic;
    });
  }

  find() {
    this.admissionService.getList(this.selectedBasicId).subscribe(
      (data) => {
        //console.log("data : " + JSON.stringify(data));
        //console.log("data.length : " + data.length);

        this.admissionRows = new Array<Admission>();

        let admin = new Admission();
        admin.baseCourse1Name = "click here to start";
        this.admissionRows.push(admin);

        data.forEach((admission) => this.admissionRows.push(admission));

        //console.log("this.admissionRows : " + JSON.stringify(this.admissionRows));

        //console.log("this.selectedAdminId : " + this.selectedAdminId);
        let account = this.admissionRows.find(
          (x) => x.id === this.selectedAdminId
        );

        //console.log("account : " + JSON.stringify(this.selectedAdminId));
        this.selected = [account];

        this.onSelected(account);
      },
      (error) => {
        console.log("error : ", error);
        if (error.status == "401") {
          this.router.navigate(["/logout"]);
        }
      }
    );
  }

  onSelectAdmission({ selected }) {
    //console.log('Select Event', selected);
  }

  onActivateAdmission(obj) {
    this.adminMessage = "";

    if (obj.type === "click") {
      this.onSelected(obj.row);
    } else if (obj.type === "dblclick") {
      if (obj.row.id == undefined) return;

      this.searchTerm = "";
      this.selectedAdmissionRow = obj.row;
      this.onChangeSchool();
      this.onChangeSchoolType();
      this.convStrToDate();

      if (
        this.authority === "manager" ||
        _.includes(
          [undefined, "UnApplied", "Holding", "Estimated"],
          this.selectedAdmissionRow.accountStatus
        )
      ) {
        this.disabledStuId = false; //this.selectedAdmissionRow.appId != undefined ? true : false;;
        this.disabledStuIdPwd = false; //this.selectedAdmissionRow.stuIdPwd != undefined ? true : false;

        this.admissionModal.show();
      } else {
        this.disabledStuId = true;
        this.disabledStuIdPwd = true;
      }
    }
  }

  onSelected(row) {
    if (row.id === undefined) {
      this.selectedAdmissionRow = new Admission();
      this.adminMemoRows = new Array<AdminMemo>();
      this.adminHistoryCommentList = new Array<AdminHistoryComment>();
      this.isSendToAc = false;

      if (this.basic.fileCount == 0) {
        this.disabledRequest = true;
        this.isOnGoing = false;
        this.adminMessage =
          "There is no file. Please upload a file to request.";
      } else {
        this.disabledRequest = false;

        if (this.admissionRows.some((x) => x.adminStatus == "Enrolled")) {
          this.isOnGoing = true;
        } else {
          this.isOnGoing = false;
        }
      }
    } else {
      this.selectedAdmissionRow = row;
      this.disabledRequest = true;
      this.findMemo();

      //console.log("this.selectedAdmissionRow.adminStatus : " + this.selectedAdmissionRow.adminStatus + " this.selectedAdmissionRow.admissionSchoolType : " + this.selectedAdmissionRow.admissionSchoolType);
      if (
        this.selectedAdmissionRow.adminStatus == "6_UO" ||
        ((this.selectedAdmissionRow.admissionSchoolType == "ELICOS" ||
          this.selectedAdmissionRow.admissionSchoolType == "Others" ||
          this.selectedAdmissionRow.admissionSchoolType == "Service" ||
          this.selectedAdmissionRow.admissionSchoolType == "Immigration") &&
          this.selectedAdmissionRow.adminStatus != "Enrolled" &&
          this.selectedAdmissionRow.adminStatus != "wd" &&
          this.selectedAdmissionRow.adminStatus != "Cancelled")
      ) {
        this.isSendToAc = true;
      } else {
        this.isSendToAc = false;
      }

      this.isOnGoing = false;

      this.selectedAdminId = this.selectedAdmissionRow.id;

      this.emailEpList.forEach((x) => {
        if (
          this.name != this.selectedAdmissionRow.adminEp &&
          this.selectedAdmissionRow.adminEp == x.code
        ) {
          x.checked = true;
        } else {
          x.checked = false;
        }
      });
    }
  }

  onChangeSchoolType() {
    //this.schoolNameList = this.codeService.codeCommon.schoolNameList.filter(code => code.value3 === this.selectedAdmissionRow.admissionSchoolType);

    //this.selectedAdmissionRow.school = '';

    if (this.selectedAdmissionRow.admissionSchoolType == "Secondary") {
      this.courseList = this.codeService.codeCommon.courseList.filter((code) =>
        this.secondary.some((x) => x === code.code)
      );
      this.displayMessage =
        "Passport, E-signature, Acommodation Request, Parent Name Contact Number";
    } else if (
      this.selectedAdmissionRow.admissionSchoolType == "AU Secondary"
    ) {
      this.courseList = this.codeService.codeCommon.courseList.filter((code) =>
        this.auSecondary.some((x) => x === code.code)
      );
      this.displayMessage =
        "Passport, E-signature, Acommodation Request, Parent Name Contact Number";
    } else if (this.selectedAdmissionRow.admissionSchoolType == "ELICOS") {
      this.courseList = this.codeService.codeCommon.courseList.filter((code) =>
        this.elicos.some((x) => x === code.code)
      );
      this.displayMessage = "Passport, E-signature, Acommodation Request";
    } else if (
      ["NZ PTE", "NZ Uni/Poly", "AU Uni", "AU PTE", "AU PTE"].some(
        (tp) => tp === this.selectedAdmissionRow.admissionSchoolType
      )
    ) {
      this.courseList = this.codeService.codeCommon.courseList.filter((code) =>
        this.pteUni.some((x) => x === code.code)
      );
      this.displayMessage = "Passport, E-signature";
    } else {
      this.courseList = this.codeService.codeCommon.courseList;
      this.displayMessage = "Passport, E-signature";
    }

    //this.selectedAdmissionRow.baseCourse1 = '';
    this.calculateEndDate();
  }

  onChangeSchool() {
    //School Type organised by School Name.
    for (var val of this.codeService.codeCommon.schoolNewList) {
      if (
        this.selectedAdmissionRow.school === val.code ||
        this.selectedAdmissionRow.school == val.code
      ) {
        //after comma, it always have a space so needs to split by ", " not ",".
        var val2 = val.courseTypes.split(", ");

        this.schoolTypeList = this.codeService.codeCommon.schoolTypeList.filter(
          function (item) {
            if (val2.includes(item.code)) {
              return item;
            }
          }
        );

        //If School Type is only one detected select it automatically.
        if (this.schoolTypeList.length == 1) {
          this.selectedAdmissionRow.admissionSchoolType =
            this.schoolTypeList[0].code;
          this.onChangeSchoolType();
        }

        break;
      }
    }

    let that = this;
    this.schoolNameList.forEach(function (element) {
      if (element.code == that.selectedAdmissionRow.school) {
        that.selectedAdmissionRow.auNzCheck = element.value2;
        return;
      }
    });
  }

  onChangeCourse() {
    this.selectedAdmissionRow.baseCourse2 = "";
  }

  showAdmissionModal(status): void {
    this.isBonusPayment = false;

    this.selected.splice(0, this.selected.length);
    this.adminMemoRows = new Array<AdminMemo>();

    var onGoingSchool = this.selectedAdmissionRow.school;
    var onGoingSchoolType = this.selectedAdmissionRow.admissionSchoolType;
    var onGoingAdminEp = this.selectedAdmissionRow.adminEp;
    var onGoingAdminAgent = this.selectedAdmissionRow.adminAgent;

    this.convStrToDate();

    var bonusBaseCourse1 = this.selectedAdmissionRow.baseCourse1;
    var bonusMajor = this.selectedAdmissionRow.major;
    var bonusIntake = this.intake;
    var bonusEndDate = this.endDate;
    var bonusStuId = this.selectedAdmissionRow.stuId;
    var bonusStuIdPwd = this.selectedAdmissionRow.stuIdPwd;
    var bonusCpSubAgent = this.selectedAdmissionRow.cpSubAgent;
    var bonusAdminAgent = this.selectedAdmissionRow.adminAgent;
    var bonusAdminEp = this.selectedAdmissionRow.adminEp;

    this.selectedAdmissionRow = new Admission();

    //this.schoolNameList = [];
    this.schoolTypeList = [];

    this.courseList = [];
    this.intake = undefined;
    this.startingDate = undefined;
    this.adminHoldingDate = undefined;
    this.endDate = undefined;
    this.visaAppliedDate = undefined;
    this.visaCreditPurchaseDate = undefined;

    this.insStartDate = undefined;
    this.insEndDate = undefined;
    this.insPurchaseDate = undefined;

    this.osStartDate = undefined;
    this.osEndDate = undefined;

    this.displayMessage = "";

    this.selectedAdmissionRow.admissionSchoolType = undefined;
    this.selectedAdmissionRow.school = undefined;
    this.selectedAdmissionRow.baseCourse1 = undefined;
    //this.selectedAdmissionRow.baseCourse2         = undefined;
    this.selectedAdmissionRow.cpSubAgent = undefined;

    if (status == "OnGoing") {
      /*
      for (var idx = 0; idx < this.admissionRows.length; idx++) {
        if (this.admissionRows[idx].adminStatus == "Enrolled") {
          this.selectedAdmissionRow.admissionSchoolType = this.admissionRows[idx].admissionSchoolType;
          this.selectedAdmissionRow.school = this.admissionRows[idx].school;
          this.selectedAdmissionRow.adminEp = this.admissionRows[idx].adminEp;
          this.selectedAdmissionRow.adminAgent = this.admissionRows[idx].adminAgent;

          this.onChangeSchool();
          this.onChangeSchoolType();
          break;
        }
      }
      */

      this.selectedAdmissionRow.admissionSchoolType = onGoingSchoolType;
      this.selectedAdmissionRow.school = onGoingSchool;
      this.selectedAdmissionRow.adminEp = onGoingAdminEp;
      this.selectedAdmissionRow.adminAgent = onGoingAdminAgent;

      this.onChangeSchool();
      this.onChangeSchoolType();

      this.selectedAdmissionRow.adminStatus = "OnGoing";
    } else if (status == "Bonus") {
      this.isBonusPayment = true;

      this.selectedAdmissionRow.admissionSchoolType = onGoingSchoolType;
      this.selectedAdmissionRow.school = onGoingSchool;
      this.selectedAdmissionRow.adminEp = onGoingAdminEp;
      this.selectedAdmissionRow.adminAgent = onGoingAdminAgent;

      this.selectedAdmissionRow.baseCourse1 = bonusBaseCourse1;
      this.selectedAdmissionRow.major = bonusMajor;

      this.intake = bonusIntake;
      this.endDate = bonusEndDate;

      this.selectedAdmissionRow.stuId = bonusStuId;
      this.selectedAdmissionRow.stuIdPwd = bonusStuIdPwd;
      this.selectedAdmissionRow.cpSubAgent = bonusCpSubAgent;
      this.selectedAdmissionRow.adminAgent = bonusAdminAgent;
      this.selectedAdmissionRow.adminEp = bonusAdminEp;

      this.onChangeSchool();
      this.onChangeSchoolType();

      this.selectedAdmissionRow.adminStatus = "Enrolled";
    } else {
      this.onSetDefault();
    }

    this.admissionModal.show();
  }

  onCancelAdmission() {
    this.admissionModal.hide();
    //this.childModal.show();
  }

  onValidateAdmission() {
    if (this.selectedAdmissionRow.school == "- Visa Only") {
      this.selectedAdmissionRow.major = "- Visa Only";
      this.selectedAdmissionRow.admissionSchoolType = "- Visa Only";

      if (this.selectedAdmissionRow.adminStatus == undefined) {
        this.selectedAdmissionRow.adminStatus = "Enrolled";
      }
    }

    if (this.selectedAdmissionRow.school == "- Insurance Only") {
      this.selectedAdmissionRow.major = "- Insurance Only";
      this.selectedAdmissionRow.admissionSchoolType = "- Insurance Only";
      //this.selectedAdmissionRow.major = "Insurance Only";

      if (this.selectedAdmissionRow.adminStatus == undefined) {
        this.selectedAdmissionRow.adminStatus = "Enrolled";
      }
    }

    if (this.selectedAdmissionRow.school == "- Other Services Only") {
      this.selectedAdmissionRow.major = "- Other Services Only";
      this.selectedAdmissionRow.admissionSchoolType = "- Other Services Only";

      if (this.selectedAdmissionRow.adminStatus == undefined) {
        this.selectedAdmissionRow.adminStatus = "Enrolled";
      }
    }

    this.popupMessage = "";

    if (this.selectedAdmissionRow.adminStatus == "OnGoing") {
      if (
        this.selectedAdmissionRow.admissionSchoolType == undefined ||
        this.selectedAdmissionRow.admissionSchoolType == ""
      ) {
        this.popupMessage = "School Type is required";
      } else if (
        this.selectedAdmissionRow.school == undefined ||
        this.selectedAdmissionRow.school == ""
      ) {
        this.popupMessage = "School Name is required";
      } else if (
        this.selectedAdmissionRow.adminAgent == undefined ||
        this.selectedAdmissionRow.adminAgent == ""
      ) {
        this.popupMessage = "Admin Agent is required";
      } else if (
        this.selectedAdmissionRow.adminEp == undefined ||
        this.selectedAdmissionRow.adminEp == ""
      ) {
        this.popupMessage = "Admin EP is required";
      } else if (
        this.selectedAdmissionRow.school != "- Other Services Only" &&
        this.selectedAdmissionRow.school != "- Visa Only" &&
        this.selectedAdmissionRow.school != "- Insurance Only" &&
        (this.selectedAdmissionRow.intake == undefined ||
          this.selectedAdmissionRow.intake == "")
      ) {
        this.popupMessage = "Intake is required";
      }
    } else {
      if (
        this.selectedAdmissionRow.admissionSchoolType == undefined ||
        this.selectedAdmissionRow.admissionSchoolType == ""
      ) {
        this.popupMessage = "School Type is required";
      } else if (
        this.selectedAdmissionRow.school == undefined ||
        this.selectedAdmissionRow.school == ""
      ) {
        this.popupMessage = "School Name is required";
      } else if (
        this.selectedAdmissionRow.baseCourse1 == undefined ||
        this.selectedAdmissionRow.baseCourse1 == ""
      ) {
        this.popupMessage = "Course is required";
      } else if (
        this.selectedAdmissionRow.major == undefined ||
        this.selectedAdmissionRow.major == ""
      ) {
        if (
          this.selectedAdmissionRow.admissionSchoolType != "ELICOS" &&
          this.selectedAdmissionRow.admissionSchoolType != "Secondary" &&
          this.selectedAdmissionRow.admissionSchoolType != "AU Secondary"
        ) {
          this.popupMessage = "Major is required";
        }
      } else if (
        this.selectedAdmissionRow.school != "- Other Services Only" &&
        this.selectedAdmissionRow.school != "- Visa Only" &&
        this.selectedAdmissionRow.school != "- Insurance Only" &&
        (this.selectedAdmissionRow.intake == undefined ||
          this.selectedAdmissionRow.intake == "")
      ) {
        this.popupMessage = "Intake is required";
      } else if (
        this.selectedAdmissionRow.adminAgent == undefined ||
        this.selectedAdmissionRow.adminAgent == ""
      ) {
        this.popupMessage = "Admin Agent is required";
      } else if (
        this.selectedAdmissionRow.adminEp == undefined ||
        this.selectedAdmissionRow.adminEp == ""
      ) {
        this.popupMessage = "Admin EP is required";
      }

      if (
        this.selectedAdmissionRow.admissionSchoolType == "ELICOS" &&
        (this.selectedAdmissionRow.weeks == null ||
          this.selectedAdmissionRow.weeks == "0")
      ) {
        this.popupMessage = "Weeks is required";
      }
    }

    if (this.selectedAdmissionRow.school == "- Insurance Only") {
      /*
      if(this.selectedAdmissionRow.insPolicyNumber == undefined || this.selectedAdmissionRow.insPolicyNumber == '') {
        this.popupMessage = "Policy No is required";
      }
      else if(this.selectedAdmissionRow.insStartDate == undefined || this.selectedAdmissionRow.insStartDate == '') {
        this.popupMessage = "Start Date is required";
      }
      else if(this.selectedAdmissionRow.insEndDate == undefined || this.selectedAdmissionRow.insEndDate == '') {
        this.popupMessage = "End Date is required";
      }
      else if(this.selectedAdmissionRow.insPurchaseDate == undefined || this.selectedAdmissionRow.insPurchaseDate == '') {
        this.popupMessage = "Purchase Date is required";
      }
      */
    }

    if (this.selectedAdmissionRow.school == "- Visa Only") {
      /*
      if(this.selectedAdmissionRow.visaCreditPurchaseDate == undefined || this.selectedAdmissionRow.visaCreditPurchaseDate == '') {
        this.popupMessage = "Purchase Date is required";
      }
      */
    }

    if (this.selectedAdmissionRow.school == "- Other Services Only") {
      //if(this.selectedAdmissionRow.osStartDate == undefined || this.selectedAdmissionRow.osStartDate == '') {
      //  this.popupMessage = "Start Date is required";
      //}
    }

    if (
      this.selectedAdmissionRow.baseCourse1 === undefined ||
      this.selectedAdmissionRow.baseCourse1 === ""
    ) {
      if (this.selectedAdmissionRow.school == "- Insurance Only") {
        this.popupMessage = "Insurance Type is required";
      } else if (this.selectedAdmissionRow.school == "- Visa Only") {
        this.popupMessage = "Visa Type is required";
      } else if (this.selectedAdmissionRow.school == "- Other Services Only") {
        this.popupMessage = "Service Type is required";
      } else {
        this.popupMessage = "Course is required";
      }
    }

    if (this.popupMessage != "") {
      this.popup1.options = {
        header: "Error",
        color: "red",
        widthProsentage: 40,
        animationDuration: 0,
        showButtons: true,
        confirmBtnContent: "OK",
        cancleBtnContent: "Cancel",
        confirmBtnClass: "btn btn-default",
        cancleBtnClass: "one-button-hidden",
      };

      this.popup1.show(this.popup1.options);
      return false;
    }

    return true;
  }

  onDeleteHistoryCommentMsg(id) {
    this.onDeleteHistoryCommentMsgStr = id;
    this.popupMessage = "Would you like to delete selected admission key memo?";

    this.popup1.options = {
      header: "Information",
      color: "#5cb85c",
      widthProsentage: 50,
      animationDuration: 0,
      showButtons: true,
      confirmBtnContent: "OK",
      cancleBtnContent: "Cancel",
      confirmBtnClass: "btn btn-default",
      cancleBtnClass: "btn btn-default",
    };

    this.popup1.show(this.popup1.options);
  }

  onUpdateDSCmsg() {
    this.popupMessage =
      "Would you like to update 'Desired School and Course by Priority?'";

    this.popup1.options = {
      header: "Information",
      color: "#5cb85c",
      widthProsentage: 50,
      animationDuration: 0,
      showButtons: true,
      confirmBtnContent: "OK",
      cancleBtnContent: "Cancel",
      confirmBtnClass: "btn btn-default",
      cancleBtnClass: "btn btn-default",
    };

    this.popup1.show(this.popup1.options);
  }

  onCreateAdmissionMsg() {
    this.popupMessage = "Would you like to create this admission?";

    this.popup1.options = {
      header: "Information",
      color: "#5cb85c",
      widthProsentage: 50,
      animationDuration: 0,
      showButtons: true,
      confirmBtnContent: "OK",
      cancleBtnContent: "Cancel",
      confirmBtnClass: "btn btn-default",
      cancleBtnClass: "btn btn-default",
    };

    this.popup1.show(this.popup1.options);
  }

  onUpdateDSC() {
    this.basicDSCdisabledUpdate = true;

    //업데이트 기능 추가 예정.
    this.basicService.update(this.basic).subscribe(
      (data) => {
        console.log("update successful");
        //staticModal.show();
        //alert("update is successful");
        this.popupMessage = "Succeed in updating";

        this.popup1.options = {
          header: "Information",
          color: "#5cb85c", // red, blue....
          widthProsentage: 20, // The with of the popou measured by browser width
          animationDuration: 0, // in seconds, 0 = no animation
          showButtons: true, // You can hide this in case you want to use custom buttons
          confirmBtnContent: "OK", // The text on your confirm button
          cancleBtnContent: "Cancel", // the text on your cancel button
          confirmBtnClass: "btn btn-default", // your class for styling the confirm button
          cancleBtnClass: "one-button-hidden", // you class for styling the cancel button
          //animation: "fadeInDown" // 'fadeInLeft', 'fadeInRight', 'fadeInUp', 'bounceIn','bounceInDown'
        };

        this.popup1.show(this.popup1.options);
        //setTimeout(()=>{this.popup1.hide()},1000);
        //setTimeout(()=>{this.find(data.id)},1000);
        this.basicDSCdisabledUpdate = false;
      },
      (error) => {
        console.log(error);
        this.popupMessage = "Fail to update";

        this.popup1.options = {
          header: "Error",
          color: "red", // red, blue....
          widthProsentage: 20, // The with of the popou measured by browser width
          animationDuration: 0, // in seconds, 0 = no animation
          showButtons: true, // You can hide this in case you want to use custom buttons
          confirmBtnContent: "OK", // The text on your confirm button
          cancleBtnContent: "Cancel", // the text on your cancel button
          confirmBtnClass: "btn btn-default", // your class for styling the confirm button
          cancleBtnClass: "one-button-hidden", // you class for styling the cancel button
          //animation: "fadeInDown" // 'fadeInLeft', 'fadeInRight', 'fadeInUp', 'bounceIn','bounceInDown'
        };

        this.popup1.show(this.popup1.options);
        this.basicDSCdisabledUpdate = false;
      }
    );
  }

  onCreateAdmission() {
    this.selectedAdmissionRow.basicId = this.selectedBasicId;

    this.convDateToStr();

    if (!this.onValidateAdmission()) return;

    if (this.isBonusPayment == true)
      this.selectedAdmissionRow.isBonusPayment = true;

    this.admissionService.create(this.selectedAdmissionRow).subscribe(
      (data) => {
        this.admissionModal.hide();
        console.log("create successful");
        this.popupMessage = "Succeed in creating";

        this.popup1.options = {
          header: "Information",
          color: "#5cb85c",
          widthProsentage: 20,
          animationDuration: 0,
          showButtons: true,
          confirmBtnContent: "OK",
          cancleBtnContent: "Cancel",
          confirmBtnClass: "btn btn-default",
          cancleBtnClass: "one-button-hidden",
        };

        this.popup1.show(this.popup1.options);

        this.selectedAdminId = data.id;

        this.adminMemoRows = new Array<AdminMemo>();
        this.epChangedList = [];
      },
      (error) => {
        console.log(error);
        this.popupMessage = "Fail to create";

        this.popup1.options = {
          header: "Error",
          color: "red",
          widthProsentage: 20,
          animationDuration: 0,
          showButtons: true,
          confirmBtnContent: "OK",
          cancleBtnContent: "Cancel",
          confirmBtnClass: "btn btn-default",
          cancleBtnClass: "one-button-hidden",
        };

        this.popup1.show(this.popup1.options);
      }
    );
  }

  onUpdateAdmission() {
    this.convDateToStr();

    if (!this.onValidateAdmission()) return;

    this.admissionService.update(this.selectedAdmissionRow).subscribe(
      (data) => {
        this.admissionModal.hide();
        console.log("update successful");
        this.popupMessage = "Succeed in updating";

        this.popup1.options = {
          header: "Information",
          color: "#5cb85c",
          widthProsentage: 20,
          animationDuration: 0,
          showButtons: true,
          confirmBtnContent: "OK",
          cancleBtnContent: "Cancel",
          confirmBtnClass: "btn btn-default",
          cancleBtnClass: "one-button-hidden",
        };

        this.popup1.show(this.popup1.options);

        this.selectedAdminId = data.id;

        this.adminMemoRows = new Array<AdminMemo>();
        this.epChangedList = [];
      },
      (error) => {
        console.log(error);
        this.admissionModal.hide();
        this.popupMessage = "Fail to update";

        this.popup1.options = {
          header: "Error",
          color: "red",
          widthProsentage: 20,
          animationDuration: 0,
          showButtons: true,
          confirmBtnContent: "OK",
          cancleBtnContent: "Cancel",
          confirmBtnClass: "btn btn-default",
          cancleBtnClass: "one-button-hidden",
        };

        this.popup1.show(this.popup1.options);
      }
    );
  }

  onDeleteAdmission() {
    this.popupMessage = "Would you like to delete selected admission?";

    this.popup1.options = {
      header: "Information",
      color: "#5cb85c",
      widthProsentage: 50,
      animationDuration: 0,
      showButtons: true,
      confirmBtnContent: "OK",
      cancleBtnContent: "Cancel",
      confirmBtnClass: "btn btn-default",
      cancleBtnClass: "btn btn-default",
    };

    this.popup1.show(this.popup1.options);
  }

  onDeleteAdmissionMemo() {
    this.popupMessage = "Would you like to delete selected admission memo?";

    this.popup1.options = {
      header: "Information",
      color: "#5cb85c",
      widthProsentage: 50,
      animationDuration: 0,
      showButtons: true,
      confirmBtnContent: "OK",
      cancleBtnContent: "Cancel",
      confirmBtnClass: "btn btn-default",
      cancleBtnClass: "btn btn-default",
    };

    this.popup1.show(this.popup1.options);
  }

  deleteAdminMemo() {
    this.adminMemoService.delete(this.selectedAdminMemoRow).subscribe(
      (data) => {
        console.log("delete successful");
        this.popupMessage = "Succeed in deleting";

        this.popup1.options = {
          header: "Information",
          color: "#5cb85c",
          widthProsentage: 20,
          animationDuration: 0,
          showButtons: true,
          confirmBtnContent: "OK",
          cancleBtnContent: "Cancel",
          confirmBtnClass: "btn btn-default",
          cancleBtnClass: "one-button-hidden",
        };

        this.popup1.show(this.popup1.options);

        this.adminMemoRows = new Array<AdminMemo>();
        this.epChangedList = [];
      },
      (error) => {
        console.log(error);
        this.popupMessage = "Fail to delete";

        this.popup1.options = {
          header: "Error",
          color: "red",
          widthProsentage: 20,
          animationDuration: 0,
          showButtons: true,
          confirmBtnContent: "OK",
          cancleBtnContent: "Cancel",
          confirmBtnClass: "btn btn-default",
          cancleBtnClass: "one-button-hidden",
        };

        this.popup1.show(this.popup1.options);
      }
    );
  }

  deleteAdmin() {
    this.admissionService.delete(this.selectedAdmissionRow).subscribe(
      (data) => {
        console.log("delete successful");
        this.popupMessage = "Succeed in deleting";

        this.popup1.options = {
          header: "Information",
          color: "#5cb85c",
          widthProsentage: 20,
          animationDuration: 0,
          showButtons: true,
          confirmBtnContent: "OK",
          cancleBtnContent: "Cancel",
          confirmBtnClass: "btn btn-default",
          cancleBtnClass: "one-button-hidden",
        };

        this.popup1.show(this.popup1.options);

        this.adminMemoRows = new Array<AdminMemo>();
        this.epChangedList = [];
      },
      (error) => {
        console.log(error);
        this.popupMessage = "Fail to delete";

        this.popup1.options = {
          header: "Error",
          color: "red",
          widthProsentage: 20,
          animationDuration: 0,
          showButtons: true,
          confirmBtnContent: "OK",
          cancleBtnContent: "Cancel",
          confirmBtnClass: "btn btn-default",
          cancleBtnClass: "one-button-hidden",
        };

        this.popup1.show(this.popup1.options);
      }
    );
  }

  hideAdmissionModal(): void {
    this.admissionModal.hide();
  }

  onUpdateStatusMsg(adminStatus) {
    this.onUpdateStatusStr = adminStatus;

    this.popupMessage = "Would you like to send to Accounting?";

    this.popup1.options = {
      header: "Information",
      color: "#5cb85c",
      widthProsentage: 50,
      animationDuration: 0,
      showButtons: true,
      confirmBtnContent: "OK",
      cancleBtnContent: "Cancel",
      confirmBtnClass: "btn btn-default",
      cancleBtnClass: "btn btn-default",
    };

    this.popup1.show(this.popup1.options);
  }

  onUpdateStatus(adminStatus) {
    if (this.basic.fileCount == 0) {
      this.popupMessage = "There is no file. please upload a file.";

      this.popup1.options = {
        header: "Information",
        color: "#5cb85c",
        widthProsentage: 30,
        animationDuration: 0,
        showButtons: true,
        confirmBtnContent: "OK",
        cancleBtnContent: "Cancel",
        confirmBtnClass: "btn btn-default",
        cancleBtnClass: "one-button-hidden",
      };

      this.popup1.show(this.popup1.options);

      return;
    }

    this.admissionService
      .updateStatus({
        id: this.selectedAdmissionRow.id,
        adminStatus: adminStatus,
      })
      .subscribe(
        (data) => {
          console.log("update successful");
          //staticModal.show();
          //alert("update is successful");
          this.popupMessage = "Succeed to request";

          this.popup1.options = {
            header: "Information",
            color: "#5cb85c",
            widthProsentage: 20,
            animationDuration: 0,
            showButtons: true,
            confirmBtnContent: "OK",
            cancleBtnContent: "Cancel",
            confirmBtnClass: "btn btn-default",
            cancleBtnClass: "one-button-hidden",
          };

          this.popup1.show(this.popup1.options);

          this.adminMemoRows = new Array<AdminMemo>();
          this.epChangedList = [];

          this.isSendToAc = false;

          this.selectedAdminId = data.id;
        },
        (error) => {
          console.log(error);
          this.popupMessage = "Fail to update";

          this.popup1.options = {
            header: "Error",
            color: "red",
            widthProsentage: 20,
            animationDuration: 0,
            showButtons: true,
            confirmBtnContent: "OK",
            cancleBtnContent: "Cancel",
            confirmBtnClass: "btn btn-default",
            cancleBtnClass: "one-button-hidden",
          };

          this.popup1.show(this.popup1.options);
        }
      );
  }

  convStrToDate() {
    if (
      this.selectedAdmissionRow.intake != undefined &&
      this.selectedAdmissionRow.intake.length == 10
    )
      this.intake = Utils.formatStrDate(this.selectedAdmissionRow.intake);
    else this.intake = undefined;
    if (
      this.selectedAdmissionRow.startingDate != undefined &&
      this.selectedAdmissionRow.startingDate.length == 10
    )
      this.startingDate = Utils.formatStrDate(
        this.selectedAdmissionRow.startingDate
      );
    else this.startingDate = undefined;
    if (
      this.selectedAdmissionRow.endDate != undefined &&
      this.selectedAdmissionRow.endDate.length == 10
    )
      this.endDate = Utils.formatStrDate(this.selectedAdmissionRow.endDate);
    else this.endDate = undefined;
    if (
      this.selectedAdmissionRow.adminHoldingDate != undefined &&
      this.selectedAdmissionRow.adminHoldingDate.length == 10
    )
      this.adminHoldingDate = Utils.formatStrDate(
        this.selectedAdmissionRow.adminHoldingDate
      );
    else this.adminHoldingDate = undefined;

    if (
      this.selectedAdmissionRow.visaAppliedDate != undefined &&
      this.selectedAdmissionRow.visaAppliedDate.length == 10
    )
      this.visaAppliedDate = Utils.formatStrDate(
        this.selectedAdmissionRow.visaAppliedDate
      );
    else this.visaAppliedDate = undefined;

    if (
      this.selectedAdmissionRow.visaCreditPurchaseDate != undefined &&
      this.selectedAdmissionRow.visaCreditPurchaseDate.length == 10
    )
      this.visaCreditPurchaseDate = Utils.formatStrDate(
        this.selectedAdmissionRow.visaCreditPurchaseDate
      );
    else this.visaCreditPurchaseDate = undefined;

    if (
      this.selectedAdmissionRow.insStartDate != undefined &&
      this.selectedAdmissionRow.insStartDate.length == 10
    )
      this.insStartDate = Utils.formatStrDate(
        this.selectedAdmissionRow.insStartDate
      );
    else this.insStartDate = undefined;

    if (
      this.selectedAdmissionRow.insEndDate != undefined &&
      this.selectedAdmissionRow.insEndDate.length == 10
    )
      this.insEndDate = Utils.formatStrDate(
        this.selectedAdmissionRow.insEndDate
      );
    else this.insEndDate = undefined;

    if (
      this.selectedAdmissionRow.insPurchaseDate != undefined &&
      this.selectedAdmissionRow.insPurchaseDate.length == 10
    )
      this.insPurchaseDate = Utils.formatStrDate(
        this.selectedAdmissionRow.insPurchaseDate
      );
    else this.insPurchaseDate = undefined;

    if (
      this.selectedAdmissionRow.osStartDate != undefined &&
      this.selectedAdmissionRow.osStartDate.length == 10
    )
      this.osStartDate = Utils.formatStrDate(
        this.selectedAdmissionRow.osStartDate
      );
    else this.osStartDate = undefined;

    if (
      this.selectedAdmissionRow.osEndDate != undefined &&
      this.selectedAdmissionRow.osEndDate.length == 10
    )
      this.osEndDate = Utils.formatStrDate(this.selectedAdmissionRow.osEndDate);
    else this.osEndDate = undefined;
  }

  convDateToStr() {
    if (this.intake != undefined)
      this.selectedAdmissionRow.intake = Utils.formatDateStr(this.intake);
    else this.selectedAdmissionRow.intake = undefined;
    if (this.startingDate != undefined)
      this.selectedAdmissionRow.startingDate = Utils.formatDateStr(
        this.startingDate
      );
    else this.selectedAdmissionRow.startingDate = undefined;
    if (this.endDate != undefined)
      this.selectedAdmissionRow.endDate = Utils.formatDateStr(this.endDate);
    else this.selectedAdmissionRow.endDate = undefined;
    if (this.adminHoldingDate != undefined)
      this.selectedAdmissionRow.adminHoldingDate = Utils.formatDateStr(
        this.adminHoldingDate
      );
    else this.selectedAdmissionRow.adminHoldingDate = undefined;

    if (this.visaAppliedDate != undefined)
      this.selectedAdmissionRow.visaAppliedDate = Utils.formatDateStr(
        this.visaAppliedDate
      );
    else this.selectedAdmissionRow.visaAppliedDate = undefined;

    if (this.visaCreditPurchaseDate != undefined)
      this.selectedAdmissionRow.visaCreditPurchaseDate = Utils.formatDateStr(
        this.visaCreditPurchaseDate
      );
    else this.selectedAdmissionRow.visaCreditPurchaseDate = undefined;

    if (this.insStartDate != undefined)
      this.selectedAdmissionRow.insStartDate = Utils.formatDateStr(
        this.insStartDate
      );
    else this.selectedAdmissionRow.insStartDate = undefined;

    if (this.insEndDate != undefined)
      this.selectedAdmissionRow.insEndDate = Utils.formatDateStr(
        this.insEndDate
      );
    else this.selectedAdmissionRow.insEndDate = undefined;

    if (this.insPurchaseDate != undefined)
      this.selectedAdmissionRow.insPurchaseDate = Utils.formatDateStr(
        this.insPurchaseDate
      );
    else this.selectedAdmissionRow.insPurchaseDate = undefined;

    if (this.osStartDate != undefined)
      this.selectedAdmissionRow.osStartDate = Utils.formatDateStr(
        this.osStartDate
      );
    else this.selectedAdmissionRow.osStartDate = undefined;

    if (this.osEndDate != undefined)
      this.selectedAdmissionRow.osEndDate = Utils.formatDateStr(this.osEndDate);
    else this.selectedAdmissionRow.osEndDate = undefined;
  }

  clickOKButton() {
    this.popup1.hide();

    if (
      this.popupMessage === "Succeed in creating" ||
      this.popupMessage === "Succeed in updating" ||
      this.popupMessage === "Succeed in deleting" ||
      this.popupMessage === "Succeed to request"
    ) {
      //this.onClear();
      this.find();

      this.basicService.get(this.selectedBasicId).subscribe((basic) => {
        this.basic = basic;
      });
    } else if (this.popupMessage === "Succeed in creating a memo") {
      this.find();
      this.findMemo();
    } else if (
      this.popupMessage === "Would you like to delete selected admission?"
    ) {
      this.deleteAdmin();
    } else if (
      this.popupMessage === "Would you like to delete selected admission memo?"
    ) {
      this.deleteAdminMemo();
    } else if (
      this.popupMessage === "Would you like to create this admission?"
    ) {
      this.onCreateAdmission();
    } else if (
      this.popupMessage === "Would you like to create this admission memo?"
    ) {
      this.onCreateAdminMemo();
    } else if (
      this.popupMessage === "Would you like to create this admission key memo?"
    ) {
      this.onCreateKey();
    } else if (this.popupMessage === "Would you like to send to Accounting?") {
      this.onUpdateStatus(this.onUpdateStatusStr);
    } else if (
      this.popupMessage ===
      "Would you like to delete selected admission key memo?"
    ) {
      this.onDeleteHistoryComment(this.onDeleteHistoryCommentMsgStr);
    } else if (
      this.popupMessage ==
      "Would you like to update 'Desired School and Course by Priority?'"
    ) {
      this.onUpdateDSC();
    }
  }

  clickCancleButton() {}

  showAdminMemoModal(): void {
    if (
      this.selectedAdmissionRow === undefined ||
      this.selectedAdmissionRow.id === undefined
    ) {
      this.popupMessage = "Please click an admission above to add new memo ";

      this.popup1.options = {
        header: "Information",
        color: "#5cb85c",
        widthProsentage: 55,
        animationDuration: 0,
        showButtons: true,
        confirmBtnContent: "OK",
        cancleBtnContent: "Cancel",
        confirmBtnClass: "btn btn-default",
        cancleBtnClass: "one-button-hidden",
      };

      this.popup1.show(this.popup1.options);
    } else {
      this.selectedAdminMemoRow = new AdminMemo();
      this.mmAlarmDate = null;
      this.adminHoldingDate = null;

      this.resetCheckedEmail();

      //this.informationCategory2List = this.informationCategoryList.find(x => x.code === this.informationBoard.boardCategory).children;
      //validStatusList
      if (
        this.validStatusList.find(
          (y) => y.code === this.selectedAdmissionRow.adminStatus
        ) !== undefined
      ) {
        this.adminStatusList =
          this.codeService.codeCommon.adminStatusList.filter((x) =>
            this.validStatusList
              .find((y) => y.code === this.selectedAdmissionRow.adminStatus)
              .value.some((z) => z === x.code)
          );
      }

      this.adminMemoModal.show();
    }
  }

  hideAdminMemoModal(): void {
    this.adminMemoModal.hide();
  }

  onSelectAdminMemo({ selected }) {
    //console.log('Select Event', selected);
  }

  onActivateAdminMemo(obj) {
    if (obj.type === "click") {
      this.selectedAdminMemoRow = obj.row;
    } else if (obj.type === "dblclick") {
      this.isBonusPayment = false;

      this.selectedAdminMemoRow = obj.row;
      if (
        this.selectedAdminMemoRow.mmAlarmDate != undefined &&
        this.selectedAdminMemoRow.mmAlarmDate !== "" &&
        this.selectedAdminMemoRow.mmAlarmDate.length == 10
      ) {
        this.mmAlarmDate = Utils.formatStrDate(
          this.selectedAdminMemoRow.mmAlarmDate
        );
      }

      if (
        this.selectedAdminMemoRow.adminHoldingDate != undefined &&
        this.selectedAdminMemoRow.adminHoldingDate !== "" &&
        this.selectedAdminMemoRow.adminHoldingDate.length == 10
      ) {
        this.adminHoldingDate = Utils.formatStrDate(
          this.selectedAdminMemoRow.adminHoldingDate
        );
      }

      if (
        this.selectedAdminMemoRow.emailAlarm != undefined &&
        this.selectedAdminMemoRow.emailAlarm !== ""
      ) {
        this.emailTo = this.selectedAdminMemoRow.emailAlarm
          .replace(" ", "")
          .split(",");
        this.emailEpList.forEach((ep) => {
          ep.checked = this.isCheckedEmail(ep.code);
        });
      } else {
        this.resetCheckedEmail();
      }

      //this.showAdminMemoModal();
    }
  }

  onValidateAdminMemo() {
    this.popupMessage = "";

    if (
      this.selectedAdminMemoRow.crmType === undefined ||
      this.selectedAdminMemoRow.crmType === ""
    ) {
      this.popupMessage = "Admin Status is required";
    } else if (
      this.selectedAdminMemoRow.crmType == "Admin Holding" &&
      (this.adminHoldingDate == null || this.adminHoldingDate === null)
    ) {
      this.popupMessage = "Admin Hoding date is required";
    } else if (
      this.selectedAdminMemoRow.crmType == "Defer" &&
      (this.intakeDate == null || this.intakeDate === null)
    ) {
      this.popupMessage = "Intake date is required";
    } else if (
      this.selectedAdminMemoRow.crmType == "Defer" &&
      Utils.compareStrDate(
        Utils.formatDateStr(this.intakeDate),
        Utils.formatDateStr(Utils.formatStrDate("today"))
      ) > 0
    ) {
      //console.log("yes " + Utils.compareStrDate(Utils.formatDateStr(this.intakeDate), Utils.formatDateStr(Utils.formatStrDate("today"))));
      this.popupMessage = "Intake date must be over today";
    }

    if (this.popupMessage !== "") {
      this.popup1.options = {
        header: "Error",
        color: "red",
        widthProsentage: 40,
        animationDuration: 0,
        showButtons: true,
        confirmBtnContent: "OK",
        cancleBtnContent: "Cancel",
        confirmBtnClass: "btn btn-default",
        cancleBtnClass: "one-button-hidden",
      };

      this.popup1.show(this.popup1.options);
      return false;
    }

    return true;
  }

  onCreateAdminMemoMsg() {
    this.popupMessage = "Would you like to create this admission memo?";

    this.popup1.options = {
      header: "Information",
      color: "#5cb85c",
      widthProsentage: 50,
      animationDuration: 0,
      showButtons: true,
      confirmBtnContent: "OK",
      cancleBtnContent: "Cancel",
      confirmBtnClass: "btn btn-default",
      cancleBtnClass: "btn btn-default",
    };

    this.popup1.show(this.popup1.options);
  }

  onCreateAdminMemo() {
    if (!this.onValidateAdminMemo()) return;

    this.selectedAdminMemoRow.basicId = this.selectedBasicId;
    this.selectedAdminMemoRow.adminId = this.selectedAdmissionRow.id;
    this.selectedAdminMemoRow.ep = this.selectedAdmissionRow.cpEp;
    this.selectedAdminMemoRow.adminEp = this.selectedAdmissionRow.adminEp;

    this.selectedAdminMemoRow.emailAlarm = this.selectedEmailEp.toString();

    this.selectedAdminMemoRow.adminStatus = this.selectedAdminMemoRow.crmType;

    this.selectedAdminMemoRow.adminHoldingDate = Utils.formatDateStr(
      this.adminHoldingDate
    );

    if (this.selectedAdminMemoRow.adminStatus == "Defer") {
      this.selectedAdminMemoRow.intake = Utils.formatDateStr(this.intakeDate);

      if (
        this.selectedAdmissionRow.admissionSchoolType === "ELICOS" &&
        this.intakeDate !== undefined
      ) {
        let date = new Date();
        let weeks =
          this.selectedAdmissionRow.weeks === undefined ||
          this.selectedAdmissionRow.weeks === ""
            ? 0
            : this.selectedAdmissionRow.weeks;
        let freeWeeks =
          this.selectedAdmissionRow.freeWeeks === undefined ||
          this.selectedAdmissionRow.freeWeeks === ""
            ? 0
            : this.selectedAdmissionRow.freeWeeks;

        let intake = new Date(Utils.formatDateStr(this.intakeDate));
        let endDate = this.datePipe.transform(
          new Date(
            date.setTime(
              intake.getTime() + (+weeks + +freeWeeks) * 86400000 * 7
            ) -
              86400000 * 3
          ),
          "yyyy-MM-dd"
        );
        this.selectedAdminMemoRow.endDate = endDate;
      }

      this.intake = null;
    }

    if (this.name == "Kokos") {
      // console.log("crmType : " + this.selectedAdminMemoRow.crmType);
      // console.log("admissionSchoolType : " + this.selectedAdmissionRow.admissionSchoolType);
      // console.log("school : " + this.selectedAdmissionRow.school);
      // console.log("baseCourse1 : " + this.selectedAdmissionRow.baseCourse1);
      // console.log("adminStatusList : " + JSON.stringify(this.adminStatusList));
      // console.log("schoolTypeList : " + JSON.stringify(this.schoolTypeList));
      // console.log("schoolNameList : " + JSON.stringify(this.schoolNameList));
      // console.log("courseList : " + JSON.stringify(this.courseList));
    }

    this.selectedAdminMemoRow.crmType = this.adminStatusList.find(
      (x) => x.code === this.selectedAdminMemoRow.crmType
    ).value;

    let admissionSchoolType = this.schoolTypeList.find(
      (x) => x.code === this.selectedAdmissionRow.admissionSchoolType
    );
    if (admissionSchoolType != undefined) {
      this.selectedAdminMemoRow.recSchoolType = admissionSchoolType.value;
    }

    let school = this.schoolNameList.find(
      (x) => x.code === this.selectedAdmissionRow.school
    );
    if (school != undefined) {
      this.selectedAdminMemoRow.recSchool = school.value;
    }

    let course = this.courseList.find(
      (x) => x.code === this.selectedAdmissionRow.baseCourse1
    );
    if (course != undefined) {
      this.selectedAdminMemoRow.recCourse = course.value;
    }

    this.selectedAdminMemoRow.recMajor = this.selectedAdmissionRow.major;
    //this.selectedAdminMemoRow.recStatus     =

    if (this.mmAlarmDate != undefined)
      this.selectedAdminMemoRow.mmAlarmDate = Utils.formatDateStr(
        this.mmAlarmDate
      );

    if (this.adminHoldingDate != undefined)
      this.selectedAdminMemoRow.adminHoldingDate = Utils.formatDateStr(
        this.adminHoldingDate
      );

    //console.log(this.selectedAdminMemoRow);

    this.adminMemoService.create(this.selectedAdminMemoRow).subscribe(
      (data) => {
        this.adminMemoModal.hide();
        console.log("create successful");
        //staticModal.show();
        //alert("update is successful");
        this.popupMessage = "Succeed in creating a memo";

        this.popup1.options = {
          header: "Information",
          color: "#5cb85c",
          widthProsentage: 30,
          animationDuration: 0,
          showButtons: true,
          confirmBtnContent: "OK",
          cancleBtnContent: "Cancel",
          confirmBtnClass: "btn btn-default",
          cancleBtnClass: "one-button-hidden",
        };

        this.selectedAdmissionRow.accountStatus =
          this.selectedAdminMemoRow.adminStatus;

        if (this.selectedAdminMemoRow.adminStatus == "Defer") {
          this.selectedAdmissionRow.intake = this.selectedAdminMemoRow.intake;
        }

        this.popup1.show(this.popup1.options);
        //setTimeout(()=>{this.popup1.hide()},1000);
        //setTimeout(()=>{this.find(data.id)},1000);

        this.selectedAdminId = data.adminId;

        this.selectedAdminMemoRow = {};
      },
      (error) => {
        console.log(error);
        this.adminMemoModal.hide();

        this.popupMessage = "Fail to create";

        this.popup1.options = {
          header: "Error",
          color: "red",
          widthProsentage: 20,
          animationDuration: 0,
          showButtons: true,
          confirmBtnContent: "OK",
          cancleBtnContent: "Cancel",
          confirmBtnClass: "btn btn-default",
          cancleBtnClass: "one-button-hidden",
        };

        this.popup1.show(this.popup1.options);
      }
    );
  }

  findMemo() {
    this.adminMemoService.getList(this.selectedAdmissionRow.id).subscribe(
      (data) => {
        //console.log("data : " + JSON.stringify(data));
        //console.log("data.length : " + data.length);

        this.adminMemoRows = new Array<AdminMemo>();
        data.forEach((adminMemo) => this.adminMemoRows.push(adminMemo));

        this.getEpChanged();
      },
      (error) => {
        console.log("error : ", error);
      }
    );

    this.onSelectHistoryComment();
  }

  onSelectHistoryComment() {
    this.adminHistoryCommentService
      .getList(this.selectedAdmissionRow.id)
      .subscribe(
        (data) => {
          //console.log("adminHistoryComment data : " + JSON.stringify(data));
          //console.log("adminHistoryComment data.length : " + data.length);

          this.adminHistoryCommentList = new Array<AdminHistoryComment>();
          data.forEach((comment) => this.adminHistoryCommentList.push(comment));
        },
        (error) => {
          console.log("error : ", error);
        }
      );
  }

  onCreateKeyMsg() {
    this.popupMessage = "Would you like to create this admission key memo?";

    this.popup1.options = {
      header: "Information",
      color: "#5cb85c",
      widthProsentage: 50,
      animationDuration: 0,
      showButtons: true,
      confirmBtnContent: "OK",
      cancleBtnContent: "Cancel",
      confirmBtnClass: "btn btn-default",
      cancleBtnClass: "btn btn-default",
    };

    this.popup1.show(this.popup1.options);
  }

  onCreateKey() {
    if (this.keyNote == undefined) return;

    let adminHistoryComment = new AdminHistoryComment();

    adminHistoryComment.adminId = this.selectedAdmissionRow.id;
    adminHistoryComment.commentBody = this.keyNote;

    this.adminHistoryCommentService.create(adminHistoryComment).subscribe(
      (data) => {
        console.log("create successful");
        //staticModal.show();
        //alert("update is successful");
        this.popupMessage = "Succeed in creating";

        this.popup1.options = {
          header: "Information",
          color: "#5cb85c",
          widthProsentage: 20,
          animationDuration: 0,
          showButtons: true,
          confirmBtnContent: "OK",
          cancleBtnContent: "Cancel",
          confirmBtnClass: "btn btn-default",
          cancleBtnClass: "one-button-hidden",
        };

        this.popup1.show(this.popup1.options);

        this.keyNote = "";
        this.onSelectHistoryComment();
      },
      (error) => {
        console.log(error);
        this.popupMessage = "Fail to create";

        this.popup1.options = {
          header: "Error",
          color: "red",
          widthProsentage: 20,
          animationDuration: 0,
          showButtons: true,
          confirmBtnContent: "OK",
          cancleBtnContent: "Cancel",
          confirmBtnClass: "btn btn-default",
          cancleBtnClass: "one-button-hidden",
        };

        this.popup1.show(this.popup1.options);
      }
    );
  }

  onDeleteHistoryComment(id) {
    let comment = new AdminHistoryComment();
    comment.id = id;
    this.adminHistoryCommentService.delete(comment).subscribe(
      (data) => {
        //console.log("onDeleteHistoryComment data : " + JSON.stringify(data));
        this.onSelectHistoryComment();
      },
      (error) => {
        console.log("onDeleteHistoryComment error : " + JSON.stringify(error));
      }
    );
  }

  isCheckedMessage(code: string) {
    return this.msgTo.some((x) => x === code);
  }

  get selectedEmailEp() {
    return this.emailEpList
      .filter((opt) => opt.checked)
      .map((opt) => opt.value);
  }

  isCheckedEmail(code: string) {
    return this.emailTo.some((x) => x === code);
  }

  resetCheckedEmail() {
    this.emailEpList.forEach((x) => {
      if (
        (this.name != this.selectedAdmissionRow.adminEp &&
          this.selectedAdmissionRow.adminEp == x.code) ||
        (this.name != this.selectedAdmissionRow.cpEp &&
          this.selectedAdmissionRow.cpEp == x.code)
      ) {
        x.checked = true;
      } else {
        x.checked = false;
      }
    });
  }

  onSelectedMenu(menu) {
    this.router.navigate(["/" + menu, this.selectedBasicId]);
  }

  getEpChanged() {
    this.epChangedList = [];
    //console.log("adminMemoRows : " + this.adminMemoRows.length);

    let temp = "";
    this.adminMemoRows.reverse().forEach((memo) => {
      if (temp !== memo.adminEpName) {
        this.epChangedList.push(
          "[" + memo.createDate + "]   Admin EP : " + memo.adminEpName
        );
      }
      temp = memo.adminEpName;
    });

    this.adminMemoRows.reverse();
    this.epChangedList = this.epChangedList.reverse();
  }

  calculateEndDate() {
    if (
      this.selectedAdmissionRow.admissionSchoolType === "ELICOS" &&
      this.intake !== undefined
    ) {
      let date = new Date();
      let weeks =
        this.selectedAdmissionRow.weeks === undefined ||
        this.selectedAdmissionRow.weeks === ""
          ? 0
          : this.selectedAdmissionRow.weeks;
      let freeWeeks =
        this.selectedAdmissionRow.freeWeeks === undefined ||
        this.selectedAdmissionRow.freeWeeks === ""
          ? 0
          : this.selectedAdmissionRow.freeWeeks;

      let intake = new Date(Utils.formatDateStr(this.intake));
      let endDate = this.datePipe.transform(
        new Date(
          date.setTime(
            intake.getTime() + (+weeks + +freeWeeks) * 86400000 * 7
          ) -
            86400000 * 3
        ),
        "yyyy-MM-dd"
      );
      this.endDate = Utils.formatStrDate(endDate);
    }
  }

  changedOnIntakeDate() {
    this.selectedAdminMemoRow.memo = `Defer: intake date is changed. [ ${
      this.selectedAdmissionRow.intake
    } -> ${Utils.formatDateStr(this.intakeDate)} ]`;
  }

  onSetDefault() {
    this.selectedAdmissionRow.isSendToAdminByEmail = true;

    //console.log("this.selectedAdmissionRow.adminEp : " + this.selectedAdmissionRow.adminEp);
    if (
      this.selectedAdmissionRow.adminEp === undefined ||
      this.selectedAdmissionRow.adminEp == null
    ) {
      this.selectedAdmissionRow.adminEp = this.name;
    }
    //console.log("this.selectedAdmissionRow.adminEp : " + this.selectedAdmissionRow.adminEp);
    if (
      this.selectedAdmissionRow.adminAgent == undefined ||
      this.selectedAdmissionRow.adminAgent == null
    ) {
      this.codeService.codeCommon.adminAgentList.filter(
        (x) => x.code == "KOKOS" || x.code == "KOKOS NZ"
      );
      if (
        this.codeService.codeCommon.adminAgentList.some(
          (x) => x.code == "SOLNZ"
        )
      ) {
        this.selectedAdmissionRow.adminAgent = "SOLNZ";
      } 
      else if (
        this.codeService.codeCommon.adminAgentList.some(
          (x) => x.code == "KOKOS"
        )
      ) {
        this.selectedAdmissionRow.adminAgent = "KOKOS";
      } 
      else if (
        this.codeService.codeCommon.adminAgentList.some((x) => x.code == "Hana")
      ) {
        this.selectedAdmissionRow.adminAgent = "Hana";
      }
      
    }
    if (
      this.selectedAdmissionRow.cpSubAgent === undefined ||
      this.selectedAdmissionRow.cpSubAgent == null
    ) {
      this.selectedAdmissionRow.cpSubAgent = this.basic.ciSubAgent;
    }
  }

  goBack(): void {
    this.location.back();
  }
}
