import { DatePipe, Location } from "@angular/common";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { Profile } from "app/model/profile";
import { Popup } from "ng2-opd-popup";
import { filter, switchMap } from "rxjs/operators";
import { Basic } from "../../model/basic";
import { BasicHistoryComment } from "../../model/basic-history-comment";
import { CodeCommon } from "../../model/code-common";
import {
  InformationBoard,
  InformationBoardSearchParams,
} from "../../model/information-board";
import { Memo } from "../../model/memo";
import { AuthenticationService } from "../../services/authentication.service";
import { BasicHistoryCommentService } from "../../services/basic-history-comment.service";
import { BasicService } from "../../services/basic.service";
import { CodeService } from "../../services/code.service";
import { InformationBoardService } from "../../services/information-board.service";
import { MemoService } from "../../services/memo.service";
import { AgentService } from "../../services/agent.service"; //1003 extra email send
import { Agent } from "../../model/agent"; //1003 extra email send
import Utils from "../../shared/utils";

@Component({
  selector: "app-followup-detail",
  providers: [
    AgentService,  //1003 extra email send
    MemoService,
    BasicHistoryCommentService,
    BasicService,
    InformationBoardService,
    DatePipe,
  ],
  templateUrl: "./followup-detail.component.html",
  styles: [
    `
      .well .list-group {
        margin-bottom: 0px;
      }
      .ngx-datatable {
        height: 400px;
      }
      .line-breaker {
        white-space: pre-line;
      }
    `,
  ],
})
export class FollowupDetailComponent implements OnInit {
  @ViewChild("viewMemo") viewMemo: ElementRef;
  @ViewChild("popup1") popup1: Popup;

  popupMessage: string;

  public rows = new Array<Memo>();
  public selectedRow = new Memo();

  public basicHistoryCommentList = new Array<BasicHistoryComment>();
  keyNote: string;

  selectedBasicId: number;
  basic = new Basic();
  agent = new Agent(); //1003 extra email send
  //public agents: Agent[] = [];
  //public selectedAgent: Agent;
  selected: any[] = [];

  informationCategoryList: any[] = [];
  informationCategory2List: any[] = [];
  informationCategory3List: any[] = [];
  informationCategory4List: any[] = [];

  public InformationBoardRows = new Array<InformationBoard>();
  public searchParams = new InformationBoardSearchParams();
  selectedBoardId: string | number;

  memoTypeList: any[] = [];
  stageList: any[] = [];
  emailEpList: any[] = [];
  branchList: any[] = [];
  requestList: any[] = [
    { code: "Downgrade to Member", value: "Downgrade to Member" },
    { code: "Change EP", value: "Change EP" },
  ];
  memberTypeList: any[] = [];
  changedEpList: any[] = [];
  epChangedList: any[] = [];

  msgTo: any[] = [];
  emailTo: any[] = [];
  disabledCreateBtn: boolean = true;
  disabledFollowup: boolean = true;

  mmAlarmDate: NgbDateStruct;

  authority: string;
  name: string;
  company: string;

  onDeleteHistoryCommentStr: string;

  validStatusList: any[] = [
    { code: "신규 멤버", value: ["상담 멤버", "수속멤버", "출국준비멤버"] },
    { code: "상담 멤버", value: ["수속멤버", "출국준비멤버"] },
    { code: "수속멤버", value: ["수속종료멤버", "귀국멤버", "상담 멤버"] },
    { code: "수속종료멤버", value: ["귀국멤버", "상담 멤버"] },
    { code: "귀국멤버", value: ["상담 멤버"] },
  ];

  checkedMessageAll: boolean = false;
  checkedEmailAll: boolean = false;

  //1003 extra Email send
  selectedStudentEmail: boolean = false;  
  subAgentList: any[] = []; 
  subagentEmail: string;
  selectedSubAgentEmail: boolean = false;

  basicSubagent:string;
  Emailagentlist: any[] = [];  

  constructor(
    private memoService: MemoService,
    private authenticationService: AuthenticationService,
    private basicHistoryCommentService: BasicHistoryCommentService,
    private basicService: BasicService,
    private agentService: AgentService, //1003 extra email send
    private codeService: CodeService,
    private informationBoardService: InformationBoardService,
    private datePipe: DatePipe,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location
  ) {
    authenticationService.profile$
      .pipe(filter<Profile>(Boolean))
      .subscribe((profile) => {
        this.name = profile.name;
        this.authority = profile.authority;
        this.company = profile.company;
      });

    codeService.getCommon(this);

    //console.log("this.name : " + this.name);
  }

  setCodeList(codeCommon: CodeCommon) {
    if (codeCommon == undefined) {
      this.router.navigate(["/logout"]);
    }
    this.memoTypeList = codeCommon.memoTypeList;

    this.subAgentList =codeCommon.subAgentList; //1002

    //this.stageList          = codeCommon.stageList;
    this.emailEpList = JSON.parse(JSON.stringify(codeCommon.epList));
    this.branchList = JSON.parse(JSON.stringify(codeCommon.branchList));
    this.changedEpList = JSON.parse(JSON.stringify(codeCommon.epList)).filter(
      (x) => x.code == "Simon"
    );
    this.memberTypeList = JSON.parse(JSON.stringify(codeCommon.memberTypeList));

    this.informationCategoryList = codeCommon.informationCategoryList;

    this.setInit();

    //this.setStage();
  }

  ngOnInit() {}

  setInit() {
    this.route.params
      .pipe(
        switchMap((params: Params) => {
          this.selectedBasicId = +params["id"];
          return this.memoService.getList(+params["id"]);
        })
      )
      .subscribe(
        (data) => {
          //console.log("followup data : " + JSON.stringify(data));
          console.log("data.length : " + data.length);
          this.rows = new Array<Memo>();

          let memo = new Memo();
          memo.crmType = "click here to start";
          this.rows.push(memo);

          data.forEach((followup) => this.rows.push(followup));

          this.getEpChanged();
        },
        (error) => {
          console.log("followup error : " + JSON.stringify(error));
          if (error.status == "401") {
            this.router.navigate(["/logout"]);
          }
        }
      );



    this.basicService.get(this.selectedBasicId).subscribe(
      (basic) => {
        //console.log("basic data : " + JSON.stringify(basic));

        this.basic = basic;

        this.getEpChanged();

        this.setStage();
        //console.log("this.basic.ciEp : " + this.basic.ciEp);
        //console.log("this.name : " + this.name);
        console.log("basicService ciSubAgent : " + this.basic.ciSubAgent);
        //console.log("this.request : " + this.selectedRow.request);
        this.basicSubagent = this.basic.ciSubAgent;
        
        //1003 extra Email send

        console.log("start agentservice: " + JSON.stringify(this.basic, null, 2));
        this.agentService.getAll().subscribe((data) => {
    
          this.Emailagentlist = data.content;     
          
          const Subagent = this.Emailagentlist.find((x) => x.name === this.basicSubagent);
          
          if (Subagent) {        
            this.subagentEmail = Subagent.email;  // SubAgent의 value4 값을 설정        
          } else {
            console.log("agentService Subagent not found");
            this.subagentEmail = '';  // SubAgent가 없을 때 처리
          }
          
          console.log("Subagent : " + JSON.stringify(Subagent, null, 2));
          console.log("agentService ciSubAgent : " + this.basicSubagent);
          console.log("agentService subagentEmail : " + this.subagentEmail);
        }  ,
        (error) => {
          console.log("basic error : " + JSON.stringify(error));
        }
        );

        console.log("subagentEmail : " + this.subagentEmail);

        this.emailEpList.forEach((x) => {
          if (this.name != this.basic.ciEp && this.basic.ciEp == x.code) {
            x.checked = true;
          } else {
            x.checked = false;
          }
        });
      },
      (error) => {
        console.log("basic error : " + JSON.stringify(error));
      }
    );

    

    this.basicHistoryCommentService.getList(this.selectedBasicId).subscribe(
      (data) => {
        //console.log("basicHistoryComment data : " + JSON.stringify(data));

        this.basicHistoryCommentList = new Array<BasicHistoryComment>();
        //data.forEach(comment => this.basicHistoryCommentList.push(comment));

        data.forEach((comment) => {
          this.basicHistoryCommentList.push(comment);
        });
      },
      (error) => {
        console.log("basicHistoryComment error : " + JSON.stringify(error));
      }
    );
  }
  setStage() {
    if (
      this.codeService.codeCommon !== undefined &&
      this.codeService.codeCommon.stageList.length > 0 &&
      this.basic.id !== undefined
    ) {
      if (
        this.validStatusList.find((y) => y.code === this.basic.ciStage) !==
        undefined
      ) {
        this.stageList = this.codeService.codeCommon.stageList.filter((x) =>
          this.validStatusList
            .find((y) => y.code === this.basic.ciStage)
            .value.some((z) => z === x.code)
        );
      } else {
        this.stageList = this.codeService.codeCommon.stageList.filter(
          (x) => x.code === this.basic.ciStage
        );
      }

      this.selectedRow.ciStage = this.basic.ciStage;
    }
  }

  onSelect({ selected }) {
    //console.log('Select Event', selected);
  }

  onActivate(obj) {
    console.log('onActivate:', this.selectedRow.emailAlarm);
    if (obj.type === "click") {
      if (obj.row.id == undefined) {
        this.selectedRow = new Memo();
        this.disabledCreateBtn = false;
        this.disabledFollowup = false;
        this.mmAlarmDate = null;
        this.selected.splice(0, this.selected.length);
        this.resetChecked();

        this.selectedRow.ciStage = this.basic.ciStage;

        this.checkedMessageAll = false;
        this.checkedEmailAll = false;
      } else {
        this.selectedRow = obj.row;
        this.disabledCreateBtn = true;
        this.disabledFollowup = true;

        this.convStrToDate();

        if (
          this.selectedRow.emailAlarm != undefined &&
          this.selectedRow.emailAlarm !== ""
        ) {
          this.emailTo = this.selectedRow.emailAlarm
            .replace(" ", "")
            .split(",");
          this.emailEpList.forEach((ep) => {
            ep.checked = this.isCheckedEmail(ep.code);
          });
          this.branchList.forEach((ep) => {
            ep.checked = this.isCheckedEmail(ep.code);
          });
        } else {
          this.emailEpList.forEach((ep) => {
            ep.checked = false;
          });
          this.branchList.forEach((ep) => {
            ep.checked = false;
          });
        }
        console.log('Current emailTo list:', this.emailTo);
        setTimeout(() => {
          if (this.viewMemo != undefined) {
            //this.viewMemo.nativeElement.style.fontSize = "5";
            this.viewMemo.nativeElement.innerHTML = this.selectedRow.memo;
          }
        }, 500);
      }
    } else if (obj.type === "dblclick") {
      //this.selectedAccountingRow = obj.row;
    }
  }

  convStrToDate() {
    if (
      this.selectedRow.mmAlarmDate != null &&
      this.selectedRow.mmAlarmDate != "" &&
      this.selectedRow.mmAlarmDate.length == 10
    )
      this.mmAlarmDate = Utils.formatStrDate(this.selectedRow.mmAlarmDate);
  }

  convDateToStr() {
    if (this.mmAlarmDate != undefined)
      this.selectedRow.mmAlarmDate = Utils.formatDateStr(this.mmAlarmDate);
  }

  onClear() {
    this.selectedRow = new Memo();
    this.mmAlarmDate = null;
    this.selected.splice(0, this.selected.length);
    this.resetChecked();

    //alert(this.selectedRow.crmMethod);
  }

  get selectedEmailEp() {
    return this.emailEpList
      .filter((opt) => opt.checked)
      .map((opt) => opt.value);
  }

  get selectedBranch() {
    return this.branchList.filter((opt) => opt.checked).map((opt) => opt.value);
  }

  //1003 extra Email send
  onStudentEmailChange() {
    this.selectedStudentEmail = !this.selectedStudentEmail;
    console.log("onStudentEmailChange:"+ this.selectedStudentEmail);
  }
  onSubAgentEmailChange() {
    this.selectedSubAgentEmail = !this.selectedSubAgentEmail;
    console.log("onSubAgentEmailChange:"+this.selectedSubAgentEmail);
  }

  isCheckedMsg(code: string) {
    return this.msgTo.some((x) => x == code);
  }

  isCheckedEmail(code: string) {
    return this.emailTo.some((x) => x == code);
  }

  resetChecked() {
    this.emailEpList.forEach((x) => {
      if (this.name != this.basic.ciEp && this.basic.ciEp == x.code) {
        x.checked = true;
      } else {
        x.checked = false;
      }
    });

    this.branchList.forEach((ep) => {
      ep.checked = false;
    });
  }

  onValidateMemo() {
    this.popupMessage = "";

    if (
      this.selectedRow.crmMethod === undefined ||
      this.selectedRow.crmMethod === ""
    ) {
      this.popupMessage = "Contact Method is required";
    } else if (
      this.selectedRow.memo === undefined ||
      this.selectedRow.memo === ""
    ) {
      this.popupMessage = "Memo is required";
    }

    //    if(this.selectedRow.request == undefined && this.selectedRow.ciStage == this.basic.ciStage &&
    //        this.selectedRow.mmAlarmDate == undefined) {
    //      this.popupMessage = "Next CRM F/U Date is required";
    //    }

    if (this.popupMessage !== "") {
      this.popup1.options = {
        header: "Error",
        color: "red",
        widthProsentage: 40,
        animationDuration: 0,
        showButtons: true,
        confirmBtnContent: "OK",
        cancleBtnContent: "Cancel",
        confirmBtnClass: "btn btn-default",
        cancleBtnClass: "one-button-hidden",
      };

      this.popup1.show(this.popup1.options);
      return false;
    }

    return true;
  }

  onCreate() {
    this.convDateToStr();

    if (!this.onValidateMemo()) return;

    this.selectedRow.basicId = this.selectedBasicId;
    this.selectedRow.emailAlarm = this.selectedEmailEp.toString();
    if (
      this.selectedEmailEp != undefined &&
      this.selectedEmailEp.length > 0 &&
      this.selectedBranch != undefined &&
      this.selectedBranch.length > 0
    ) {
      this.selectedRow.emailAlarm = this.selectedRow.emailAlarm + ",";
    }
    this.selectedRow.emailAlarm =
      this.selectedRow.emailAlarm + this.selectedBranch.toString();

    
    //1003 extra Email send  
    if (this.selectedStudentEmail && this.basic.mdEMail) {
      if (this.selectedRow.emailAlarm) {
        this.selectedRow.emailAlarm = this.selectedRow.emailAlarm + ',';
      }
      this.selectedRow.emailAlarm = this.selectedRow.emailAlarm + this.basic.mdEMail;
    }

    if (this.selectedSubAgentEmail && this.subagentEmail) {
      if (this.selectedRow.emailAlarm) {
        this.selectedRow.emailAlarm = this.selectedRow.emailAlarm + ',';
      }
      this.selectedRow.emailAlarm = this.selectedRow.emailAlarm + this.subagentEmail;
    }

    console.log('selectedRow:', this.selectedRow);
    //return;

    this.memoService.create(this.selectedRow).subscribe(
      (data) => {
        console.log("create successful");
        //staticModal.show();
        //alert("update is successful");
        this.popupMessage = "Succeed in creating";

        this.popup1.options = {
          header: "Information",
          color: "#5cb85c",
          widthProsentage: 20,
          animationDuration: 0,
          showButtons: true,
          confirmBtnContent: "OK",
          cancleBtnContent: "Cancel",
          confirmBtnClass: "btn btn-default",
          cancleBtnClass: "one-button-hidden",
        };

        this.popup1.show(this.popup1.options);

        this.disabledCreateBtn = true;
        this.disabledFollowup = true;
      },
      (error) => {
        console.log(error);
        this.popupMessage = "Fail to create";

        this.popup1.options = {
          header: "Error",
          color: "red",
          widthProsentage: 20,
          animationDuration: 0,
          showButtons: true,
          confirmBtnContent: "OK",
          cancleBtnContent: "Cancel",
          confirmBtnClass: "btn btn-default",
          cancleBtnClass: "one-button-hidden",
        };

        this.popup1.show(this.popup1.options);
      }
    );
  }

  onDelete() {
    this.memoService.delete(this.selectedRow).subscribe(
      (data) => {
        console.log("delete successful");
        this.popupMessage = "Succeed in deleting";

        this.popup1.options = {
          header: "Information",
          color: "#5cb85c",
          widthProsentage: 20,
          animationDuration: 0,
          showButtons: true,
          confirmBtnContent: "OK",
          cancleBtnContent: "Cancel",
          confirmBtnClass: "btn btn-default",
          cancleBtnClass: "one-button-hidden",
        };

        this.popup1.show(this.popup1.options);

        this.disabledCreateBtn = true;
        this.disabledFollowup = true;
      },
      (error) => {
        console.log(error);
        this.popupMessage = "Fail to delete";

        this.popup1.options = {
          header: "Error",
          color: "red",
          widthProsentage: 20,
          animationDuration: 0,
          showButtons: true,
          confirmBtnContent: "OK",
          cancleBtnContent: "Cancel",
          confirmBtnClass: "btn btn-default",
          cancleBtnClass: "one-button-hidden",
        };

        this.popup1.show(this.popup1.options);
      }
    );
  }

  clickOKButton() {
    this.popup1.hide();
    if (
      this.popupMessage == "Succeed in creating" ||
      this.popupMessage == "Succeed in deleting"
    ) {
      this.onClear();
      this.find();
    } else if (
      this.popupMessage === "Would you like to create this basic memo?"
    ) {
      this.onCreate();
    } else if (
      this.popupMessage === "Would you like to delete this basic memo?"
    ) {
      this.onDelete();
    } else if (
      this.popupMessage === "Would you like to create this basic key memo?"
    ) {
      this.onCreateKey();
    } else if (
      this.popupMessage === "Would you like to delete selected basic key memo?"
    ) {
      this.onDeleteHistoryComment(this.onDeleteHistoryCommentStr);
    }
  }

  find() {
    this.memoService.getList(this.selectedBasicId).subscribe(
      (data) => {
        this.rows = new Array<Memo>();

        let memo = new Memo();
        memo.crmType = "click here to start";
        this.rows.push(memo);

        data.forEach((followup) => this.rows.push(followup));

        this.getEpChanged();
      },
      (error) => {
        console.log("find followup error : " + JSON.stringify(error));
      }
    );

    this.basicService.get(this.selectedBasicId).subscribe(
      (basic) => {
        this.basic = basic;

        this.getEpChanged();

        this.setStage();
      },
      (error) => {
        console.log("find basic error : " + JSON.stringify(error));
      }
    );

    this.onSelectHistoryComment();
  }

  onSelectHistoryComment() {
    this.basicHistoryCommentService.getList(this.selectedBasicId).subscribe(
      (data) => {
        this.basicHistoryCommentList = new Array<BasicHistoryComment>();

        data.forEach((comment) => {
          this.basicHistoryCommentList.push(comment);
        });
      },
      (error) => {
        console.log(
          "find basicHistoryComment error : " + JSON.stringify(error)
        );
      }
    );
  }

  onCreateKeyMsg() {
    this.popupMessage = "Would you like to create this basic key memo?";

    this.popup1.options = {
      header: "Information",
      color: "#5cb85c",
      widthProsentage: 50,
      animationDuration: 0,
      showButtons: true,
      confirmBtnContent: "OK",
      cancleBtnContent: "Cancel",
      confirmBtnClass: "btn btn-default",
      cancleBtnClass: "btn btn-default",
    };

    this.popup1.show(this.popup1.options);
  }

  onCreateMsg() {
    this.popupMessage = "Would you like to create this basic memo?";

    this.popup1.options = {
      header: "Information",
      color: "#5cb85c",
      widthProsentage: 50,
      animationDuration: 0,
      showButtons: true,
      confirmBtnContent: "OK",
      cancleBtnContent: "Cancel",
      confirmBtnClass: "btn btn-default",
      cancleBtnClass: "btn btn-default",
    };

    this.popup1.show(this.popup1.options);
  }

  onDeleteMsg() {
    this.popupMessage = "Would you like to delete this basic memo?";

    this.popup1.options = {
      header: "Information",
      color: "#5cb85c",
      widthProsentage: 50,
      animationDuration: 0,
      showButtons: true,
      confirmBtnContent: "OK",
      cancleBtnContent: "Cancel",
      confirmBtnClass: "btn btn-default",
      cancleBtnClass: "btn btn-default",
    };

    this.popup1.show(this.popup1.options);
  }

  onCreateKey() {
    if (this.keyNote == undefined) return;

    let basicHistoryComment = new BasicHistoryComment();

    basicHistoryComment.basicId = this.selectedBasicId;
    basicHistoryComment.commentBody = this.keyNote;

    this.basicHistoryCommentService.create(basicHistoryComment).subscribe(
      (data) => {
        console.log("create successful");
        //staticModal.show();
        //alert("update is successful");
        this.popupMessage = "Succeed in creating";

        this.popup1.options = {
          header: "Information",
          color: "#5cb85c",
          widthProsentage: 20,
          animationDuration: 0,
          showButtons: true,
          confirmBtnContent: "OK",
          cancleBtnContent: "Cancel",
          confirmBtnClass: "btn btn-default",
          cancleBtnClass: "one-button-hidden",
        };

        this.popup1.show(this.popup1.options);

        this.keyNote = "";
        this.onSelectHistoryComment();
      },
      (error) => {
        console.log(error);
        this.popupMessage = "Fail to create";

        this.popup1.options = {
          header: "Error",
          color: "red",
          widthProsentage: 20,
          animationDuration: 0,
          showButtons: true,
          confirmBtnContent: "OK",
          cancleBtnContent: "Cancel",
          confirmBtnClass: "btn btn-default",
          cancleBtnClass: "one-button-hidden",
        };

        this.popup1.show(this.popup1.options);
      }
    );
  }

  onDeleteHistoryCommentMsg(id) {
    this.onDeleteHistoryCommentStr = id;

    this.popupMessage = "Would you like to delete selected basic key memo?";

    this.popup1.options = {
      header: "Information",
      color: "#5cb85c",
      widthProsentage: 50,
      animationDuration: 0,
      showButtons: true,
      confirmBtnContent: "OK",
      cancleBtnContent: "Cancel",
      confirmBtnClass: "btn btn-default",
      cancleBtnClass: "btn btn-default",
    };

    this.popup1.show(this.popup1.options);
  }

  onDeleteHistoryComment(id) {
    let comment = new BasicHistoryComment();
    comment.id = id;
    this.basicHistoryCommentService.delete(comment).subscribe(
      (data) => {
        this.onSelectHistoryComment();
      },
      (error) => {
        console.log("onDeleteHistoryComment error : " + JSON.stringify(error));
      }
    );
  }
  getEpChanged() {
    if (this.rows.length > 1 && this.basic.createDate !== undefined) {
      this.epChangedList = [];
      this.rows.forEach((memo) => {
        if (memo.crmType !== undefined && memo.crmType != null) {
          if (memo.crmType.indexOf("Change EP") > -1) {
            this.epChangedList.push(
              memo.createDate + "   EP : " + memo.crmType.substring(13)
            );
          } else if (memo.crmType.indexOf("Change M") > -1) {
            this.epChangedList.push(
              memo.createDate + "   MC : " + memo.crmType.substring(21)
            );
          }
        }
      });
      if (this.rows.length > 1) {
        this.epChangedList.push(
          this.rows[this.rows.length - 2].createDate +
            "   EP : " +
            this.rows[this.rows.length - 2].ep +
            " [initial]"
        );
      }
    }

    if (
      this.basic.initialMemberCategoryName != undefined &&
      this.basic.initialMemberCategoryName != null
    )
      this.epChangedList.push(
        this.basic.createDate +
          "   MC : " +
          this.basic.initialMemberCategoryName +
          " [initial]"
      );
  }

  onSelectedMenu(menu) {
    this.router.navigate(["/" + menu, this.selectedBasicId]);
  }

  goBack(): void {
    this.location.back();
  }

  onEmailAll() {
    if (this.selectedRow.id == undefined) {
      this.emailEpList.forEach((x) => {
        if (this.name != this.basic.ciEp && this.basic.ciEp == x.code) {
          x.checked = true;
        } else {
          x.checked = this.checkedEmailAll;
        }
      });

      this.branchList.forEach((x) => {
        x.checked = this.checkedEmailAll;
      });
    }
  }

  onChange(ckeditor) {}

  onReady(ckeditor) {}

  onFocus(ckeditor) {
    //console.log("onFocus : ");
  }

  onBlur(ckeditor) {
    //console.log("onBlur : ");
  }


  onChangeContactMethod() {
    if (this.selectedRow.crmMethod == "Email Template") {
      this.searchParams.boardCategory = "Email Template";
      this.onChangeBoardCategory();
    }
  }

  onChangeRequest() {    
    this.emailEpList.forEach((x) => {      
      if (x.code == "Simon" && this.selectedRow.request != "Downgrade to Member" && this.selectedRow.request != "Change EP" ) {
        x.checked = false; console.log("Simon Unchecked : "+this.selectedRow.request);
      } else if (x.code == "Simon" && this.selectedRow.request != undefined) {
        x.checked = true; console.log("Simon checked : "+this.selectedRow.request);
      } else {        
      }
    });
  }

  onChangeBoardCategory() {
    if (this.searchParams.boardCategory == "") {
      this.informationCategory2List = [];
    } else {
      this.informationCategory2List = this.informationCategoryList.find(
        (x) => x.code === this.searchParams.boardCategory
      ).children;
    }

    this.searchParams.boardCategoryMiddle = "";
    this.searchParams.boardCategoryThird = "";
    this.searchParams.boardCategoryFourth = "";

    this.selectedRow.subject = "";
    this.selectedRow.memo = "";

    this.searchInformationBoard();
  }

  onChangeBoardCategoryMiddle() {
    if (this.searchParams.boardCategoryMiddle == "") {
      this.informationCategory3List = [];
    } else {
      this.informationCategory3List = this.informationCategory2List.find(
        (x) => x.code === this.searchParams.boardCategoryMiddle
      ).children;
    }

    this.searchParams.boardCategoryThird = "";
    this.searchParams.boardCategoryFourth = "";

    this.selectedRow.subject = "";
    this.selectedRow.memo = "";

    this.searchInformationBoard();
  }

  onChangeBoardCategoryThird() {
    if (this.searchParams.boardCategoryThird == "") {
      this.informationCategory4List = [];
    } else {
      this.informationCategory4List = this.informationCategory3List.find(
        (x) => x.code === this.searchParams.boardCategoryThird
      ).children;
    }

    this.searchParams.boardCategoryFourth = "";

    this.selectedRow.subject = "";
    this.selectedRow.memo = "";

    this.searchInformationBoard();
  }

  onChangeBoardCategoryFourth() {
    this.searchInformationBoard();
  }

  onChangeBoard() {
    if (this.selectedBoardId != undefined && this.selectedBoardId != 0) {
      this.selectedRow.subject = this.InformationBoardRows.find(
        (x) => x.id == this.selectedBoardId
      ).boardHead;
      this.selectedRow.memo = this.InformationBoardRows.find(
        (x) => x.id == this.selectedBoardId
      ).boardBody;
    } else {
      this.selectedRow.subject = "";
      this.selectedRow.memo = "";
    }
  }


  searchInformationBoard() {
    this.InformationBoardRows = new Array<InformationBoard>();

    this.informationBoardService
      .searchInformationBoard(this.searchParams, { pageNumber: 0, size: 10000 })
      .subscribe(
        (data) => {
          //console.log("data : " + JSON.stringify(data));

          this.InformationBoardRows = new Array<InformationBoard>();
          data.content.forEach((board) =>
            this.InformationBoardRows.push(board)
          );
          this.selectedBoardId = "";
        },
        (error) => {
          console.log("error : ", error);
          if (error.status == "401") {
            this.router.navigate(["/logout"]);
          }
        }
      );
  }
}
