import { DatePipe, Location } from "@angular/common";
import { Component, OnInit, ViewChild, ViewContainerRef } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import {
  IMultiSelectOption,
  IMultiSelectSettings,
  IMultiSelectTexts,
} from "angular-2-dropdown-multiselect";
import { FlashMessagesService } from "angular2-flash-messages";
import { Profile } from "app/model/profile";
import * as _ from "lodash";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { Popup } from "ng2-opd-popup";
import { ModalDirective } from "ngx-bootstrap/modal";
import { filter, switchMap } from "rxjs/operators";
import { Accounting } from "../../model/accounting";
import { AccountingMemo } from "../../model/accounting-memo";
import { Basic } from "../../model/basic";
import { CodeCommon } from "../../model/code-common";
import { AccountingMemoService } from "../../services/accounting-memo.service";
import { AccountingService } from "../../services/accounting.service";
import { AuthenticationService } from "../../services/authentication.service";
import { BasicService } from "../../services/basic.service";
import { CodeService } from "../../services/code.service";
import Utils from "../../shared/utils";

@Component({
  selector: "app-accounting-detail",
  providers: [AccountingService, AccountingMemoService, BasicService, DatePipe],
  styles: [
    `
      br {
        display: block;
        margin-top: 5px;
        line-height: 12px;
      }
    `,
  ],
  templateUrl: "./accounting-detail.component.html",
})
export class AccountingDetailComponent implements OnInit {
  myOptions1: IMultiSelectOption[] = [
    { id: "Tuition Fee DC", name: "Tuition Fee DC" },
    { id: "Immigration Advisor Fee", name: "Immigration Advisor Fee" },
    { id: "Airport Pickup", name: "Airport Pickup" },
    { id: "Airport Drop", name: "Airport Drop" },
    { id: "Refund", name: "Refund" },
    { id: "Others", name: "Others" },
  ];

  myOptions2: IMultiSelectOption[] = [
    { id: "Admin Service (Refundable)", name: "Admin Service (Refundable)" },
    { id: "Admin Service (No-Retund)", name: "Admin Service (No-Retund)" },
    { id: "Visa Service Fee", name: "Visa Service Fee" },
    {
      id: "Bonus Commission from School",
      name: "Bonus Commission from School",
    },
    { id: "Consulting Service (Edu)", name: "Consulting Service (Edu)" },
    { id: "Consulting Service (Visa)", name: "Consulting Service (Visa)" },
    { id: "School Viewing/Tour Service", name: "School Viewing/Tour Service" },
    { id: "Airport Pick Up Service", name: "Airport Pick Up Service" },
    { id: "Airport Drop Service", name: "Airport Drop Service" },
    { id: "Settlement Service", name: "Settlement Service" },
    { id: "Guardianship Service", name: "Guardianship Service" },
    { id: "Other Service", name: "Other Service" },
  ];

  optionsModel1: any[] = [];
  optionsModel2: any[] = [];

  /*

    otherDiscountNoteList: any[] = [
    { code: 'Tuition Fee DC', value: 'Tuition Fee DC' },
    { code: 'Immigration Advisor Fee', value: 'Immigration Advisor Fee' },
    { code: 'Airport Pickup', value: 'Airport Pickup' },
    { code: 'Airport Drop', value: 'Airport Drop' },
    { code: 'Refund', value: 'Refund' },
    { code: 'Others', value: 'Others' },
  ];

  otherServiceFeeNoteList: any[] = [
    { code: 'Admin Service (Refundable)', value: 'Admin Service (Refundable)' },
    { code: 'Admin Service (No-Retund)', value: 'Admin Service (No-Retund)' },
    { code: 'Visa Service Fee', value: 'Visa Service Fee' },
    { code: 'Bonus Commission from School', value: 'Bonus Commission from School' },
    { code: 'Consulting Service (Edu)', value: 'Consulting Service (Edu)' },
    { code: 'Consulting Service (Visa)', value: 'Consulting Service (Visa)' },
    { code: 'School Viewing/Tour Service', value: 'School Viewing/Tour Service' },
    { code: 'Airport Pick Up Service', value: 'Airport Pick Up Service' },
    { code: 'Airport Drop Service', value: 'Airport Drop Service' },
    { code: 'Settlement Service', value: 'Settlement Service' },
    { code: 'Guardianship Service', value: 'Guardianship Service' },
    { code: 'Other Service', value: 'Other Service' },
  ];


  */

  //Settings configuration
  mySettings: IMultiSelectSettings = {
    showCheckAll: true,
    showUncheckAll: true,
    dynamicTitleMaxItems: 4,
    displayAllSelectedText: false,
    buttonClasses: "btn btn-secondary",
  };

  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: "id",
    textField: "name",
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    itemsShowLimit: 1,
    allowSearchFilter: false,
  };

  //Text configuration
  myTexts: IMultiSelectTexts = {
    checkAll: "Select all",
    uncheckAll: "Unselect all",
    checked: "option selected",
    checkedPlural: "options selected",
    searchPlaceholder: "Find",
    searchEmptyResult: "Nothing found...",
    searchNoRenderText: "Type in search box to see results...",
    defaultTitle: "Selected option",
    allSelected: "All selected",
  };

  @ViewChild("popup1") popup1: Popup;
  popupMessage: string;

  public rows = new Array<Accounting>();
  public selectedRow = new Accounting();

  public accountingMemoRows = new Array<AccountingMemo>();
  public selectedAccountingMemoRow = new AccountingMemo();
  @ViewChild("accountingMemoModal") public accountingMemoModal: ModalDirective;

  selectedAdminId: number;
  selected: any[] = [];
  selectedInvoices: any[] = [];
  showInvoice: boolean;

  selectedBasicId: number;
  basic = new Basic();

  isCreate: boolean = false;

  schoolTypeList: any[] = [];
  schoolNameList: any[] = [];
  courseList: any[] = [];

  courseListForVisa: any[] = [
    { code: "Student Visa", value: "Student Visa" },
    { code: "Visitor Visa - Guardian", value: "Visitor Visa - Guardian" },
    { code: "Visitor Visa - Partnership", value: "Visitor Visa - Partnership" },
    { code: "Visitor Visa - ", value: "Visitor Visa - " },
    { code: "Work Visa - Graduate Visa", value: "Work Visa - Graduate Visa" },
    {
      code: "Work Visa - Essential Skill",
      value: "Work Visa - Essential Skill",
    },
    { code: "Work Visa - Partnership", value: "Work Visa - Partnership" },
    { code: "Section 61", value: "Section 61" },
  ];

  courseListForInsurance: any[] = [
    {
      code: "Done By School(Paid to KOKOS)",
      value: "Done By School(Paid to KOKOS)",
    },
    { code: "Student Lite(Orbit)", value: "Student Lite(Orbit)" },
    { code: "Student Prime(Orbit)", value: "Student Prime(Orbit)" },
    { code: "Done By Subagent", value: "Done By Subagent" },
    { code: "Family Lite(Orbit)", value: "Family Lite(Orbit)" },
    { code: "Individual Prime(Orbit)", value: "Individual Prime(Orbit)" },
    { code: "Individual Lite(Orbit)", value: "Individual Lite(Orbit)" },
    { code: "Working Holiday(Orbit)", value: "Working Holiday(Orbit)" },
  ];

  courseListForOtherServices: any[] = [
    { code: "Admin Service (Refundable)", value: "Admin Service (Refundable)" },
    { code: "Admin Service (No-Retund)", value: "Admin Service (No-Retund)" },
    { code: "Visa Service Fee", value: "Visa Service Fee" },
    { code: "Consulting Service (Edu)", value: "Consulting Service (Edu)" },
    { code: "Consulting Service (Visa)", value: "Consulting Service (Visa)" },
    {
      code: "School Viewing/Tour Service",
      value: "School Viewing/Tour Service",
    },
    { code: "Airport Pick Up Service", value: "Airport Pick Up Service" },
    { code: "Airport Drop Service", value: "Airport Drop Service" },
    { code: "Settlement Service", value: "Settlement Service" },
    { code: "Guardianship Service", value: "Guardianship Service" },
    { code: "Other Service", value: "Other Service" },
    {
      code: "Bonus Commission from School",
      value: "Bonus Commission from School",
    },
  ];

  epList: any[] = [];
  subAgentList: any[] = [];

  memoTypeList: any[] = [];
  accountingNoteList: any[] = [];
  messageEpList: any[] = [];
  emailEpList: any[] = [];
  msgTo: any[] = [];
  emailTo: any[] = [];

  commissionRateList: any[] = [
    { code: "5", value: "5%" },
    { code: "10", value: "10%" },
    { code: "12", value: "12%" },
    { code: "12.5", value: "12.5%" },
    { code: "13", value: "13%" },
    { code: "15", value: "15%" },
    { code: "20", value: "20%" },
    { code: "25", value: "25%" },
    { code: "30", value: "30%" },
    { code: "35", value: "35%" },
    { code: "40", value: "40%" },
    { code: "", value: "Manual" },
  ];

  subCommissionRateList: any[] = [
    { code: "5", value: "5%" },
    { code: "5.5", value: "5.5%" },
    { code: "6", value: "6%" },
    { code: "6.5", value: "6.5%" },
    { code: "7", value: "7%" },
    { code: "8", value: "8%" },
    { code: "8.5", value: "8.5%" },
    { code: "9", value: "9%" },
    { code: "", value: "Manual" },
  ];

  visaPaidToKokosDepositTypeList: any[] = [
    { code: "ANZ KOKOS", value: "ANZ KOKOS" },
    { code: "Korea Bank", value: "Korea Bank" },
    { code: "Cash", value: "Cash" },
  ];

  insPaidToKokosDepositTypeList: any[] = [
    { code: "ANZ KOKOS", value: "ANZ KOKOS" },
    { code: "Korea Bank", value: "Korea Bank" },
    { code: "Cash", value: "Cash" },
  ];

  osPaidToKokosDepositTypeList: any[] = [
    { code: "ANZ KOKOS", value: "ANZ KOKOS" },
    { code: "Korea Bank", value: "Korea Bank" },
    { code: "Cash", value: "Cash" },
  ];

  visaApplicationFeeNoteList: any[] = [
    { code: "Not Decided", value: "Not Decided" },
    { code: "Done By Student", value: "Done By Student" },
    { code: "Done By Agent(Advisor)", value: "Done By Agent(Advisor)" },
    { code: "Student Visa", value: "Student Visa" },
    { code: "Visitor Visa - Guardian", value: "Visitor Visa - Guardian" },
    { code: "Visitor Visa - Partnership", value: "Visitor Visa - Partnership" },
    { code: "Visitor Visa - ", value: "Visitor Visa - " },
    { code: "Work Visa - Graduate Visa", value: "Work Visa - Graduate Visa" },
    {
      code: "Work Visa - Essential Skill",
      value: "Work Visa - Essential Skill",
    },
    { code: "Work Visa - Partnership", value: "Work Visa - Partnership" },
    { code: "Section 61", value: "Section 61" },
  ];

  defaultNoteList: any[] = [
    { code: "Paid to School", value: "Paid to School" },
    { code: "Paid to KOKOS", value: "Paid to KOKOS" },
    { code: "Paid to Subagent", value: "Paid to Subagent" },
  ];

  insuranceNoteList: any[] = [
    { code: "Not Decided", value: "Not Decided" },
    { code: "Paid to School", value: "Paid to School" },
    {
      code: "Done By School(Paid to KOKOS)",
      value: "Done By School(Paid to KOKOS)",
    },
    { code: "Student Lite(Orbit)", value: "Student Lite(Orbit)" },
    { code: "Student Prime(Orbit)", value: "Student Prime(Orbit)" },
    { code: "Done By Student", value: "Done By Student" },
    { code: "Done By Subagent", value: "Done By Subagent" },
    { code: "Family Lite(Orbit)", value: "Family Lite(Orbit)" },
    { code: "Individual Prime(Orbit)", value: "Individual Prime(Orbit)" },
    { code: "Individual Lite(Orbit)", value: "Individual Lite(Orbit)" },
    { code: "Working Holiday(Orbit)", value: "Working Holiday(Orbit)" },
  ];

  otherDiscountNoteList: any[] = [
    { code: "Tuition Fee DC", value: "Tuition Fee DC" },
    { code: "Immigration Advisor Fee", value: "Immigration Advisor Fee" },
    { code: "Airport Pickup", value: "Airport Pickup" },
    { code: "Airport Drop", value: "Airport Drop" },
    { code: "Refund", value: "Refund" },
    { code: "Others", value: "Others" },
  ];

  otherServiceFeeNoteList: any[] = [
    { code: "Admin Service (Refundable)", value: "Admin Service (Refundable)" },
    { code: "Admin Service (No-Retund)", value: "Admin Service (No-Retund)" },
    { code: "Visa Service Fee", value: "Visa Service Fee" },
    {
      code: "Bonus Commission from School",
      value: "Bonus Commission from School",
    },
    { code: "Consulting Service (Edu)", value: "Consulting Service (Edu)" },
    { code: "Consulting Service (Visa)", value: "Consulting Service (Visa)" },
    {
      code: "School Viewing/Tour Service",
      value: "School Viewing/Tour Service",
    },
    { code: "Airport Pick Up Service", value: "Airport Pick Up Service" },
    { code: "Airport Drop Service", value: "Airport Drop Service" },
    { code: "Settlement Service", value: "Settlement Service" },
    { code: "Guardianship Service", value: "Guardianship Service" },
    { code: "Other Service", value: "Other Service" },
  ];

  visaCreditPurchaseDate: NgbDateStruct;
  visaStartDate: NgbDateStruct;
  visaEndDate: NgbDateStruct;
  visaPaidDate: NgbDateStruct;

  insPurchaseDate: NgbDateStruct;
  insStartDate: NgbDateStruct;
  insEndDate: NgbDateStruct;
  insPaidDate: NgbDateStruct;
  osStartDate: NgbDateStruct;
  osEndDate: NgbDateStruct;
  osAppliedDate: NgbDateStruct;
  osPaidDate: NgbDateStruct;

  minDate: NgbDateStruct = { year: 1900, month: 1, day: 1 };

  //for course setting
  secondary: any[] = [
    "Year 1",
    "YR 10",
    "YR 11",
    "YR 12",
    "YR 13",
    "YR 2",
    "YR 3",
    "YR 4",
    "YR 5",
    "YR 6",
    "YR 7",
    "YR 8",
    "YR 9",
  ];
  auSecondary: any[] = [
    "Year 1",
    "YR 10",
    "YR 11",
    "YR 12",
    "YR 13",
    "YR 2",
    "YR 3",
    "YR 4",
    "YR 5",
    "YR 6",
    "YR 7",
    "YR 8",
    "YR 9",
    "GE",
  ];
  elicos: any[] = ["GE", "1", "2", "TECSOL", "CFZ", "BST"];
  pteUni: any[] = [
    "7",
    "5",
    "3",
    "Graduate Diploma",
    "Master",
    "DT",
    "GCZ",
    "PDP",
    "PCT",
    "6",
    "GE",
  ];

  intake: NgbDateStruct;
  appliedDate: NgbDateStruct;
  //startingDate : NgbDateStruct;
  endDate: NgbDateStruct;
  paidDate: NgbDateStruct;
  subPaidDate: NgbDateStruct;

  gstInc: boolean = false;
  gstExc: boolean = false;

  authority: string;
  name: string;
  company: string;

  disabledInvoice: boolean = true;
  inoviceCnt: number = 0;

  disabledPaidDate: boolean = true;
  disabledSubPaidDate: boolean = true;
  disabledAccount: boolean = true;
  disabledCommission: boolean = true;
  disabledRealCommission: boolean = true;
  disabledSubCommission: boolean = true;
  disabledRealSubCommission: boolean = true;

  isReturn: boolean = false;

  disabledReturn: boolean = false;
  disabledSave: boolean = false;
  disabledHolding: boolean = false;
  disabledPending: boolean = false;
  disabledInvoiced: boolean = false;
  disabledCompleted: boolean = false;
  disabledCanDef: boolean = false;
  disabledCancelled: boolean = false;

  canDivide: boolean = false;
  canDelete: boolean = false;

  tuitionGross: string;
  schoolRegistrationBank: string;
  schoolMaterialBank: string;
  schoolExamBank: string;
  sInsuranceBank: any;
  sVisaBank: any;
  sOthersBank: string;

  etcInsuranceBank: any;
  etcVisaBank: any;
  etcPickupBank: string;
  otherExBank: string;
  etcServiceBank: string;

  commissionBank: string;
  subCom: string;
  receivedCom: string;
  subReal: string;

  iaeTg: string;
  dc: string;
  tTn: string;
  asfTotal: string;
  profit: string;
  onUpdateStatusStr: string;

  disabledRequest: boolean = true;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private accountingService: AccountingService,
    private basicService: BasicService,
    private codeService: CodeService,
    private accountingMemoService: AccountingMemoService,
    private authenticationService: AuthenticationService,
    private datePipe: DatePipe,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private _flashMessagesService: FlashMessagesService
  ) {
    authenticationService.profile$
      .pipe(filter<Profile>(Boolean))
      .subscribe((profile) => {
        this.name = profile.name;
        this.authority = profile.authority;
        this.company = profile.company;
      });

    codeService.getCommon(this);
  }

  setCodeList(codeCommon: CodeCommon) {
    if (codeCommon == undefined) {
      this.router.navigate(["/logout"]);
    }
    this.schoolTypeList = codeCommon.schoolTypeList;
    //this.schoolNameList   = codeCommon.schoolNameList;
    //this.courseList       = codeCommon.courseList;
    this.courseList = codeCommon.courseList;
    this.epList = JSON.parse(JSON.stringify(codeCommon.epList));
    //this.subAgentList     = codeCommon.subAgentList;
    this.subAgentList = codeCommon.subAgentList.filter((x) => x.code != "self");
    this.emailEpList = JSON.parse(JSON.stringify(codeCommon.epList));
    this.accountingNoteList = codeCommon.accountingNoteList;

    this.init();
  }

  ngOnInit() {}

  init() {
    var accId = null;

    this.route.params
      .pipe(
        switchMap((params: Params) => {
          this.selectedBasicId = +params["id"];
          accId = +params["accid"];
          return this.basicService.get(this.selectedBasicId);
        })
      )
      .pipe(
        switchMap((basic) => {
          this.basic = basic;
          return this.accountingService.getList(this.selectedBasicId);
        })
      )
      .subscribe(
        (data) => {
          this.rows = new Array<Accounting>();
          data.forEach((accounting) => {
            this.rows.push(accounting);

            if (accounting != null && accounting.commissionRate != null) {
              var itHas: boolean = false;
              var closest: number = 100;
              var index: number = 0;

              for (
                var temp = 0;
                temp < this.commissionRateList.length;
                temp++
              ) {
                if (
                  this.commissionRateList[temp].code ==
                  accounting.commissionRate
                ) {
                  itHas = true;
                  break;
                }

                if (
                  Math.abs(
                    this.commissionRateList[temp].code -
                      accounting.commissionRate
                  ) < closest
                ) {
                  closest = Math.abs(
                    this.commissionRateList[temp].code -
                      accounting.commissionRate
                  );
                  index = temp;
                }
              }

              if (itHas == false) {
                if (
                  accounting.commissionRate == 0 ||
                  accounting.commissionRate == 0.0
                ) {
                  this.commissionRateList.splice(0, 0, {
                    code: accounting.commissionRate,
                    value: accounting.commissionRate + "%",
                  });
                } else {
                  this.commissionRateList.splice(index + 1, 0, {
                    code: accounting.commissionRate,
                    value: accounting.commissionRate + "%",
                  });
                }
              }
            }

            if (this.selected.length == 0) {
              this.selected = [accounting];
              this.setValue(accounting);
              this.checkInvoice();
              this.onSelected(accounting);
            }
          });

          if (accId != null) {
            let accounting = this.rows.find((x) => x.id === accId);
            this.selected = [accounting];
            this.onSelected(accounting);
          }
        },
        (error) => {
          console.log("error : ", error);
          if (error.status == "401") {
            this.router.navigate(["/logout"]);
          }
        }
      );
  }

  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);

    this.checkInvoice();
  }

  checkInvoice() {
    this.inoviceCnt = 0;
    //console.log('this.selected', this.selected);
    this.selected.forEach((x) => {
      if (x.accountStatus == "Pending") {
        this.inoviceCnt = this.inoviceCnt + 1;
      }
    });

    if (this.inoviceCnt > 0 && this.inoviceCnt == this.selected.length) {
      this.disabledInvoice = false;
    } else {
      this.disabledInvoice = true;
    }
  }

  onActivate(obj) {
    if (obj.type === "click") {
      this.setValue(obj.row);

      this.onSelected(obj.row);

      //console.log(JSON.stringify(obj.row));
    } else if (obj.type === "dblclick") {
      //console.log('Activate Event', obj);
      //console.log('Type', obj.type);
      //console.log('Id', obj.row.id);
      //this.selectedAccountingRow = obj.row;
    }
  }

  setValue(selected) {
    //if(!this.commissionRateList["code"].includes(this.selectedRow.commissionRate))
    //{
    //this.commissionRateList.push({code: this.selectedRow.commissionRate, value: this.selectedRow.commissionRate + "%"});
    //}

    this.isCreate = false;

    if (selected.id !== undefined) {
      this.selectedRow = JSON.parse(JSON.stringify(selected));

      //console.log(this.selectedRow.school);
      //console.log(this.selectedRow.admissionSchoolType);

      if (this.selectedRow.school == "- Visa Only") {
        this.selectedRow.visaApplicationFeeNote =
          this.selectedRow.baseCourse1 + ""; //Visa fixed (School Type)
        this.selectedRow.otherServiceFeeNote = "Visa Service Fee"; //Visa Service Fee auto-selected
        this.selectedRow.subCommissionRate = ""; //Manual
      }

      if (this.selectedRow.school == "- Insurance Only") {
        this.selectedRow.insuranceNote = this.selectedRow.baseCourse1 + ""; //Insurance fixed (School Type)
      }

      if (this.selectedRow.school == "- Other Services Only") {
        this.selectedRow.otherServiceFeeNote =
          this.selectedRow.baseCourse1 + ""; //Other service fixed (School Type)
      }

      this.optionsModel1 = this.selectedRow.otherDiscountNote
        ? this.selectedRow.otherDiscountNote.split(",")
        : [];
      this.optionsModel2 = this.selectedRow.otherServiceFeeNote
        ? this.selectedRow.otherServiceFeeNote.split(",")
        : [];

      if (
        this.selectedRow.visaPaidCreditCard == null ||
        this.selectedRow.visaPaidCreditCard == ""
      ) {
        this.selectedRow.visaPaidCreditCard = "**********9243";
      }

      if (
        this.selectedRow.visaPaidToKokosDepositType == null ||
        this.selectedRow.visaPaidToKokosDepositType == ""
      ) {
        this.selectedRow.visaPaidToKokosDepositType = "ANZ KOKOS";
      }

      if (
        this.selectedRow.insPaidToKokosDepositType == null ||
        this.selectedRow.insPaidToKokosDepositType == ""
      ) {
        this.selectedRow.insPaidToKokosDepositType = "ANZ KOKOS";
      }

      if (
        this.selectedRow.osPaidToKokosDepositType == null ||
        this.selectedRow.osPaidToKokosDepositType == ""
      ) {
        this.selectedRow.osPaidToKokosDepositType = "ANZ KOKOS";
      }

      if (
        this.selectedRow.visaApplicantName1 == null ||
        this.selectedRow.visaApplicantName1 == ""
      ) {
        this.selectedRow.visaApplicantName1 =
          this.basic.mdNameOrg +
          " (" +
          this.basic.mdNameEng +
          " " +
          this.basic.mdNameEngFamily +
          ")";
      }

      if (
        this.selectedRow.insName1 == null ||
        this.selectedRow.insName1 == ""
      ) {
        this.selectedRow.insName1 =
          this.basic.mdNameOrg +
          " (" +
          this.basic.mdNameEng +
          " " +
          this.basic.mdNameEngFamily +
          ")";
      }
    } else {
      this.selectedRow = new Accounting();
    }

    //console.log("JSON.stringify(selected) : " + JSON.stringify(selected));

    if (this.authority == "accountant" || this.authority == "manager") {
      if (this.selectedRow.id === undefined) {
        this.canDivide = false;
        this.canDelete = false;
      } else if (
        _.includes(
          ["Holding", "Estimated", "Pending"],
          this.selectedRow.accountStatus
        )
      ) {
        this.canDivide = true;
        this.canDelete = true;
      } else if (
        this.selectedRow.divide == "1" &&
        this.selectedRow.parentId == 0
      ) {
        if (this.selectedRow.tuitionGross > 0) {
          this.canDivide = true;
          this.canDelete = false;
        } else {
          this.canDivide = false;
          this.canDelete = false;
        }
      } else if (
        this.selectedRow.divide > "1" &&
        this.selectedRow.superNumber == 1
      ) {
        this.canDivide = false;
        this.canDelete = false;
      } else if (+this.selectedRow.divide == this.selectedRow.superNumber) {
        this.canDivide = true;
        this.canDelete = true;
      } else {
        this.canDivide = false;
        this.canDelete = false;
      }
    } else {
      this.canDivide = false;
      this.canDelete = false;
    }

    this.onChangeSchoolType();
    this.convStrToDate();
    this.calculateInvoice();

    if (this.selectedRow.tuitionGross == 0) this.tuitionGross = null;
    else this.tuitionGross = Utils.addComma(this.selectedRow.tuitionGross);

    if (this.selectedRow.schoolRegistrationBank == 0)
      this.schoolRegistrationBank = null;
    else
      this.schoolRegistrationBank = Utils.addComma(
        this.selectedRow.schoolRegistrationBank
      );

    if (this.selectedRow.schoolMaterialBank == 0)
      this.schoolMaterialBank = null;
    else
      this.schoolMaterialBank = Utils.addComma(
        this.selectedRow.schoolMaterialBank
      );

    if (this.selectedRow.schoolExamBank == 0) this.schoolExamBank = null;
    else this.schoolExamBank = Utils.addComma(this.selectedRow.schoolExamBank);

    if (this.selectedRow.sInsuranceBank == 0) this.sInsuranceBank = null;
    else this.sInsuranceBank = Utils.addComma(this.selectedRow.sInsuranceBank);

    if (this.selectedRow.sVisaBank == 0) this.sVisaBank = null;
    else this.sVisaBank = Utils.addComma(this.selectedRow.sVisaBank);

    if (this.selectedRow.sOthersBank == 0) this.sOthersBank = null;
    else this.sOthersBank = Utils.addComma(this.selectedRow.sOthersBank);

    if (this.selectedRow.etcInsuranceBank == 0) this.etcInsuranceBank = null;
    else
      this.etcInsuranceBank = Utils.addComma(this.selectedRow.etcInsuranceBank);

    if (this.selectedRow.etcVisaBank == 0) this.etcVisaBank = null;
    else this.etcVisaBank = Utils.addComma(this.selectedRow.etcVisaBank);

    if (this.selectedRow.etcPickupBank == 0) this.etcPickupBank = null;
    else this.etcPickupBank = Utils.addComma(this.selectedRow.etcPickupBank);

    if (this.selectedRow.otherExBank == 0) this.otherExBank = null;
    else this.otherExBank = Utils.addComma(this.selectedRow.otherExBank);

    if (this.selectedRow.etcServiceBank == 0) this.etcServiceBank = null;
    else this.etcServiceBank = Utils.addComma(this.selectedRow.etcServiceBank);

    if (this.selectedRow.commissionBank == 0) this.commissionBank = null;
    else this.commissionBank = Utils.addComma(this.selectedRow.commissionBank);

    if (this.selectedRow.subCom == 0) this.subCom = null;
    else this.subCom = Utils.addComma(this.selectedRow.subCom);

    if (this.selectedRow.receivedCom == 0) this.receivedCom = null;
    else this.receivedCom = Utils.addComma(this.selectedRow.receivedCom);

    if (this.selectedRow.subReal == 0) this.subReal = null;
    else this.subReal = Utils.addComma(this.selectedRow.subReal);

    if (
      this.selectedRow.cpSubAgent === undefined ||
      this.selectedRow.cpSubAgent == "" ||
      this.selectedRow.cpSubAgent == null
    ) {
    }

    if (
      this.selectedRow.paidDate === undefined ||
      this.selectedRow.paidDate == "" ||
      this.selectedRow.paidDate == null
    ) {
      this.disabledPaidDate = false;
    } else {
      if (this.authority == "manager") {
        this.disabledPaidDate = false;
      } else {
        this.disabledPaidDate = true;
      }
    }

    if (
      this.selectedRow.subPaidDate === undefined ||
      this.selectedRow.subPaidDate == "" ||
      this.selectedRow.subPaidDate == null
    ) {
      this.disabledSubPaidDate = false;
    } else {
      if (this.authority == "manager") {
        this.disabledSubPaidDate = false;
      } else {
        this.disabledSubPaidDate = true;
      }
    }

    if (this.selectedRow.accountStatus == "UnApplied") {
      this.disabledSave = false;
      this.disabledHolding = true;
      this.disabledPending = true;
      this.disabledInvoiced = true;
      this.disabledCompleted = true;

      this.disabledAccount = false;
      this.disabledCommission = false;
      this.disabledSubCommission = false;
    } else if (this.selectedRow.accountStatus == "Enrolled") {
      this.disabledSave = false;
      this.disabledHolding = false;
      this.disabledPending = true;
      this.disabledInvoiced = true;
      this.disabledCompleted = true;

      this.disabledAccount = false;
      this.disabledCommission = false;
      this.disabledSubCommission = false;
    } else if (this.selectedRow.accountStatus == "Holding") {
      //Holding

      this.disabledSave = false;
      this.disabledHolding = true;
      this.disabledPending = false;
      this.disabledInvoiced = true;
      this.disabledCompleted = true;

      this.disabledAccount = false;
      this.disabledCommission = false;
      this.disabledSubCommission = false;
    } else if (this.selectedRow.accountStatus == "Estimated") {
      //Pending

      this.disabledHolding = true;
      this.disabledPending = true;
      this.disabledCompleted = true;

      if (this.authority == "accountant" || this.authority == "manager") {
        this.disabledSave = false;
        this.disabledInvoiced = false;

        this.disabledAccount = false;
        this.disabledCommission = false;
        this.disabledSubCommission = false;
      } else {
        this.disabledSave = true;
        this.disabledInvoiced = true;

        this.disabledAccount = true;
        this.disabledCommission = true;
        this.disabledSubCommission = true;
      }
    } else if (this.selectedRow.accountStatus == "Pending") {
      //Invoiced

      this.disabledHolding = true;
      this.disabledPending = true;
      this.disabledInvoiced = true;

      this.disabledAccount = true;
      this.disabledCommission = true;
      this.disabledSubCommission = true;

      if (this.authority == "accountant") {
        this.disabledSave = false;
        this.disabledCompleted = false;
      } else {
        this.disabledSave = true;
        this.disabledCompleted = true;
      }
    } else if (this.selectedRow.accountStatus == "OnGoing") {
      //SubUnpaid

      this.disabledSave = true;
      this.disabledHolding = true;
      this.disabledPending = true;
      this.disabledInvoiced = true;
      this.disabledCompleted = false;

      this.disabledAccount = true;
      this.disabledCommission = true;
      this.disabledSubCommission = true;
    } else if (this.selectedRow.accountStatus == "CR") {
      //Can/Ref

      this.disabledSave = true;
      this.disabledHolding = true;
      this.disabledPending = true;
      this.disabledInvoiced = true;
      this.disabledCompleted = true;

      this.disabledAccount = true;
      this.disabledCommission = true;
      this.disabledSubCommission = true;
    } else if (this.selectedRow.accountStatus == "Cancelled") {
      //Cancelled

      this.disabledSave = true;
      this.disabledHolding = true;
      this.disabledPending = true;
      this.disabledInvoiced = true;
      this.disabledCompleted = true;

      if (this.authority == "accountant" || this.authority == "manager") {
        this.disabledSave = false;

        this.disabledAccount = false;
        this.disabledCommission = false;
        this.disabledSubCommission = false;
      } else {
        this.disabledSave = true;

        this.disabledAccount = true;
        this.disabledCommission = true;
        this.disabledSubCommission = true;
      }
    } else {
      this.disabledSave = true;
      this.disabledHolding = true;
      this.disabledPending = true;
      this.disabledInvoiced = true;
      this.disabledCompleted = true;

      this.disabledAccount = true;
      this.disabledCommission = true;
      this.disabledSubCommission = true;
    }

    // there is exception for divided row
    if (this.selectedRow.divide != "1") {
      this.disabledAccount = true;
      this.disabledCommission = true;
      this.disabledSubCommission = true;
    }

    if (this.authority == "accountant" || this.authority == "manager") {
      this.disabledSave = false;

      if (
        this.selectedRow.divide == "1" &&
        this.selectedRow.superId == 1 &&
        !(
          this.selectedRow.accountStatus === "Pending" ||
          this.selectedRow.accountStatus === "OnGoing" ||
          this.selectedRow.accountStatus === "Received"
        )
      ) {
        this.disabledCanDef = false;
      } else {
        this.disabledCanDef = true;
      }

      if (
        !(
          this.selectedRow.accountStatus === "Pending" ||
          this.selectedRow.accountStatus === "OnGoing" ||
          this.selectedRow.accountStatus === "Received"
        )
      ) {
        this.disabledCancelled = false;
      } else {
        this.disabledCancelled = true;
      }

      this.disabledRealCommission = false;
      this.disabledRealSubCommission = false;

      if (this.authority == "manager") {
        this.disabledHolding = false;
        this.disabledPending = false;
        this.disabledInvoiced = false;
        this.disabledCompleted = false;
      }
    } else {
      this.disabledCanDef = true;
      this.disabledCancelled = true;
      this.disabledRealCommission = true;
      this.disabledRealSubCommission = true;
    }
  }

  onChangeSchoolType() {
    this.schoolNameList = this.codeService.codeCommon.schoolNameList.filter(
      (code) => code.value3 === this.selectedRow.admissionSchoolType
    );

    if (this.selectedRow.admissionSchoolType === "Secondary")
      this.courseList = this.codeService.codeCommon.courseList.filter((code) =>
        this.secondary.some((x) => x === code.code)
      );
    else if (this.selectedRow.admissionSchoolType === "AU Secondary")
      this.courseList = this.codeService.codeCommon.courseList.filter((code) =>
        this.auSecondary.some((x) => x === code.code)
      );
    else if (this.selectedRow.admissionSchoolType === "ELICOS")
      this.courseList = this.codeService.codeCommon.courseList.filter((code) =>
        this.elicos.some((x) => x === code.code)
      );
    else if (
      ["NZ PTE", "NZ Uni/Poly", "AU Uni", "AU PTE", "AU PTE"].some(
        (tp) => tp === this.selectedRow.admissionSchoolType
      )
    )
      this.courseList = this.codeService.codeCommon.courseList.filter((code) =>
        this.pteUni.some((x) => x === code.code)
      );
    else this.courseList = this.codeService.codeCommon.courseList;

    if (
      this.selectedRow.admissionSchoolType == "- Insurance Only" ||
      this.selectedRow.admissionSchoolType == "- Visa Only" ||
      this.selectedRow.admissionSchoolType == "- Other Services Only"
    ) {
      this.selectedRow.school = this.selectedRow.admissionSchoolType;
    }

    this.calculateEndDate();
  }

  onSelectGst() {
    this.gstInc = false;
    this.gstExc = false;

    //console.log("this.selectedRow.school : " + this.selectedRow.school);

    if (this.selectedRow.school !== undefined) {
      this.selectedRow.auNzCheck = this.schoolNameList.find(
        (x) => x.code == this.selectedRow.school
      ).value2;

      //console.log("this.selectedRow.auNzCheck : " + this.selectedRow.auNzCheck);
      if (this.selectedRow.auNzCheck == "AUS") {
        this.gstInc = false;
        this.gstExc = true;
      } else {
        //console.log("this.selectedRow.cpSubAgent : " + this.selectedRow.cpSubAgent);
        if (
          this.selectedRow.cpSubAgent === undefined ||
          this.selectedRow.cpSubAgent == "" ||
          this.selectedRow.cpSubAgent == null
        ) {
          this.gstInc = false;
          this.gstExc = false;
          //this.selectedRow.subCom = 0.0;
        } else {
          if (
            this.subAgentList.find(
              (x) => x.code == this.selectedRow.cpSubAgent
            ) !== undefined
          ) {
            let val = this.subAgentList.find(
              (x) => x.code == this.selectedRow.cpSubAgent
            ).value6;

            //console.log("val : " + val);
            if (val !== "G") {
              this.gstInc = true;
              this.gstExc = false;

              this.selectedRow.gst = (this.selectedRow.subCom * 3) / 23;

              //console.log("this.selectedRow.gst : " + this.selectedRow.gst);
            }
          }
        }
      }
    }
  }

  onChangeCourse() {
    if (this.selectedRow.admissionSchoolType == "- Insurance Only") {
      this.selectedRow.tuitionGrossNote = null;
      this.selectedRow.registrationNote = null;
      this.selectedRow.materialNote = null;
      this.selectedRow.examFeeNote = null;
      this.selectedRow.insuranceNote = null;
      this.selectedRow.visaApplicationFeeNote = null;
      this.selectedRow.othersNote = null;

      this.selectedRow.insuranceNote = this.selectedRow.baseCourse1 + "";
    } else if (this.selectedRow.admissionSchoolType == "- Visa Only") {
      this.selectedRow.tuitionGrossNote = null;
      this.selectedRow.registrationNote = null;
      this.selectedRow.materialNote = null;
      this.selectedRow.examFeeNote = null;
      this.selectedRow.insuranceNote = null;
      this.selectedRow.visaApplicationFeeNote = null;
      this.selectedRow.othersNote = null;

      this.selectedRow.visaApplicationFeeNote =
        this.selectedRow.baseCourse1 + "";
    } else if (
      this.selectedRow.admissionSchoolType == "- Other Services Only"
    ) {
      this.selectedRow.tuitionGrossNote = null;
      this.selectedRow.registrationNote = null;
      this.selectedRow.materialNote = null;
      this.selectedRow.examFeeNote = null;
      this.selectedRow.insuranceNote = null;
      this.selectedRow.visaApplicationFeeNote = null;
      this.selectedRow.othersNote = null;

      this.selectedRow.otherServiceFeeNote = this.selectedRow.baseCourse1 + "";
    }
  }

  convStrToDate() {
    if (
      this.selectedRow.intake != undefined &&
      this.selectedRow.intake.length == 10
    )
      this.intake = Utils.formatStrDate(this.selectedRow.intake);
    else this.intake = undefined;
    if (
      this.selectedRow.appliedDate != undefined &&
      this.selectedRow.appliedDate.length == 10
    )
      this.appliedDate = Utils.formatStrDate(this.selectedRow.appliedDate);
    else if (
      this.selectedRow.appliedDate === undefined ||
      this.selectedRow.appliedDate == null
    ) {
      this.appliedDate = Utils.formatStrDate("today");
    } else {
      this.appliedDate = undefined;
    }
    //if(this.selectedRow.startingDate != undefined && this.selectedRow.startingDate.length  == 10)
    //     this.startingDate  = Utils.formatStrDate(this.selectedRow.startingDate);
    //else this.startingDate  = null;
    if (
      this.selectedRow.endDate != undefined &&
      this.selectedRow.endDate.length == 10
    )
      this.endDate = Utils.formatStrDate(this.selectedRow.endDate);
    else this.endDate = undefined;
    if (
      this.selectedRow.paidDate != undefined &&
      this.selectedRow.paidDate.length == 10
    )
      this.paidDate = Utils.formatStrDate(this.selectedRow.paidDate);
    else this.paidDate = undefined;
    if (
      this.selectedRow.subPaidDate != undefined &&
      this.selectedRow.subPaidDate.length == 10
    )
      this.subPaidDate = Utils.formatStrDate(this.selectedRow.subPaidDate);
    else this.subPaidDate = undefined;

    if (
      this.selectedRow.visaCreditPurchaseDate != undefined &&
      this.selectedRow.visaCreditPurchaseDate.length == 10
    )
      this.visaCreditPurchaseDate = Utils.formatStrDate(
        this.selectedRow.visaCreditPurchaseDate
      );
    else this.visaCreditPurchaseDate = undefined;

    if (
      this.selectedRow.visaStartDate != undefined &&
      this.selectedRow.visaStartDate.length == 10
    )
      this.visaStartDate = Utils.formatStrDate(this.selectedRow.visaStartDate);
    else this.visaStartDate = undefined;

    if (
      this.selectedRow.visaEndDate != undefined &&
      this.selectedRow.visaEndDate.length == 10
    )
      this.visaEndDate = Utils.formatStrDate(this.selectedRow.visaEndDate);
    else this.visaEndDate = undefined;

    if (
      this.selectedRow.visaPaidDate != undefined &&
      this.selectedRow.visaPaidDate.length == 10
    )
      this.visaPaidDate = Utils.formatStrDate(this.selectedRow.visaPaidDate);
    else this.visaPaidDate = undefined;

    if (
      this.selectedRow.insPurchaseDate != undefined &&
      this.selectedRow.insPurchaseDate.length == 10
    )
      this.insPurchaseDate = Utils.formatStrDate(
        this.selectedRow.insPurchaseDate
      );
    else this.insPurchaseDate = undefined;

    if (
      this.selectedRow.insStartDate != undefined &&
      this.selectedRow.insStartDate.length == 10
    )
      this.insStartDate = Utils.formatStrDate(this.selectedRow.insStartDate);
    else this.insStartDate = undefined;

    if (
      this.selectedRow.insEndDate != undefined &&
      this.selectedRow.insEndDate.length == 10
    )
      this.insEndDate = Utils.formatStrDate(this.selectedRow.insEndDate);
    else this.insEndDate = undefined;

    if (
      this.selectedRow.insPaidDate != undefined &&
      this.selectedRow.insPaidDate.length == 10
    )
      this.insPaidDate = Utils.formatStrDate(this.selectedRow.insPaidDate);
    else this.insPaidDate = undefined;

    if (
      this.selectedRow.osStartDate != undefined &&
      this.selectedRow.osStartDate.length == 10
    )
      this.osStartDate = Utils.formatStrDate(this.selectedRow.osStartDate);
    else this.osStartDate = undefined;

    if (
      this.selectedRow.osEndDate != undefined &&
      this.selectedRow.osEndDate.length == 10
    )
      this.osEndDate = Utils.formatStrDate(this.selectedRow.osEndDate);
    else this.osEndDate = undefined;

    if (
      this.selectedRow.osAppliedDate != undefined &&
      this.selectedRow.osAppliedDate.length == 10
    )
      this.osAppliedDate = Utils.formatStrDate(this.selectedRow.osAppliedDate);
    else this.osAppliedDate = undefined;

    if (
      this.selectedRow.osPaidDate != undefined &&
      this.selectedRow.osPaidDate.length == 10
    )
      this.osPaidDate = Utils.formatStrDate(this.selectedRow.osPaidDate);
    else this.osPaidDate = undefined;
  }

  convDateToStr() {
    if (this.intake != undefined)
      this.selectedRow.intake = Utils.formatDateStr(this.intake);
    else this.selectedRow.intake = undefined;
    if (this.appliedDate != undefined)
      this.selectedRow.appliedDate = Utils.formatDateStr(this.appliedDate);
    else this.selectedRow.appliedDate = undefined;
    //if(this.startingDate   != undefined)
    //     this.selectedRow.startingDate  = Utils.formatDateStr(this.startingDate);
    //else this.selectedRow.startingDate  = null;
    if (this.endDate != undefined)
      this.selectedRow.endDate = Utils.formatDateStr(this.endDate);
    else this.selectedRow.endDate = undefined;
    if (this.paidDate != undefined)
      this.selectedRow.paidDate = Utils.formatDateStr(this.paidDate);
    else this.selectedRow.paidDate = undefined;
    if (this.subPaidDate != undefined)
      this.selectedRow.subPaidDate = Utils.formatDateStr(this.subPaidDate);
    else this.selectedRow.subPaidDate = undefined;

    if (this.visaCreditPurchaseDate != undefined)
      this.selectedRow.visaCreditPurchaseDate = Utils.formatDateStr(
        this.visaCreditPurchaseDate
      );
    else this.selectedRow.visaCreditPurchaseDate = undefined;

    if (this.visaStartDate != undefined)
      this.selectedRow.visaStartDate = Utils.formatDateStr(this.visaStartDate);
    else this.selectedRow.visaStartDate = undefined;

    if (this.visaEndDate != undefined)
      this.selectedRow.visaEndDate = Utils.formatDateStr(this.visaEndDate);
    else this.selectedRow.visaEndDate = undefined;

    if (this.visaPaidDate != undefined)
      this.selectedRow.visaPaidDate = Utils.formatDateStr(this.visaPaidDate);
    else this.selectedRow.visaPaidDate = undefined;

    if (this.insPurchaseDate != undefined)
      this.selectedRow.insPurchaseDate = Utils.formatDateStr(
        this.insPurchaseDate
      );
    else this.selectedRow.insPurchaseDate = undefined;

    if (this.insStartDate != undefined)
      this.selectedRow.insStartDate = Utils.formatDateStr(this.insStartDate);
    else this.selectedRow.insStartDate = undefined;

    if (this.insEndDate != undefined)
      this.selectedRow.insEndDate = Utils.formatDateStr(this.insEndDate);
    else this.selectedRow.insEndDate = undefined;

    if (this.insPaidDate != undefined)
      this.selectedRow.insPaidDate = Utils.formatDateStr(this.insPaidDate);
    else this.selectedRow.insPaidDate = undefined;

    if (this.insPaidDate != undefined)
      this.selectedRow.insPaidDate = Utils.formatDateStr(this.insPaidDate);
    else this.selectedRow.insPaidDate = undefined;

    if (this.osStartDate != undefined)
      this.selectedRow.osStartDate = Utils.formatDateStr(this.osStartDate);
    else this.selectedRow.osStartDate = undefined;

    if (this.osEndDate != undefined)
      this.selectedRow.osEndDate = Utils.formatDateStr(this.osEndDate);
    else this.selectedRow.osEndDate = undefined;

    if (this.osAppliedDate != undefined)
      this.selectedRow.osAppliedDate = Utils.formatDateStr(this.osAppliedDate);
    else this.selectedRow.osAppliedDate = undefined;

    if (this.osPaidDate != undefined)
      this.selectedRow.osPaidDate = Utils.formatDateStr(this.osPaidDate);
    else this.selectedRow.osPaidDate = undefined;
  }

  removeComma(value) {
    if (value == undefined) return value;

    return +value.replace(",", "");
  }

  onTuitioNGrossNoteChange(value) {
    this.selectedRow.registrationNote = value;
    this.selectedRow.materialNote = value;
    this.selectedRow.examFeeNote = value;
    this.selectedRow.othersNote = value;
  }

  onCalculateInvoice(field, $event) {
    if (field == "tuitionGross") {
      this.selectedRow.tuitionGross = this.removeComma(this.tuitionGross);
    } else if (field == "schoolRegistrationBank") {
      this.selectedRow.schoolRegistrationBank = this.removeComma(
        this.schoolRegistrationBank
      );
    } else if (field == "schoolMaterialBank") {
      this.selectedRow.schoolMaterialBank = this.removeComma(
        this.schoolMaterialBank
      );
    } else if (field == "schoolExamBank") {
      this.selectedRow.schoolExamBank = this.removeComma(this.schoolExamBank);
    } else if (field == "sInsuranceBank") {
      this.selectedRow.sInsuranceBank = this.removeComma(this.sInsuranceBank);
    } else if (field == "sVisaBank") {
      this.selectedRow.sVisaBank = this.removeComma(this.sVisaBank);
    } else if (field == "sOthersBank") {
      this.selectedRow.sOthersBank = this.removeComma(this.sOthersBank);
    } else if (field == "etcInsuranceBank") {
      this.selectedRow.etcInsuranceBank = this.removeComma(
        this.etcInsuranceBank
      );
    } else if (field == "etcVisaBank") {
      this.selectedRow.etcVisaBank = this.removeComma(this.etcVisaBank);
    } else if (field == "etcPickupBank") {
      this.selectedRow.etcPickupBank = this.removeComma(this.etcPickupBank);
    } else if (field == "otherExBank") {
      this.selectedRow.otherExBank = this.removeComma(this.otherExBank);
    } else if (field == "etcServiceBank") {
      this.selectedRow.etcServiceBank = this.removeComma(this.etcServiceBank);
    } else if (field == "commissionBank") {
      this.selectedRow.commissionBank = this.removeComma(this.commissionBank);
    } else if (field == "subCom") {
      this.selectedRow.subCom = this.removeComma(this.subCom);
    } else if (field == "receivedCom") {
      this.selectedRow.receivedCom = this.removeComma(this.receivedCom);
    } else if (field == "subReal") {
      this.selectedRow.subReal = this.removeComma(this.subReal);
    }

    this.calculateInvoice();
  }

  calculateInvoice() {
    //this.selectedRow.tuitionGross = +(this.selectedRow.tuitionGross).toLocaleString();

    //console.log("this.selectedRow.tuitionGross : " + this.selectedRow.tuitionGross);

    //School Total
    this.selectedRow.iaeTg =
      +this.selectedRow.tuitionGross +
      +this.selectedRow.schoolRegistrationBank +
      +this.selectedRow.schoolMaterialBank +
      +this.selectedRow.schoolExamBank +
      +this.selectedRow.sInsuranceBank +
      +this.selectedRow.sVisaBank +
      +this.selectedRow.sOthersBank;
    this.iaeTg = Utils.addComma(this.selectedRow.iaeTg);

    //Discount + Extra Discount
    this.selectedRow.dc =
      +this.selectedRow.etcInsuranceBank +
      +this.selectedRow.etcVisaBank +
      +this.selectedRow.etcPickupBank +
      +this.selectedRow.otherExBank;
    this.dc = Utils.addComma(this.selectedRow.dc);

    //Received Total
    this.selectedRow.tTn =
      +this.selectedRow.iaeTg -
      +this.selectedRow.dc +
      +this.selectedRow.etcServiceBank;
    this.tTn = Utils.addComma(this.selectedRow.tTn);

    if (
      this.selectedRow.commissionRate != "" &&
      this.selectedRow.commissionRate != null
    ) {
      this.selectedRow.commissionBank =
        (+this.selectedRow.tuitionGross * +this.selectedRow.commissionRate) /
        100;
      this.commissionBank = Utils.addComma(this.selectedRow.commissionBank);
    }

    //console.log(" profit : " + (+this.selectedRow.commissionBank - +this.selectedRow.dc + +this.selectedRow.etcServiceBank));
    this.asfTotal = Utils.addComma(
      +this.selectedRow.commissionBank -
        +this.selectedRow.dc +
        +this.selectedRow.etcServiceBank
    );
    //console.log(" asfTotal : " + this.asfTotal);

    if (
      this.selectedRow.subCommissionRate != "" &&
      this.selectedRow.subCommissionRate != null
    ) {
      this.selectedRow.subCom =
        (+this.selectedRow.tuitionGross * +this.selectedRow.subCommissionRate) /
        100;
      this.subCom = Utils.addComma(this.selectedRow.subCom);
    }

    if (this.selectedRow.subCom != undefined && this.selectedRow.subCom > 0.0) {
      this.asfTotal = Utils.addComma(
        +this.removeComma(this.asfTotal) - +this.selectedRow.subCom
      );
    }

    //console.log(" asfTotal : " + this.asfTotal);

    if (
      this.selectedRow.receivedCom !== undefined &&
      this.selectedRow.receivedCom != 0
    ) {
      this.selectedRow.profit =
        +this.selectedRow.receivedCom -
        +this.selectedRow.dc +
        +this.selectedRow.etcServiceBank;

      if (
        this.selectedRow.subReal != undefined &&
        this.selectedRow.subReal > 0.0
      ) {
        this.selectedRow.profit =
          +this.selectedRow.profit - +this.selectedRow.subReal;
      }
    } else {
      this.selectedRow.profit = this.removeComma(this.asfTotal);
    }

    this.profit = Utils.addComma(this.selectedRow.profit);

    // better idea

    //this.onSelectGst();
  }

  onCalculateManager($event) {}

  get checkDisplay() {
    if (this.selectedRow.admissionSchoolType === "ELICOS") {
      return 1;
    } else if (
      this.selectedRow.admissionSchoolType === "Secondary" ||
      this.selectedRow.admissionSchoolType === "NZ Uni/Poly" ||
      this.selectedRow.admissionSchoolType === "AU Uni" ||
      this.selectedRow.admissionSchoolType === "AU Secondary"
    ) {
      return 2;
    } else {
      return 3;
    }
  }

  onClear() {
    this.selected.splice(0, this.selected.length);

    this.isCreate = true;
    this.selectedRow = new Accounting();
    this.intake = undefined;
    this.appliedDate = undefined;
    //this.startingDate = null;
    this.endDate = undefined;
    this.paidDate = undefined;
    this.subPaidDate = undefined;
  }

  onValidateAdmission() {
    this.popupMessage = "";

    if (
      this.selectedRow.admissionSchoolType === undefined ||
      this.selectedRow.admissionSchoolType === ""
    ) {
      this.popupMessage = "School Type is required";
    } else if (
      this.selectedRow.school === undefined ||
      this.selectedRow.school === ""
    ) {
      this.popupMessage = "School Name is required";
    } else if (
      this.selectedRow.baseCourse1 === undefined ||
      this.selectedRow.baseCourse1 === ""
    ) {
      if (this.selectedRow.school == "- Insurance Only") {
        this.popupMessage = "Insurance Type (Course) is required";
      } else if (this.selectedRow.school == "- Visa Only") {
        this.popupMessage = "Visa Type (Course) is required";
      } else if (this.selectedRow.school == "- Other Services Only") {
        this.popupMessage = "Service Type (Course) is required";
      } else {
        this.popupMessage = "Course is required";
      }

      //} else if(this.selectedRow.adminEp          === undefined || this.selectedRow.adminEp      === '') {
      //  this.popupMessage = "Admin EP is required";
    }

    if (this.selectedRow.school == "- Insurance Only") {
      if (
        this.selectedRow.insPolicyNumber == undefined ||
        this.selectedRow.insPolicyNumber == ""
      ) {
        this.popupMessage = "Insurance Tab -> Policy No is required";
      } else if (
        this.selectedRow.insStartDate == undefined ||
        this.selectedRow.insStartDate == ""
      ) {
        this.popupMessage = "Insurance Tab -> Start Date is required";
      } else if (
        this.selectedRow.insEndDate == undefined ||
        this.selectedRow.insEndDate == ""
      ) {
        this.popupMessage = "Insurance Tab -> End Date is required";
      } else if (
        this.selectedRow.insPurchaseDate == undefined ||
        this.selectedRow.insPurchaseDate == ""
      ) {
        this.popupMessage = "Insurance Tab -> Purchase Date is required";
      }
    }

    if (this.selectedRow.school == "- Visa Only") {
      //if(this.selectedRow.visaCreditPurchaseDate == undefined || this.selectedRow.visaCreditPurchaseDate == '') {
      //  this.popupMessage = "Visa Tab -> Purchase Date is required";
      //}
    }

    if (this.selectedRow.school == "- Other Services Only") {
      //if(this.selectedRow.osStartDate == undefined || this.selectedRow.osStartDate == '') {
      //  this.popupMessage = "Other Services Tab -> Start Date is required";
      //}
    }

    //    else if (this.selectedRow.school != "- Insurance Only" && this.selectedRow.school != "- Other Services Only" && this.selectedRow.school != "- Visa Only" &&

    if (this.popupMessage !== "") {
      this.popup1.options = {
        header: "Error",
        color: "red",
        widthProsentage: 40,
        animationDuration: 0,
        showButtons: true,
        confirmBtnContent: "OK",
        cancleBtnContent: "Cancel",
        confirmBtnClass: "btn btn-default",
        cancleBtnClass: "one-button-hidden",
      };

      this.popup1.show(this.popup1.options);
      return false;
    }

    return true;
  }

  //if you want to create new Admission, you can use this function
  onCreate() {
    this.convDateToStr();

    if (!this.onValidateAdmission()) return;

    this.selectedRow.basicId = this.selectedBasicId;

    //console.log("this.selectedRow.basicId : " + this.selectedRow.basicId);
    this.accountingService.create(this.selectedRow).subscribe(
      (data) => {
        this.popupMessage = "Succeed in creating";

        this.popup1.options = {
          header: "Information",
          color: "#5cb85c",
          widthProsentage: 40,
          animationDuration: 0,
          showButtons: true,
          confirmBtnContent: "OK",
          cancleBtnContent: "Cancel",
          confirmBtnClass: "btn btn-default",
          cancleBtnClass: "one-button-hidden",
        };

        this.popup1.show(this.popup1.options);

        this.selectedAdminId = data.id;
      },
      (error) => {
        console.log(error);
        this.popupMessage = "Fail to create";

        this.popup1.options = {
          header: "Error",
          color: "red",
          widthProsentage: 40,
          animationDuration: 0,
          showButtons: true,
          confirmBtnContent: "OK",
          cancleBtnContent: "Cancel",
          confirmBtnClass: "btn btn-default",
          cancleBtnClass: "one-button-hidden",
        };

        this.popup1.show(this.popup1.options);
      }
    );
  }

  onValidateAccounting() {
    this.popupMessage = "";

    if (
      this.selectedRow.admissionSchoolType != "Others" &&
      this.selectedRow.admissionSchoolType != "Service" &&
      this.selectedRow.admissionSchoolType != "Immigration"
    ) {
      //if (this.selectedRow.tuitionGross == undefined || this.selectedRow.tuitionGross <= 0.0) {
      //  this.popupMessage = "Please input tuition gross.";
      //}
      /*
      else if (this.selectedRow.profit < 0.0) {
        this.popupMessage = "Profit is minus. Please check the commission and discount.";
      }
      else if (this.selectedRow.etcInsuranceBank > 0.0 && (this.selectedRow.sInsuranceBank <= 0.0 || this.selectedRow.etcInsuranceBank > this.selectedRow.sInsuranceBank)) {
        this.popupMessage = "Please check your input amount of school insurance and discount insurance.";
      }
      else if (this.selectedRow.etcVisaBank > 0.0 && (this.selectedRow.sVisaBank <= 0.0 || this.selectedRow.etcVisaBank > this.selectedRow.sVisaBank)) {
        this.popupMessage = "Please check your input amount of school visa and discount visa.";
        //} else if(this.selectedRow.adminEp          === undefined || this.selectedRow.adminEp      === '') {
        //  this.popupMessage = "Admin EP is required";      
      }

      */
    }

    if (this.subCom == "" && this.receivedCom != "" && this.subReal == "") {
      this.subReal = "0";
    }

    if (this.popupMessage !== "") {
      this.popup1.options = {
        header: "Error",
        color: "red",
        widthProsentage: 40,
        animationDuration: 0,
        showButtons: true,
        confirmBtnContent: "OK",
        cancleBtnContent: "Cancel",
        confirmBtnClass: "btn btn-default",
        cancleBtnClass: "one-button-hidden",
      };

      this.popup1.show(this.popup1.options);
      return false;
    }

    return true;
  }

  onUpdate() {
    /*
    this.selectedRow.otherDiscountNote =
      _.size(this.selectedRow.otherDiscountNote) > 0
        ? _.map(this.selectedRow.otherDiscountNote, (r) => r).join(", ")
        : "";

    this.selectedRow.otherServiceFeeNote =
      _.size(this.selectedRow.otherServiceFeeNote) > 0
        ? _.map(this.selectedRow.otherServiceFeeNote, (r) => r).join(", ")
        : "";
    */

    this.convDateToStr();

    if (!this.onValidateAccounting()) return;

    if (this.selectedRow.accountStatus == "UnApplied") {
      this.selectedRow.accountStatus = "Holding";
    }

    this.selectedAdminId = this.selectedRow.id;
    this.accountingService.update(this.selectedRow).subscribe(
      (data) => {
        this.popupMessage = "Succeed in updating";

        this.popup1.options = {
          header: "Information",
          color: "#5cb85c",
          widthProsentage: 40,
          animationDuration: 0,
          showButtons: true,
          confirmBtnContent: "OK",
          cancleBtnContent: "Cancel",
          confirmBtnClass: "btn btn-default",
          cancleBtnClass: "one-button-hidden",
        };

        this.popup1.show(this.popup1.options);
      },
      (error) => {
        console.log(error);
        this.popupMessage = "Fail to update";

        this.popup1.options = {
          header: "Error",
          color: "red",
          widthProsentage: 40,
          animationDuration: 0,
          showButtons: true,
          confirmBtnContent: "OK",
          cancleBtnContent: "Cancel",
          confirmBtnClass: "btn btn-default",
          cancleBtnClass: "one-button-hidden",
        };

        this.popup1.show(this.popup1.options);
      }
    );
  }

  onValidateUpdateStatus(accountStatus: string) {
    this.popupMessage = "";

    if (
      accountStatus == "Estimated" &&
      (this.selectedRow.stuId == "" || this.selectedRow.stuId == null)
    ) {
      this.popupMessage =
        "Please fill 'Admission -> Student ID' that is empty to proceed changing the selected account status to Pending.";
    }

    if (
      (accountStatus == "Cancel" || accountStatus == "Defer") &&
      (this.selectedRow.accountingMemo == undefined ||
        this.selectedRow.accountingMemo == "" ||
        this.selectedRow.accountingMemo == null)
    ) {
      this.popupMessage =
        "Please write an accounting memo about the reason of defer or cancel.";
    }

    if (this.popupMessage !== "") {
      this.popup1.options = {
        header: "Error",
        color: "red",
        widthProsentage: 50,
        animationDuration: 0,
        showButtons: true,
        confirmBtnContent: "OK",
        cancleBtnContent: "Cancel",
        confirmBtnClass: "btn btn-default",
        cancleBtnClass: "one-button-hidden",
      };

      this.popup1.show(this.popup1.options);
      return false;
    }

    return true;
  }

  onCreateAccountingMemoMsg() {
    this.popupMessage = "Would you like to create this accounting memo?";

    this.popup1.options = {
      header: "Information",
      color: "#5cb85c",
      widthProsentage: 50,
      animationDuration: 0,
      showButtons: true,
      confirmBtnContent: "OK",
      cancleBtnContent: "Cancel",
      confirmBtnClass: "btn btn-default",
      cancleBtnClass: "btn btn-default",
    };

    this.popup1.show(this.popup1.options);
  }

  onCreateAdminMemoMsg() {
    this.popupMessage = "Would you like to create this admission memo?";

    this.popup1.options = {
      header: "Information",
      color: "#5cb85c",
      widthProsentage: 50,
      animationDuration: 0,
      showButtons: true,
      confirmBtnContent: "OK",
      cancleBtnContent: "Cancel",
      confirmBtnClass: "btn btn-default",
      cancleBtnClass: "btn btn-default",
    };

    this.popup1.show(this.popup1.options);
  }

  onUpdateMsg() {
    var bugFixer1 = this.optionsModel1;
    var bugFixer2 = this.optionsModel2;

    var bugFixed1 = "";
    var bugFixed2 = "";

    this.selectedRow.otherDiscountNote = "";
    this.selectedRow.otherServiceFeeNote = "";

    bugFixer1.forEach(function (value) {
      if (value.id != null && value.id.length > 0) {
        bugFixed1 = bugFixed1 + value.id + ",";
      }
    });

    bugFixer2.forEach(function (value) {
      if (value.id != null && value.id.length > 0) {
        bugFixed2 = bugFixed2 + value.id + ",";
      }
    });

    /*
    console.log("optionModel1: " + this.optionsModel1);
    console.log("optionModel2: " + this.optionsModel2);
    console.log("bugFixed1: " + bugFixed1);
    console.log("bugFixed2: " + bugFixed2);
    console.log("discountNote: " + this.selectedRow.otherDiscountNote);
    console.log("otherServiceFeeNote: " + this.selectedRow.otherServiceFeeNote);
    */

    if (bugFixed1 != "") {
      this.selectedRow.otherDiscountNote = bugFixed1.substring(
        0,
        bugFixed1.length - 1
      );
    } else {
      this.selectedRow.otherDiscountNote = this.optionsModel1 + "";
    }

    if (bugFixed2 != "") {
      this.selectedRow.otherServiceFeeNote = bugFixed2.substring(
        0,
        bugFixed2.length - 1
      );
    } else {
      this.selectedRow.otherServiceFeeNote = this.optionsModel2 + "";
    }

    if (
      this.selectedRow.otherServiceFeeNote != "Bonus Commission from School"
    ) {
      //changed to if student Id is none or D.O.B IS none (danger)
      if (
        this.selectedRow.school != "- Insurance Only" &&
        this.selectedRow.school != "- Other Services Only" &&
        this.selectedRow.school != "- Visa Only" &&
        (this.selectedRow.accountStatus === "UnApplied" ||
          this.selectedRow.accountStatus === "Holding") &&
        !this.selectedRow.stuId &&
        !this.basic.mdDob
      ) {
        this._flashMessagesService.show(
          `Please, check Student's ID(Admission) or D.O.B(Basic Info) to update primary details`,
          { cssClass: "alert-danger", timeout: 7000 }
        );
      }
      //Tuition Gross filled - note is empty
      else if (
        this.selectedRow.school != "- Insurance Only" &&
        this.selectedRow.school != "- Other Services Only" &&
        this.selectedRow.school != "- Visa Only" &&
        (this.selectedRow.accountStatus === "UnApplied" ||
          this.selectedRow.accountStatus === "Holding") &&
        (this.selectedRow.tuitionGrossNote == null ||
          this.selectedRow.tuitionGrossNote.length < 2)
      ) {
        this._flashMessagesService.show(
          `Tuition Gross - please update where to pay. (Error)`,
          { cssClass: "alert-danger", timeout: 7000 }
        );
      }
      //Registeration filled - note is empty
      else if (
        this.selectedRow.school != "- Insurance Only" &&
        this.selectedRow.school != "- Other Services Only" &&
        this.selectedRow.school != "- Visa Only" &&
        (this.selectedRow.accountStatus === "UnApplied" ||
          this.selectedRow.accountStatus === "Holding") &&
        (this.selectedRow.registrationNote == null ||
          this.selectedRow.registrationNote.length < 2)
      ) {
        this._flashMessagesService.show(
          `Registeration - please update where to pay. (Error)`,
          { cssClass: "alert-danger", timeout: 7000 }
        );
      }
      //Material filled - note is empty
      else if (
        this.selectedRow.school != "- Insurance Only" &&
        this.selectedRow.school != "- Other Services Only" &&
        this.selectedRow.school != "- Visa Only" &&
        (this.selectedRow.accountStatus === "UnApplied" ||
          this.selectedRow.accountStatus === "Holding") &&
        (this.selectedRow.materialNote == null ||
          this.selectedRow.materialNote.length < 2)
      ) {
        this._flashMessagesService.show(
          `Material - please update where to pay. (Error)`,
          { cssClass: "alert-danger", timeout: 7000 }
        );
      }
      //Exam Fee filled - note is empty
      else if (
        this.selectedRow.school != "- Insurance Only" &&
        this.selectedRow.school != "- Other Services Only" &&
        this.selectedRow.school != "- Visa Only" &&
        (this.selectedRow.accountStatus === "UnApplied" ||
          this.selectedRow.accountStatus === "Holding") &&
        (this.selectedRow.examFeeNote == null ||
          this.selectedRow.examFeeNote.length < 2)
      ) {
        this._flashMessagesService.show(
          `Exam Fee - please update where to pay. (Error)`,
          { cssClass: "alert-danger", timeout: 7000 }
        );
      }
      //Insurance filled - note is empty
      else if (
        this.selectedRow.school != "- Visa Only" &&
        this.selectedRow.school != "- Other Services Only" &&
        (this.selectedRow.accountStatus === "UnApplied" ||
          this.selectedRow.accountStatus === "Holding") &&
        (this.selectedRow.insuranceNote == null ||
          this.selectedRow.insuranceNote.length < 2)
      ) {
        this._flashMessagesService.show(
          `Insurance - please update where to pay or what type. (Error)`,
          { cssClass: "alert-danger", timeout: 7000 }
        );
      }
      //Visa filled - note is empty
      else if (
        this.selectedRow.school != "- Insurance Only" &&
        this.selectedRow.school != "- Other Services Only" &&
        (this.selectedRow.accountStatus === "UnApplied" ||
          this.selectedRow.accountStatus === "Holding") &&
        (this.selectedRow.visaApplicationFeeNote == null ||
          this.selectedRow.visaApplicationFeeNote.length < 2)
      ) {
        this._flashMessagesService.show(
          `Visa Application - please update where to pay or what type. (Error)`,
          { cssClass: "alert-danger", timeout: 7000 }
        );
      }
      //Others filled - note is empty
      else if (
        this.selectedRow.school != "- Insurance Only" &&
        this.selectedRow.school != "- Other Services Only" &&
        this.selectedRow.school != "- Visa Only" &&
        (this.selectedRow.accountStatus === "UnApplied" ||
          this.selectedRow.accountStatus === "Holding") &&
        (this.selectedRow.othersNote == null ||
          this.selectedRow.othersNote.length < 2)
      ) {
        this._flashMessagesService.show(
          `Others is filled, please update where to pay or what type. (Error)`,
          { cssClass: "alert-danger", timeout: 7000 }
        );
      }
      //Other Discount - To Be Deducted - note is empty
      else if (
        (this.selectedRow.accountStatus === "UnApplied" ||
          this.selectedRow.accountStatus === "Holding") &&
        (this.selectedRow.otherDiscountNote == null ||
          this.selectedRow.otherDiscountNote.length < 2) &&
        this.selectedRow.otherExBank != undefined &&
        this.selectedRow.otherExBank > 0.0
      ) {
        this._flashMessagesService.show(
          `Other Discount - To Be Deducted is filled, please update where to pay or what type. (Error)`,
          { cssClass: "alert-danger", timeout: 7000 }
        );
      }
      //Other Service Fee - To Be Added filled - note is empty
      else if (
        (this.selectedRow.accountStatus === "UnApplied" ||
          this.selectedRow.accountStatus === "Holding") &&
        (this.selectedRow.otherServiceFeeNote == null ||
          this.selectedRow.otherServiceFeeNote.length < 2) &&
        this.selectedRow.etcServiceBank != undefined &&
        this.selectedRow.etcServiceBank > 0.0
      ) {
        this._flashMessagesService.show(
          `Other Service Fee - To Be Added is filled, please update where to pay or what type. (Error)`,
          { cssClass: "alert-danger", timeout: 7000 }
        );
      } else if (
        this.selectedRow.receivedCom > 0 &&
        this.selectedRow.subCom > 0 &&
        this.selectedRow.subReal == 0
      ) {
        this.popupMessage =
          "Would you like to update selected accounting without 'Real Sub-Commission'?";

        this.popup1.options = {
          header: "Warning",
          color: "#ff9900",
          widthProsentage: 50,
          animationDuration: 0,
          showButtons: true,
          confirmBtnContent: "OK",
          cancleBtnContent: "Cancel",
          confirmBtnClass: "btn btn-default",
          cancleBtnClass: "btn btn-default",
        };

        this.popup1.show(this.popup1.options);
      } else {
        this.popupMessage = "Would you like to update selected accounting?";

        this.popup1.options = {
          header: "Information",
          color: "#5cb85c",
          widthProsentage: 50,
          animationDuration: 0,
          showButtons: true,
          confirmBtnContent: "OK",
          cancleBtnContent: "Cancel",
          confirmBtnClass: "btn btn-default",
          cancleBtnClass: "btn btn-default",
        };

        this.popup1.show(this.popup1.options);
      }
    } else if (
      this.selectedRow.receivedCom > 0 &&
      this.selectedRow.subCom > 0 &&
      this.selectedRow.subReal == 0
    ) {
      this.popupMessage =
        "Would you like to update selected accounting without 'Real Sub-Commission'?";

      this.popup1.options = {
        header: "Warning",
        color: "#ff9900",
        widthProsentage: 50,
        animationDuration: 0,
        showButtons: true,
        confirmBtnContent: "OK",
        cancleBtnContent: "Cancel",
        confirmBtnClass: "btn btn-default",
        cancleBtnClass: "btn btn-default",
      };

      this.popup1.show(this.popup1.options);
    } else {
      this.popupMessage = "Would you like to update selected accounting?";

      this.popup1.options = {
        header: "Information",
        color: "#5cb85c",
        widthProsentage: 50,
        animationDuration: 0,
        showButtons: true,
        confirmBtnContent: "OK",
        cancleBtnContent: "Cancel",
        confirmBtnClass: "btn btn-default",
        cancleBtnClass: "btn btn-default",
      };

      this.popup1.show(this.popup1.options);
    }
  }

  onDeleteMsg() {
    this.popupMessage = "Would you like to delete selected accounting?";

    this.popup1.options = {
      header: "Information",
      color: "#5cb85c",
      widthProsentage: 50,
      animationDuration: 0,
      showButtons: true,
      confirmBtnContent: "OK",
      cancleBtnContent: "Cancel",
      confirmBtnClass: "btn btn-default",
      cancleBtnClass: "btn btn-default",
    };

    this.popup1.show(this.popup1.options);
  }

  onDivideMsg() {
    this.popupMessage = "Would you like to divide selected accounting?";

    this.popup1.options = {
      header: "Information",
      color: "#5cb85c",
      widthProsentage: 50,
      animationDuration: 0,
      showButtons: true,
      confirmBtnContent: "OK",
      cancleBtnContent: "Cancel",
      confirmBtnClass: "btn btn-default",
      cancleBtnClass: "btn btn-default",
    };

    this.popup1.show(this.popup1.options);
  }

  onUpdateStatusMsg(accountStatus: string) {
    this.onUpdateStatusStr = accountStatus;

    if (accountStatus === "Return") {
      this.popupMessage =
        "Would you like to change status of selected Acc to" + " Return?";
    } else if (accountStatus === "Undo") {
      this.popupMessage =
        "Would you like to change status of selected Acc to" +
        " Return To Admin?";
    } else if (accountStatus === "Cancel/Refund") {
      this.popupMessage =
        "Would you like to change status of selected Acc to" +
        " Cancel/Refund?";
    } else if (accountStatus === "Cancelled") {
      this.popupMessage =
        "Would you like to change status of selected Acc to" + " Cancelled?";
    } else if (accountStatus === "Defer") {
      this.popupMessage =
        "Would you like to change status of selected Acc to" + " Defer?";
    } else if (accountStatus === "AccCancelled") {
      this.popupMessage =
        "Would you like to change status of selected Acc to" + " AccCancelled?";
    } else if (accountStatus === "Holding") {
      this.popupMessage =
        "Would you like to change status of selected Acc to" + " Holding?";
    } else if (accountStatus === "Estimated") {
      this.popupMessage =
        "Would you like to change status of selected Acc to" + " Pending?";
    } else if (accountStatus === "Pending") {
      this.popupMessage =
        "Would you like to change status of selected Acc to" + " Invoiced?";
    } else if (accountStatus === "Received") {
      this.popupMessage =
        "Would you like to change status of selected Acc to" + " Completed?";
    }

    this.popup1.options = {
      header: "Information",
      color: "#5cb85c",
      widthProsentage: 50,
      animationDuration: 0,
      showButtons: true,
      confirmBtnContent: "OK",
      cancleBtnContent: "Cancel",
      confirmBtnClass: "btn btn-default",
      cancleBtnClass: "btn btn-default",
    };

    this.popup1.show(this.popup1.options);
  }

  onUpdateStatus(accountStatus: string) {
    if (!this.onValidateUpdateStatus(accountStatus)) return;

    if (
      this.selectedRow.cpSubAgent != null &&
      this.selectedRow.cpSubAgent != "" &&
      this.selectedRow.accountStatus == "Pending" &&
      accountStatus == "Received"
    ) {
      accountStatus = "OnGoing";
    }

    this.selectedAdminId = this.selectedRow.id;
    this.accountingService
      .updateStatus({
        id: this.selectedRow.id,
        accountStatus: accountStatus,
        accountingMemo: this.selectedRow.accountingMemo,
      })
      .subscribe(
        (data) => {
          this.popupMessage = "Succeed in updating status";

          this.popup1.options = {
            header: "Information",
            color: "#5cb85c",
            widthProsentage: 40,
            animationDuration: 0,
            showButtons: true,
            confirmBtnContent: "OK",
            cancleBtnContent: "Cancel",
            confirmBtnClass: "btn btn-default",
            cancleBtnClass: "one-button-hidden",
          };

          this.popup1.show(this.popup1.options);
        },
        (error) => {
          console.log(error);
          this.popupMessage = "Fail to update status";

          this.popup1.options = {
            header: "Error",
            color: "red",
            widthProsentage: 40,
            animationDuration: 0,
            showButtons: true,
            confirmBtnContent: "OK",
            cancleBtnContent: "Cancel",
            confirmBtnClass: "btn btn-default",
            cancleBtnClass: "one-button-hidden",
          };

          this.popup1.show(this.popup1.options);
        }
      );
  }

  async onDivide() {
    this.selectedAdminId = this.selectedRow.id;

    // 현재 상태 저장.
    await this.accountingService.update(this.selectedRow).toPromise();

    this.accountingService
      .divide({
        id: this.selectedRow.id,
        divide: (+this.selectedRow.divide + 1).toString(),
      })
      .subscribe(
        (data) => {
          this.popupMessage = "Succeed in updating";

          this.popup1.options = {
            header: "Information",
            color: "#5cb85c",
            widthProsentage: 40,
            animationDuration: 0,
            showButtons: true,
            confirmBtnContent: "OK",
            cancleBtnContent: "Cancel",
            confirmBtnClass: "btn btn-default",
            cancleBtnClass: "one-button-hidden",
          };

          this.popup1.show(this.popup1.options);
        },
        (error) => {
          console.log(error);
          this.popupMessage = "Fail to update";

          this.popup1.options = {
            header: "Error",
            color: "red",
            widthProsentage: 40,
            animationDuration: 0,
            showButtons: true,
            confirmBtnContent: "OK",
            cancleBtnContent: "Cancel",
            confirmBtnClass: "btn btn-default",
            cancleBtnClass: "one-button-hidden",
          };

          this.popup1.show(this.popup1.options);
        }
      );
  }

  onDeleteAccountingMemo() {
    this.popupMessage = "Would you like to delete selected accounting memo?";

    this.popup1.options = {
      header: "Information",
      color: "#5cb85c",
      widthProsentage: 50,
      animationDuration: 0,
      showButtons: true,
      confirmBtnContent: "OK",
      cancleBtnContent: "Cancel",
      confirmBtnClass: "btn btn-default",
      cancleBtnClass: "btn btn-default",
    };

    this.popup1.show(this.popup1.options);
  }

  deleteAccountingMemo() {
    this.accountingMemoService.delete(this.selectedAccountingMemoRow).subscribe(
      (data) => {
        this.popupMessage = "Succeed in deleting";

        this.popup1.options = {
          header: "Information",
          color: "#5cb85c",
          widthProsentage: 20,
          animationDuration: 0,
          showButtons: true,
          confirmBtnContent: "OK",
          cancleBtnContent: "Cancel",
          confirmBtnClass: "btn btn-default",
          cancleBtnClass: "one-button-hidden",
        };

        this.popup1.show(this.popup1.options);

        this.accountingMemoRows = new Array<AccountingMemo>();
        //this.epChangedList = [];
      },
      (error) => {
        console.log(error);
        this.popupMessage = "Fail to delete";

        this.popup1.options = {
          header: "Error",
          color: "red",
          widthProsentage: 20,
          animationDuration: 0,
          showButtons: true,
          confirmBtnContent: "OK",
          cancleBtnContent: "Cancel",
          confirmBtnClass: "btn btn-default",
          cancleBtnClass: "one-button-hidden",
        };

        this.popup1.show(this.popup1.options);
      }
    );
  }

  onDelete() {
    this.convDateToStr();

    this.accountingService.delete(this.selectedRow).subscribe(
      (data) => {
        this.popupMessage = "Succeed in deleting";

        this.popup1.options = {
          header: "Information",
          color: "#5cb85c",
          widthProsentage: 40,
          animationDuration: 0,
          showButtons: true,
          confirmBtnContent: "OK",
          cancleBtnContent: "Cancel",
          confirmBtnClass: "btn btn-default",
          cancleBtnClass: "one-button-hidden",
        };

        this.popup1.show(this.popup1.options);
      },
      (error) => {
        console.log(error);
        this.popupMessage = "Fail to delete";

        this.popup1.options = {
          header: "Error",
          color: "red",
          widthProsentage: 40,
          animationDuration: 0,
          showButtons: true,
          confirmBtnContent: "OK",
          cancleBtnContent: "Cancel",
          confirmBtnClass: "btn btn-default",
          cancleBtnClass: "one-button-hidden",
        };

        this.popup1.show(this.popup1.options);
      }
    );
  }

  clickOKButton() {
    this.popup1.hide();
    if (
      this.popupMessage === "Succeed in creating" ||
      this.popupMessage === "Succeed in deleting" ||
      this.popupMessage === "Succeed in updating" ||
      this.popupMessage === "Succeed in updating status"
    ) {
      this.find();

      //this.selectedRow = new Accounting();
    } else if (this.popupMessage === "Succeed in creating a memo") {
      this.findMemo();
    } else if (
      this.popupMessage === "Would you like to delete selected accounting memo?"
    ) {
      this.deleteAccountingMemo();
      this.findMemo();
    } else if (
      this.popupMessage.indexOf(
        "Would you like to change status of selected Acc to"
      ) > -1
    ) {
      this.onUpdateStatus(this.onUpdateStatusStr);
    } else if (
      this.popupMessage === "Would you like to update selected accounting?" ||
      this.popupMessage ===
        "Would you like to update selected accounting without 'Real Sub-Commission'?"
    ) {
      this.onUpdate();
    } else if (
      this.popupMessage === "Would you like to divide selected accounting?"
    ) {
      this.onDivide();
    } else if (
      this.popupMessage === "Would you like to delete selected accounting?"
    ) {
      this.onDelete();
    } else if (
      this.popupMessage === "Would you like to create this accounting memo?"
    ) {
      this.onCreateAccountingMemo();
    }
  }

  find() {
    this.accountingService.getList(this.selectedBasicId).subscribe(
      (data) => {
        //console.log("data : " + JSON.stringify(data));
        //console.log("data.length : " + data.length);

        this.rows = new Array<Accounting>();
        data.forEach((followup) => this.rows.push(followup));
        //this.rows.push(new Accounting());

        //console.log("this.selectedAdminId : " + this.selectedAdminId);
        let account = this.rows.find((x) => x.id === this.selectedAdminId);

        //console.log("account : " + JSON.stringify(this.selectedAdminId));
        this.selected = [account];
        this.setValue(account);
        this.onSelected(account);

        this.onChangeSchoolType();
        this.convStrToDate();
        this.calculateInvoice();

        this.checkInvoice();
      },
      (error) => {
        console.log("error : ", error);
        if (error.status == "401") {
          this.router.navigate(["/logout"]);
        }
      }
    );

    this.basicService.get(this.selectedBasicId).subscribe((basic) => {
      this.basic = basic;
    });
  }

  calculateEndDate() {
    if (
      this.selectedRow.admissionSchoolType === "ELICOS" &&
      this.intake != undefined
    ) {
      let date = new Date();
      let weeks =
        this.selectedRow.weeks === undefined || this.selectedRow.weeks === ""
          ? 0
          : this.selectedRow.weeks;
      let freeWeeks =
        this.selectedRow.freeWeeks === undefined ||
        this.selectedRow.freeWeeks === ""
          ? 0
          : this.selectedRow.freeWeeks;

      let intake = new Date(Utils.formatDateStr(this.intake));
      let endDate = this.datePipe.transform(
        new Date(
          date.setTime(
            intake.getTime() + (+weeks + +freeWeeks) * 86400000 * 7
          ) -
            86400000 * 3
        ),
        "yyyy-MM-dd"
      );
      this.endDate = Utils.formatStrDate(endDate);
    }
  }

  onSelectedMenu(menu) {
    this.router.navigate(["/" + menu, this.selectedBasicId]);
  }

  deselectInvoice() {
    this.selected = [];
  }

  onOpenInvoice() {
    this.showInvoice = true;
    this.selected.forEach((x) => this.selectedInvoices.push(x));
    this.selectedInvoices = JSON.parse(JSON.stringify(this.selectedInvoices));
  }

  onClosedInvoice(showInvoice: boolean) {
    this.showInvoice = showInvoice;
    this.selectedInvoices.length = 0;
  }

  onSelected(row) {
    //console.log("onSelected row.id " + row.id);
    if (row.id === undefined) {
      //this.selectedAdmissionRow = new Admission();
      this.accountingMemoRows = new Array<AccountingMemo>();
      this.disabledRequest = true;
    } else {
      //this.selectedAdmissionRow = row;
      this.disabledRequest = true;
      this.findMemo();

      //this.selectedAdminId = this.selectedAdmissionRow.id;

      this.emailEpList.forEach((x) => {
        if (x.value == this.basic.ciEp) {
          x.checked = true;
        } else {
          x.checked = false;
        }
      });
    }
  }

  onSelectAccountingMemo({ selected }) {
    //console.log('Select Event', selected);
  }

  onActivateAccountingMemo(obj) {
    if (obj.type === "click") {
      this.selectedAccountingMemoRow = obj.row;
    }
  }

  onValidateAccountingMemo() {
    this.popupMessage = "";

    if (
      this.selectedAccountingMemoRow.accountingNote === undefined ||
      this.selectedAccountingMemoRow.accountingNote === ""
    ) {
      this.popupMessage = "Accounting Note is required";
    }

    if (this.popupMessage !== "") {
      this.popup1.options = {
        header: "Error",
        color: "red",
        widthProsentage: 40,
        animationDuration: 0,
        showButtons: true,
        confirmBtnContent: "OK",
        cancleBtnContent: "Cancel",
        confirmBtnClass: "btn btn-default",
        cancleBtnClass: "one-button-hidden",
      };

      this.popup1.show(this.popup1.options);
      return false;
    }

    return true;
  }

  onCreateAccountingMemo() {
    if (!this.onValidateAccountingMemo()) return;

    this.selectedAccountingMemoRow.basicId = this.selectedBasicId;
    this.selectedAccountingMemoRow.adminId = this.selectedRow.id;
    this.selectedAccountingMemoRow.accEp = this.selectedRow.cpEp;
    this.selectedAccountingMemoRow.emailAlarm = this.selectedEmailEp.toString();

    this.selectedAccountingMemoRow.accountingNote =
      this.selectedAccountingMemoRow.accountingNote;

    this.accountingMemoService.create(this.selectedAccountingMemoRow).subscribe(
      (data) => {
        this.accountingMemoModal.hide();
        //staticModal.show();
        //alert("update is successful");
        this.popupMessage = "Succeed in creating a memo";

        this.popup1.options = {
          header: "Information",
          color: "#5cb85c",
          widthProsentage: 30,
          animationDuration: 0,
          showButtons: true,
          confirmBtnContent: "OK",
          cancleBtnContent: "Cancel",
          confirmBtnClass: "btn btn-default",
          cancleBtnClass: "one-button-hidden",
        };

        this.popup1.show(this.popup1.options);
        //setTimeout(()=>{this.popup1.hide()},1000);
        //setTimeout(()=>{this.find(data.id)},1000);

        this.selectedAdminId = data.adminId;

        if (
          this.selectedAccountingMemoRow.accountingNote ==
          "Fee matching request"
        ) {
          console.log("fee matching request detected");
          this.onUpdate();
        }
      },
      (error) => {
        console.log(error);
        this.accountingMemoModal.hide();

        this.popupMessage = "Fail to create";

        this.popup1.options = {
          header: "Error",
          color: "red",
          widthProsentage: 20,
          animationDuration: 0,
          showButtons: true,
          confirmBtnContent: "OK",
          cancleBtnContent: "Cancel",
          confirmBtnClass: "btn btn-default",
          cancleBtnClass: "one-button-hidden",
        };

        this.popup1.show(this.popup1.options);
      }
    );
  }

  get selectedEmailEp() {
    return this.emailEpList
      .filter((opt) => opt.checked)
      .map((opt) => opt.value);
  }

  findMemo() {
    this.accountingMemoService.getList(this.selectedRow.id).subscribe(
      (data) => {
        //console.log("data : " + JSON.stringify(data));
        //console.log("data.length : " + data.length);

        this.accountingMemoRows = new Array<AccountingMemo>();
        data.forEach((accountingMemo) =>
          this.accountingMemoRows.push(accountingMemo)
        );

        //this.getEpChanged();
      },
      (error) => {
        console.log("error : ", error);
      }
    );
  }

  onFeeMatchingRequest() {
    if (this.selectedRow === undefined || this.selectedRow.id === undefined) {
      this.popupMessage = "Please click an accounting above to add new memo ";

      this.popup1.options = {
        header: "Information",
        color: "#5cb85c",
        widthProsentage: 55,
        animationDuration: 0,
        showButtons: true,
        confirmBtnContent: "OK",
        cancleBtnContent: "Cancel",
        confirmBtnClass: "btn btn-default",
        cancleBtnClass: "one-button-hidden",
      };

      this.popup1.show(this.popup1.options);
    } else {
      this.emailEpList.forEach((x) => {
        if (
          x.value == this.basic.ciEp ||
          x.value == this.selectedRow.cpEp ||
          x.value == "Hyunsil"
        ) {
          x.checked = true;
        } else {
          x.checked = false;
        }
      });

      var bugFixer1 = this.optionsModel1;
      var bugFixer2 = this.optionsModel2;

      var bugFixed1 = "";
      var bugFixed2 = "";

      this.selectedRow.otherDiscountNote = "";
      this.selectedRow.otherServiceFeeNote = "";

      bugFixer1.forEach(function (value) {
        if (value.length > 4) {
          bugFixed1 = bugFixed1 + value.id + ",";
        }
      });

      bugFixer2.forEach(function (value) {
        if (value.length > 4) {
          bugFixed2 = bugFixed2 + value.id + ",";
        }
      });

      this.selectedRow.otherDiscountNote = bugFixed1.substring(
        0,
        bugFixed1.length - 1
      );
      this.selectedRow.otherServiceFeeNote = bugFixed2.substring(
        0,
        bugFixed2.length - 1
      );

      this.selectedAccountingMemoRow = new AccountingMemo();

      this.selectedAccountingMemoRow.accountingNote = "Fee matching request";

      //this.resetCheckedEmail();

      var autoCalculatedNumber: number = 0;

      this.selectedAccountingMemoRow.memo = "- Deposit to KOKOS -\n";
      this.selectedAccountingMemoRow.memo +=
        "Please, confirm the below amount is deposited correctly.\n";

      if (
        this.selectedRow.tuitionGrossNote == "Paid to KOKOS" &&
        this.selectedRow.tuitionGross != 0
      ) {
        this.selectedAccountingMemoRow.memo +=
          "Tuition Gross: " + this.selectedRow.tuitionGross + "\n";
        autoCalculatedNumber += this.selectedRow.tuitionGross;
      }

      if (
        this.selectedRow.registrationNote == "Paid to KOKOS" &&
        this.selectedRow.schoolRegistrationBank != 0
      ) {
        this.selectedAccountingMemoRow.memo +=
          "Registration: " + this.selectedRow.schoolRegistrationBank + "\n";
        autoCalculatedNumber += this.selectedRow.schoolRegistrationBank;
      }

      if (
        this.selectedRow.materialNote == "Paid to KOKOS" &&
        this.selectedRow.schoolMaterialBank != 0
      ) {
        this.selectedAccountingMemoRow.memo +=
          "Material: " + this.selectedRow.schoolMaterialBank + "\n";
        autoCalculatedNumber += this.selectedRow.schoolMaterialBank;
      }

      if (
        this.selectedRow.examFeeNote == "Paid to KOKOS" &&
        this.selectedRow.schoolExamBank != 0
      ) {
        this.selectedAccountingMemoRow.memo +=
          "Exam Fee: " + this.selectedRow.schoolExamBank + "\n";
        autoCalculatedNumber += this.selectedRow.schoolExamBank;
      }

      if (
        this.selectedRow.othersNote == "Paid to KOKOS" &&
        this.selectedRow.sOthersBank != 0
      ) {
        this.selectedAccountingMemoRow.memo +=
          "Others: " + this.selectedRow.sOthersBank + "\n";
        autoCalculatedNumber += this.selectedRow.sOthersBank;
      }

      this.selectedAccountingMemoRow.memo += "\n";

      if (this.insPaidDate != undefined) {
        if (
          this.selectedRow.insuranceNote != null &&
          this.selectedRow.insuranceNote != "" &&
          this.selectedRow.insuranceNote != "Paid to School" &&
          this.selectedRow.insuranceNote != "Done By Student" &&
          this.selectedRow.insuranceNote != "Done By Subagent"
        ) {
          this.selectedAccountingMemoRow.memo +=
            "Insurance: (" +
            this.selectedRow.insuranceNote +
            "): " +
            +this.selectedRow.sInsuranceBank;

          if (
            this.etcInsuranceBank != null &&
            +this.etcInsuranceBank != 0 &&
            +this.etcInsuranceBank != 0.0
          ) {
            this.selectedAccountingMemoRow.memo +=
              " (discount [" + this.etcInsuranceBank + "] applied)";
          }

          this.selectedAccountingMemoRow.memo += "\n";
          this.selectedAccountingMemoRow.memo +=
            "Total $" +
            (+this.selectedRow.sInsuranceBank - +this.etcInsuranceBank) +
            "\n";
          this.selectedAccountingMemoRow.memo +=
            "Paid to: " + this.selectedRow.insPaidToKokosDepositType + "\n";
          this.selectedAccountingMemoRow.memo +=
            "Date Paid: " + Utils.formatDateStr(this.insPaidDate) + "\n";

          autoCalculatedNumber +=
            +this.selectedRow.sInsuranceBank - +this.etcInsuranceBank;
          this.selectedAccountingMemoRow.memo += "\n";
        }
      }

      if (
        this.visaCreditPurchaseDate != undefined &&
        this.selectedRow.sVisaBank != 0 &&
        this.selectedRow.sVisaBank != 0.0
      ) {
        this.selectedAccountingMemoRow.memo +=
          "Credit Card (Visa Application Fee): $" +
          this.selectedRow.sVisaBank +
          "\n";
        this.selectedAccountingMemoRow.memo +=
          "Credit card purchase date: " +
          Utils.formatDateStr(this.visaCreditPurchaseDate) +
          "\n\n";
      }

      if (this.visaPaidDate != undefined) {
        if (
          this.selectedRow.visaApplicationFeeNote != null &&
          this.selectedRow.visaApplicationFeeNote != "" &&
          this.selectedRow.visaApplicationFeeNote != "Done By Student" &&
          this.selectedRow.visaApplicationFeeNote != "Done By Agent(Advisor)"
        ) {
          this.selectedAccountingMemoRow.memo +=
            "Visa Application Fee (" +
            this.selectedRow.visaApplicationFeeNote +
            "): " +
            +this.selectedRow.sVisaBank;

          if (
            this.etcVisaBank != null &&
            +this.etcVisaBank != 0 &&
            +this.etcVisaBank != 0.0
          ) {
            this.selectedAccountingMemoRow.memo +=
              " (discount [" + this.etcVisaBank + "] applied)";
          }

          this.selectedAccountingMemoRow.memo += "\n";
          this.selectedAccountingMemoRow.memo +=
            "Total $" +
            (+this.selectedRow.sVisaBank - +this.etcVisaBank) +
            "\n";
          this.selectedAccountingMemoRow.memo +=
            "Paid to: " + this.selectedRow.visaPaidToKokosDepositType + "\n";
          this.selectedAccountingMemoRow.memo +=
            "Date Paid: " + Utils.formatDateStr(this.visaPaidDate) + "\n";

          autoCalculatedNumber +=
            +this.selectedRow.sVisaBank - +this.etcVisaBank;
          this.selectedAccountingMemoRow.memo += "\n";
        }
      }

      if (this.osPaidDate != undefined) {
        this.selectedAccountingMemoRow.memo +=
          "Others Service Fee (" +
          this.selectedRow.otherServiceFeeNote +
          "): " +
          +this.selectedRow.etcServiceBank;
        autoCalculatedNumber += this.selectedRow.etcServiceBank;

        if (
          this.removeComma(this.otherExBank) != null &&
          this.removeComma(this.otherExBank) != 0
        ) {
          this.selectedAccountingMemoRow.memo +=
            " (discount [" + this.removeComma(this.otherExBank) + "] applied)";
          autoCalculatedNumber -= this.removeComma(this.otherExBank);
        }
        this.selectedAccountingMemoRow.memo += "\n";

        this.selectedAccountingMemoRow.memo +=
          "Total $" +
          (+this.selectedRow.etcServiceBank -
            this.removeComma(this.otherExBank)) +
          "\n";
        this.selectedAccountingMemoRow.memo +=
          "Paid to: " + this.selectedRow.osPaidToKokosDepositType + "\n";
        this.selectedAccountingMemoRow.memo +=
          "Date Paid: " + Utils.formatDateStr(this.osPaidDate) + "\n";

        this.selectedAccountingMemoRow.memo += "\n";
      } else if (
        this.osPaidDate == undefined &&
        (this.selectedRow.etcServiceBank == null ||
          this.selectedRow.etcServiceBank == 0)
      ) {
        if (
          this.removeComma(this.otherExBank) != null &&
          this.removeComma(this.otherExBank) != 0
        ) {
          this.selectedAccountingMemoRow.memo +=
            "Other Discount (" +
            this.selectedRow.otherDiscountNote +
            "): " +
            this.removeComma(this.otherExBank) +
            " (to be deducted)\n";
          this.selectedAccountingMemoRow.memo += "\n";

          autoCalculatedNumber -= this.removeComma(this.otherExBank);
        }
      }

      this.selectedAccountingMemoRow.memo +=
        "Total $" + Utils.addComma(autoCalculatedNumber) + "\n";
      this.selectedAccountingMemoRow.memo +=
        "------------------------------------\n";
      this.selectedAccountingMemoRow.memo +=
        "Please, add any extra memo if necessary\n";

      this.accountingMemoModal.show();
    }
  }

  showAccountingMemoModal(): void {
    if (this.selectedRow === undefined || this.selectedRow.id === undefined) {
      this.popupMessage = "Please click an accounting above to add new memo ";

      this.popup1.options = {
        header: "Information",
        color: "#5cb85c",
        widthProsentage: 55,
        animationDuration: 0,
        showButtons: true,
        confirmBtnContent: "OK",
        cancleBtnContent: "Cancel",
        confirmBtnClass: "btn btn-default",
        cancleBtnClass: "one-button-hidden",
      };

      this.popup1.show(this.popup1.options);
    } else {
      this.selectedAccountingMemoRow = new AccountingMemo();

      //this.resetCheckedEmail();

      this.accountingMemoModal.show();
    }
  }

  hideAccountingMemoModal(): void {
    this.accountingMemoModal.hide();
  }

  goBack(): void {
    this.location.back();
  }
}
