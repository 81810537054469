import { Location } from "@angular/common";
import { Component, OnInit, ViewChild, ViewContainerRef } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { FlashMessagesService } from "angular2-flash-messages";
import { Profile } from "app/model/profile";
import * as FileSaver from "file-saver";
import * as _ from "lodash";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { FileUploader } from "ng2-file-upload";
import { Popup } from "ng2-opd-popup";
import { ModalDirective } from "ngx-bootstrap/modal";
import { filter } from "rxjs/operators";
import { Agent } from "../../model/agent";
import { AgentFiles } from "../../model/agent-files";
import { AgentMemo } from "../../model/agent-memo";
import { CodeCommon } from "../../model/code-common";
import { AgentFilesService } from "../../services/agent-files.service";
import { AgentMemoService } from "../../services/agent-memo.service";
import { AgentService } from "../../services/agent.service";
import { AuthenticationService } from "../../services/authentication.service";
import { CodeService } from "../../services/code.service";

@Component({
  selector: "app-agent",
  providers: [AgentService, AgentMemoService, AgentFilesService],
  templateUrl: "./agent.component.html",
  styleUrls: ["./agent.component.css"],
})
export class AgentComponent implements OnInit {
  @ViewChild("popup1") popup1: Popup;
  popupMessage: string;

  agentNoteList: any[] = [
    { code: "Phone", value: "Phone" },
    { code: "Face", value: "Face" },
    { code: "Online", value: "Online" },
  ];

  public uploader: FileUploader = new FileUploader({ queueLimit: 999 });

  public agentFiles = new Array<AgentFiles>();

  createUpdateButtonDisabled: boolean = false;

  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: "code",
    textField: "value",
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    itemsShowLimit: 2,
    allowSearchFilter: true,
  };

  selectedTab: number;
  processedCount = 0;
  totalFiles = 0;

  public searchTerm: string;

  public form: FormGroup;

  authority: string;
  name: string;
  company: string;

  public agents: Agent[] = [];
  public selectedAgent: Agent;

  epList: any[] = [];
  nationalityList: any[] = [];

  addNew = new Agent();

  emailEpList: any[] = [];
  disabledRequest: boolean = true;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private agentFilesService: AgentFilesService,
    formBuilder: FormBuilder,
    private agentService: AgentService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private codeService: CodeService,
    private agentMemoService: AgentMemoService,
    private route: ActivatedRoute,
    private location: Location,
    private _flashMessagesService: FlashMessagesService
  ) {
    authenticationService.profile$
      .pipe(filter<Profile>(Boolean))
      .subscribe((profile) => {
        this.name = profile.name;
        this.authority = profile.authority;
        this.company = profile.company;
      });

    this.form = formBuilder.group({
      id: formBuilder.control(null, []),
      name: formBuilder.control(null, [Validators.required]),
      phone: formBuilder.control(null, []),
      marketCountry: formBuilder.control(null, []),
      basedCountry: formBuilder.control(null, []),
      basedCity: formBuilder.control(null, []),
      representative: formBuilder.control(null, []),
      email: formBuilder.control(null, []),
      managingEP: formBuilder.control(null, []),
      active: formBuilder.control(true, []),
      memo: formBuilder.control(null, []),
      agentCode: formBuilder.control(null, []),
      commissionRateNZ: formBuilder.control(null, []),
      commissionRateAU: formBuilder.control(null, []),
    });

    codeService.getCommon(this);
  }

  onFileWithoutDeleting(agentFiles: AgentFiles[]): AgentFiles[] {
    let result: AgentFiles[] = [];

    for (var i = 0; agentFiles != undefined && i < agentFiles.length; i++) {
      if (agentFiles[i].mode === undefined || agentFiles[i].mode == "") {
        result.push(agentFiles[i]);
      }
    }
    return result;
  }

  readQueue(action) {
    for (var idx = 0; idx < this.uploader.queue.length; idx++) {
      this.readFile(action, this.uploader.queue[idx]._file);
    }
  }

  readFile(action, f) {
    var fileReader = new FileReader();

    var that = this;
    fileReader.onload = function () {
      //console.log("content   : " + window.btoa(fileReader.result));
      that.addFile(
        action,
        window.btoa(fileReader.result as string),
        f.name,
        f.size
      );
    };

    fileReader.readAsBinaryString(f);
  }

  addFile(action, result, filename, filesize) {
    let informationFile = new AgentFiles();

    informationFile.filesize = filesize;
    informationFile.filename = filename;
    informationFile.file = result;
    informationFile.mode = "I";

    this.agentFiles.push(informationFile);
    this.processedCount++;

    this.agentFiles.forEach(function (elem) {
      //console.log("File Name : " + elem.filename);
    });

    if (this.processedCount == this.totalFiles) {
      this.onSubmitWroker(action);
    }
  }

  selectTab(idx: number) {
    this.selectedTab = idx;
  }

  clickOKButton() {
    if (this.popupMessage === "Would you like to create this agent memo?") {
      this.onCreateAgentMemo();
    } else if (
      this.popupMessage === "Would you like to delete selected accounting memo?"
    ) {
      this.deleteAgentMemo();
    }

    this.popup1.hide();
  }

  setCodeList(codeCommon: CodeCommon) {
    if (codeCommon == undefined) {
      this.router.navigate(["/logout"]);
    }
    this.epList = codeCommon.epList;

    this.nationalityList = codeCommon.nationalityList;

    this.emailEpList = JSON.parse(JSON.stringify(codeCommon.epList));
  }

  onSaveFile(fileContent, fileName) {
    var byteContents = window.atob(fileContent);
    var byteNumbers = new Array(byteContents.length);

    for (var i = 0; i < byteContents.length; i++) {
      byteNumbers[i] = byteContents.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);

    let blob = new Blob([byteArray]);
    FileSaver.saveAs(blob, fileName);
  }

  setInit() {
    this.selectedTab = 0;

    if (
      this.selectedAgent != null &&
      this.selectedAgent.id != null &&
      this.selectedAgent.id != undefined
    ) {
      this.agentMemoService.getList(this.selectedAgent.id).subscribe(
        (data) => {
          this.selectedAgentMemoRow = new AgentMemo();
          this.agentMemoRows = new Array<AgentMemo>();
          data.forEach((agentMemo) => this.agentMemoRows.push(agentMemo));

          this.getFiles();
        },
        (error) => {
          console.log("error : ", error);
          if (error.status == "401") {
            this.router.navigate(["/logout"]);
          }
        }
      );
    }
  }

  onDeleteFile(fileName) {
    this.agentFiles.find(function (item) {
      return item.filename === fileName;
    }).mode = "D";
  }

  getFiles() {
    if (this.selectedAgent.id !== undefined) {
      this.agentFilesService.getList(this.selectedAgent.id).subscribe(
        (data) => {
          //console.log("data : " + JSON.stringify(data));
          //console.log("data.length : " + data.length);

          this.agentFiles = new Array<AgentFiles>();
          data.forEach((file) => this.agentFiles.push(file));
        },
        (error) => {
          console.log("error : ", error);
        }
      );
    }
  }

  ngOnInit() {
    this.agentService.getAll().subscribe((data) => {
      this.agents = data.content;
      this.agents = this.agents.sort((n1, n2) => {
        if (n1.active > n2.active) {
          return -1;
        }

        if (n1.active < n2.active) {
          return 1;
        }

        return 0;
      });
    });

    this.addNew.name = "[Add New]";
    this.addNew.id = null;
    this.addNew.active = true;

    this.agents.push(this.addNew);

    this.setInit();
  }

  onSubmit(agent: Agent) {
    this.createUpdateButtonDisabled = true;

    if (agent.active == null || agent.active == undefined) agent.active = true;

    this.totalFiles = this.uploader.queue.length;

    if (this.totalFiles > 0) {
      this.readQueue(agent);
    } else {
      this.onSubmitWroker(agent);
    }
  }

  onSubmitWroker(agent: Agent) {
    agent.agentFilesParams = new Array<AgentFiles>();

    for (var i = 0; i < this.agentFiles.length; i++) {
      agent.agentFilesParams.push(this.agentFiles[i]);
    }

    agent.marketCountry =
      _.size(agent.marketCountry) > 0
        ? _.map(agent.marketCountry, (r) => r.code).join(", ")
        : "";

    if (agent.id) {
      this.agentService.update(agent).subscribe((data) => {
        this.agents = _.map(this.agents, (agent) => {
          return agent.id === data.id ? data : agent;
        });

        this.uploader = new FileUploader({ queueLimit: 999 });
        this.processedCount = 0;
        this.totalFiles = 0;
        this.createUpdateButtonDisabled = false;
      });

      this.popupMessage = "Succeed in updating";

      this.popup1.options = {
        header: "Information",
        color: "#5cb85c", // red, blue....
        widthProsentage: 20, // The with of the popou measured by browser width
        animationDuration: 0, // in seconds, 0 = no animation
        showButtons: true, // You can hide this in case you want to use custom buttons
        confirmBtnContent: "OK", // The text on your confirm button
        cancleBtnContent: "Cancel", // the text on your cancel button
        confirmBtnClass: "btn btn-default", // your class for styling the confirm button
        cancleBtnClass: "one-button-hidden", // you class for styling the cancel button
        //animation: "fadeInDown" // 'fadeInLeft', 'fadeInRight', 'fadeInUp', 'bounceIn','bounceInDown'
      };

      this.popup1.show(this.popup1.options);
    } else {
      this.agentService.create(agent).subscribe((data) => {
        this.agents = [data, ...this.agents];

        this.uploader = new FileUploader({ queueLimit: 999 });
        this.processedCount = 0;
        this.totalFiles = 0;
        this.createUpdateButtonDisabled = false;
      });

      this.popupMessage = "Succeed in creating";

      this.popup1.options = {
        header: "Information",
        color: "#5cb85c", // red, blue....
        widthProsentage: 20, // The with of the popou measured by browser width
        animationDuration: 0, // in seconds, 0 = no animation
        showButtons: true, // You can hide this in case you want to use custom buttons
        confirmBtnContent: "OK", // The text on your confirm button
        cancleBtnContent: "Cancel", // the text on your cancel button
        confirmBtnClass: "btn btn-default", // your class for styling the confirm button
        cancleBtnClass: "one-button-hidden", // you class for styling the cancel button
        //animation: "fadeInDown" // 'fadeInLeft', 'fadeInRight', 'fadeInUp', 'bounceIn','bounceInDown'
      };

      this.popup1.show(this.popup1.options);
    }
  }

  selectAgent(agent: Agent) {
    this.selectedAgent = agent;

    if (agent) {
      this.form.patchValue({
        id: agent.id,
        name: agent.name,
        phone: agent.phone,
        marketCountry: _.isArray(agent.marketCountry)
          ? agent.marketCountry
          : agent.marketCountry && agent.marketCountry.length > 0
          ? (<string>agent.marketCountry)
              .split(", ")
              .map((item) =>
                _.find(this.nationalityList, (c) => c.code === item)
              )
          : [],

        basedCountry: agent.basedCountry,
        basedCity: agent.basedCity,
        representative: agent.representative,
        email: agent.email,
        managingEP: agent.managingEP,
        active: agent.active,
        memo: agent.memo,
        agentCode: agent.agentCode,
        commissionRateNZ: agent.commissionRateNZ,
        commissionRateAU: agent.commissionRateAU,
      });
    } else {
      agent = new Agent();
      this.selectedAgent = agent;

      this.form.patchValue({
        id: agent.id,
        name: agent.name,
        phone: agent.phone,
        marketCountry: agent.marketCountry,
        basedCountry: agent.basedCountry,
        basedCity: agent.basedCity,
        representative: agent.representative,
        email: agent.email,
        managingEP: agent.managingEP,
        active: agent.active,
        memo: agent.memo,
        agentCode: agent.agentCode,
        commissionRateNZ: agent.commissionRateNZ,
        commissionRateAU: agent.commissionRateAU,
      });
    }

    this.setInit();
  }

  /* memo part */

  public agentMemoRows = new Array<AgentMemo>();
  public selectedAgentMemoRow = new AgentMemo();
  @ViewChild("agentMemoModal") public agentMemoModal: ModalDirective;

  onCreateAgentMemoMsg() {
    this.popupMessage = "Would you like to create this agent memo?";

    this.popup1.options = {
      header: "Information",
      color: "#5cb85c",
      widthProsentage: 50,
      animationDuration: 0,
      showButtons: true,
      confirmBtnContent: "OK",
      cancleBtnContent: "Cancel",
      confirmBtnClass: "btn btn-default",
      cancleBtnClass: "btn btn-default",
    };

    this.popup1.show(this.popup1.options);
  }

  onDeleteAgentMemo() {
    this.popupMessage = "Would you like to delete selected accounting memo?";

    this.popup1.options = {
      header: "Information",
      color: "#5cb85c",
      widthProsentage: 50,
      animationDuration: 0,
      showButtons: true,
      confirmBtnContent: "OK",
      cancleBtnContent: "Cancel",
      confirmBtnClass: "btn btn-default",
      cancleBtnClass: "btn btn-default",
    };

    this.popup1.show(this.popup1.options);
  }

  deleteAgentMemo() {
    this.agentMemoService.delete(this.selectedAgentMemoRow).subscribe(
      (data) => {
        this.popupMessage = "Succeed in deleting";

        this.popup1.options = {
          header: "Information",
          color: "#5cb85c",
          widthProsentage: 20,
          animationDuration: 0,
          showButtons: true,
          confirmBtnContent: "OK",
          cancleBtnContent: "Cancel",
          confirmBtnClass: "btn btn-default",
          cancleBtnClass: "one-button-hidden",
        };

        this.popup1.show(this.popup1.options);

        this.agentMemoRows = new Array<AgentMemo>();
        this.setInit();
      },
      (error) => {
        console.log(error);
        this.popupMessage = "Fail to delete";

        this.popup1.options = {
          header: "Error",
          color: "red",
          widthProsentage: 20,
          animationDuration: 0,
          showButtons: true,
          confirmBtnContent: "OK",
          cancleBtnContent: "Cancel",
          confirmBtnClass: "btn btn-default",
          cancleBtnClass: "one-button-hidden",
        };

        this.popup1.show(this.popup1.options);
      }
    );
  }

  onSelectAgentMemo({ selected }) {
    //console.log('Select Event', selected);
  }

  onActivateAgentMemo(obj) {
    if (obj.type === "click") {
      //console.log("clicked");

      this.agentFiles = new Array<AgentFiles>();

      this.uploader = new FileUploader({ queueLimit: 999 });
      this.processedCount = 0;
      this.totalFiles = 0;

      this.selectedAgentMemoRow = obj.row;
    }
  }

  onValidateAgentMemo() {
    this.popupMessage = "";

    //if (this.selectedAgentMemoRow.agentNote === undefined || this.selectedAgentMemoRow.agentNote === '') {
    //  this.popupMessage = "Agent Note is required";
    //}

    if (this.popupMessage !== "") {
      this.popup1.options = {
        header: "Error",
        color: "red",
        widthProsentage: 40,
        animationDuration: 0,
        showButtons: true,
        confirmBtnContent: "OK",
        cancleBtnContent: "Cancel",
        confirmBtnClass: "btn btn-default",
        cancleBtnClass: "one-button-hidden",
      };

      this.popup1.show(this.popup1.options);
      return false;
    }

    return true;
  }

  onCreateAgentMemo() {
    if (!this.onValidateAgentMemo()) return;

    this.selectedAgentMemoRow.agentId = this.selectedAgent.id;
    this.selectedAgentMemoRow.ep = this.name;
    this.selectedAgentMemoRow.emailAlarm = this.selectedEmailEp.toString();

    this.selectedAgentMemoRow.agentNote = this.selectedAgentMemoRow.agentNote;

    this.agentMemoService.create(this.selectedAgentMemoRow).subscribe(
      (data) => {
        this.agentMemoModal.hide();

        //staticModal.show();
        //alert("update is successful");
        this.popupMessage = "Succeed in creating a memo";

        this.popup1.options = {
          header: "Information",
          color: "#5cb85c",
          widthProsentage: 30,
          animationDuration: 0,
          showButtons: true,
          confirmBtnContent: "OK",
          cancleBtnContent: "Cancel",
          confirmBtnClass: "btn btn-default",
          cancleBtnClass: "one-button-hidden",
        };

        this.popup1.show(this.popup1.options);
        //setTimeout(()=>{this.popup1.hide()},1000);
        //setTimeout(()=>{this.find(data.id)},1000);

        //console.log("전:" + this.selectedAgent.id);

        this.selectedAgent.id = data.agentId;

        //console.log("후:" + this.selectedAgent.id);

        this.setInit();
      },
      (error) => {
        console.log(error);
        this.agentMemoModal.hide();

        this.popupMessage = "Fail to create";

        this.popup1.options = {
          header: "Error",
          color: "red",
          widthProsentage: 20,
          animationDuration: 0,
          showButtons: true,
          confirmBtnContent: "OK",
          cancleBtnContent: "Cancel",
          confirmBtnClass: "btn btn-default",
          cancleBtnClass: "one-button-hidden",
        };

        this.popup1.show(this.popup1.options);
      }
    );
  }

  get selectedEmailEp() {
    return this.emailEpList
      .filter((opt) => opt.checked)
      .map((opt) => opt.value);
  }

  showAgentMemoModal(): void {
    if (
      this.selectedAgent === undefined ||
      this.selectedAgent.id === undefined
    ) {
      this.popupMessage = "Please click an agent above to add new memo ";

      this.popup1.options = {
        header: "Information",
        color: "#5cb85c",
        widthProsentage: 55,
        animationDuration: 0,
        showButtons: true,
        confirmBtnContent: "OK",
        cancleBtnContent: "Cancel",
        confirmBtnClass: "btn btn-default",
        cancleBtnClass: "one-button-hidden",
      };

      this.popup1.show(this.popup1.options);
    } else {
      this.selectedAgentMemoRow = new AgentMemo();

      //this.resetCheckedEmail();

      this.agentMemoModal.show();
    }
  }

  hideAgentMemoModal(): void {
    this.agentMemoModal.hide();
  }

  goBack(): void {
    this.location.back();
  }
}
